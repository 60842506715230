import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components";
import {
  Auth,
  Fuel,
  Home,
  Machine,
  Machines,
  MachineWorkTable,
  Request,
  RequestCreate,
  Requests,
  Tickets,
  Work,
  Profile,
  MachineDetail,
  Shop,
  ResetPassword,
  ModelSelect,
  Registration,
  Policy,
  Manual,
  ManualDetail,
  AddMachine,
} from "./screens";
import Builds from "./screens/Shop/Builds";
import Scheme from "./screens/Shop/Scheme";
import Cart from "./screens/Shop/Cart";
import Orders from "./screens/Shop/Orders";
import Order from "./screens/Shop/Order";
import { getUser, setAuth, setUid } from "./store/Auth/actions";
import * as AuthSelector from "./store/Auth/selectors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cat from "./screens/Shop/Cat";
import Sub from "./screens/Shop/Sub";
import Gen from "./screens/ModelSelect/Gen";
import { uid } from "uid";
import { default as SocketIO } from "./components/SocketIO/socket";
import { Ticks } from "chart.js";

function App() {
  const auth = useSelector(AuthSelector.auth);
  const token = useSelector(AuthSelector.token);
  const __uid = useSelector(AuthSelector.uid);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    } else {
      dispatch(setAuth(false));
    }
    if (!auth && __uid === "") {
      const _uid = uid();
      localStorage.setItem("uid", _uid);
      dispatch(setUid(_uid));
    }
  }, [auth]);

  if (auth === null) return <></>;

  return (
    <Wrapper>
      {auth ? (
        <>
          <SocketIO token={token} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/machine/:nomer" element={<Machine />} />
            <Route path="/machine/:nomer/detail" element={<MachineDetail />} />
            <Route path="/machine/:nomer/fuel" element={<Fuel />} />
            <Route path="/machine/:nomer/work" element={<Work />} />
            <Route
              path="/machine/:nomer/maschine-work-table"
              element={<MachineWorkTable />}
            />
            <Route
              path="/machine/:nomer/request/create"
              element={<RequestCreate />}
            />
            <Route path="/machine/:nomer/requests" element={<Requests />} />
            <Route path="/machine/:nomer/request/:id" element={<Request />} />
            <Route path="/machines" element={<Machines />} />
            <Route path="/shop/:nomer" element={<Shop />} />
            <Route path="/shop/:nomer/c/:cat" element={<Cat />} />
            <Route path="/shop/:nomer/c/:cat/s/:sub" element={<Sub />} />
            <Route
              path="/shop/:nomer/c/:cat/s/:sub/b/:part"
              element={<Builds />}
            />
            <Route
              path="/shop/:nomer/c/:cat/s/:sub/b/:part/v/:scheme"
              element={<Scheme />}
            />
            <Route path="/shop/cart" element={<Cart />} />
            <Route path="/shop/orders" element={<Orders />} />
            <Route path="/shop/orders/:id" element={<Order />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/machine/:nomer/manual" element={<Manual />} />
            <Route
              path="/machine/:nomer/manual/:id"
              element={<ManualDetail />}
            />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Auth />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/model" element={<ModelSelect />} />
          <Route path="/model/:model" element={<Gen />} />
          <Route path="/model/:model/shop" element={<Shop />} />
          <Route path="/model/:model/shop/c/:cat" element={<Cat />} />
          <Route path="/model/:model/shop/c/:cat/s/:sub" element={<Sub />} />
          <Route
            path="/model/:model/shop/c/:cat/s/:sub/b/:part"
            element={<Builds />}
          />
          <Route
            path="/model/:model/shop/c/:cat/s/:sub/b/:part/v/:scheme"
            element={<Scheme />}
          />
          <Route path="/shop/cart" element={<Cart />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      )}
      <ToastContainer position="bottom-right" />
    </Wrapper>
  );
}

export default App;
