import Basket from "./Basket"
import Orders from "./Orders"

const ShopSidebar = ({ b = false, o = false, auth = true }) => {
    return (
        <>
            {b ? <Basket /> : null}
            {o && auth ? <Orders /> : null}
        </>
    )
}

export default ShopSidebar