import React, { useEffect, useState } from 'react'
import { WorkDataDraw } from './components';
import "./style.css"

const WorkGraf = ({ data, width }) => {
    return (
        <WorkDataDraw data={data} width={width} />
    )
}

export default WorkGraf
