export const SET_SHOP_MACHINE = "SET_SHOP_MACHINE";
export const SET_SHOP_BASKET = "SET_SHOP_BASKET";
export const SET_SHOP_ORDERS = "SET_SHOP_ORDERS";
export const SET_SHOP_ORDER = "SET_SHOP_ORDER";
export const SET_SHOP_CATEGORIES = "SET_SHOP_CATEGORIES";
export const SET_SHOP_BUILDS = "SET_SHOP_BUILDS";
export const SET_SHOP_CATEGORY = "SET_SHOP_CATEGORY";
export const SET_SHOP_SUBCATEGORY = "SET_SHOP_SUBCATEGORY";
export const SET_SHOP_SUBSUBCATEGORY = "SET_SHOP_SUBSUBCATEGORY";
export const SET_SHOP_PART = "SET_SHOP_PART";
export const SET_SHOP_SCHEME = "SET_SHOP_SCHEME";
export const SET_SHOP_SCHEME_ITEMS = "SET_SHOP_SCHEME_ITEMS";
export const SET_SHOP_ORDER_STATUSES = "SET_SHOP_ORDER_STATUSES";
export const SET_SHOP_ORDER_STATUS = "SET_SHOP_ORDER_STATUS";
export const SET_SHOP_MODEL_GROUP = "SET_SHOP_MODEL_GROUP";
export const SET_SHOP_MODEL = "SET_SHOP_MODEL";
export const SET_SHOP_MODEL_GROUP_LIST = "SET_SHOP_MODEL_GROUP_LIST";
