import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Menu,
    MenuItem,
    SvgIcon,
    Toolbar,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/Auth/actions";
import * as AuthSelector from "../../store/Auth/selectors";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/logo.svg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import NoAuthShopModal from "../NoAuthShopModal";
import Divider from "@mui/material/Divider";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ListNots from "../ListNots";

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auth = useSelector(AuthSelector.auth);
    const user = useSelector(AuthSelector.user);
    const socketListNots = useSelector(AuthSelector.socketListNots);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openRightBar, setOpenRightBar] = useState(false);
    const [countNewNots, setCountNewNots] = useState(0);

    useEffect(() => {
        if (
            typeof socketListNots !== "undefined" &&
            socketListNots !== null &&
            typeof socketListNots.length !== "undefined"
        ) {
            let count = 0;
            socketListNots.map((item) => {
                if (item !== null && parseInt(item.status) === 0) count++;
            });
            setCountNewNots(count);
        }
    }, [socketListNots]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleRightBarToggle = () => {
        setOpenRightBar(!openRightBar);
    };

    const menu = [
        {
            text: "Главная",
            url: "/",
        },
        {
            text: "Машины",
            url: "/machines",
        },
        {
            text: "Корзина",
            url: "/shop/cart",
        },
        {
            text: "Заказы",
            url: "/shop/orders",
        },
    ];

    const link = (url) => {
        navigate(url);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const _logOut = () => {
        handleClose();
        dispatch(logOut());
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const container =
        window !== undefined ? () => window.document.body : undefined;

    return (
        <>
            <AppBar position="relative" color="inherit" sx={{ zIndex: 9 }}>
                <Box
                    sx={{
                        paddingLeft: "24px",
                        paddingRight: "24px",
                    }}
                >
                    <Toolbar disableGutters>
                        <img
                            src={Logo}
                            style={{
                                marginRight: "24px",
                            }}
                            alt=""
                        />
                        {auth ? (
                            <>
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleDrawerToggle}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Box component="nav">
                                        <Drawer
                                            container={container}
                                            variant="temporary"
                                            open={mobileOpen}
                                            onClose={handleDrawerToggle}
                                            ModalProps={{
                                                keepMounted: true, // Better open performance on mobile.
                                            }}
                                            sx={{
                                                display: { xs: "block", sm: "none" },
                                                "& .MuiDrawer-paper": {
                                                    boxSizing: "border-box",
                                                    width: 240,
                                                },
                                            }}
                                        >
                                            <List onClick={handleDrawerToggle}>
                                                {menu.map((page) => (
                                                    <ListItem
                                                        key={page.text}
                                                        disablePadding
                                                        sx={{
                                                            textTransform: "none",
                                                        }}
                                                    >
                                                        <ListItemButton onClick={() => link(page.url)}>
                                                            <ListItemText primary={page.text} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Drawer>
                                    </Box>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                    {menu.map((page) => (
                                        <Button
                                            key={page.text}
                                            onClick={() => link(page.url)}
                                            sx={{
                                                textTransform: "none",
                                                my: 2,
                                                display: "block",
                                                color: "#3498db",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {page.text}
                                        </Button>
                                    ))}
                                </Box>
                                <div>
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButton
                                            size="large"
                                            sx={{
                                                color: "#3498db",
                                            }}
                                            onClick={() => navigate(`/tickets`)}
                                        >
                                            <SmsOutlinedIcon
                                                sx={{ fontSize: "24px" }}
                                                color="custom_blue_outlined"
                                            />
                                        </IconButton>
                                        <IconButton
                                            size="large"
                                            sx={{
                                                mr: "24px",
                                                color: "#3498db",
                                            }}
                                            onClick={handleRightBarToggle}
                                        >
                                            <NotificationsNoneRoundedIcon
                                                color="custom_blue_outlined"
                                                sx={{ fontSize: "24px" }}
                                            />
                                            {countNewNots > 0 ? (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        fontSize: "0.4em",
                                                        color: "#fff",
                                                        lineHeight: "15px",
                                                        textAlign: "center",
                                                        width: "auto",
                                                        minWidth: "20px",
                                                        minHeight: "15px",
                                                        top: "5px",
                                                        right: "-3px",
                                                        backgroundColor: "red",
                                                        padding: "1px 2px",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    {countNewNots}
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                        </IconButton>
                                        <IconButton
                                            size="large"
                                            sx={{
                                                mr: "24px",
                                            }}
                                            onClick={() => setOpen(true)}
                                        >
                                            <SupportAgentIcon color="custom_blue_outlined" />
                                        </IconButton>
                                        <span
                                            style={{
                                                color: "#3498db",
                                            }}
                                        >
                                            {user.name}
                                        </span>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                        >
                                            <PermIdentityOutlinedIcon color="custom_blue_outlined" />
                                        </IconButton>
                                    </Typography>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => link("/profile")}>
                                            Профиль
                                        </MenuItem>
                                        <MenuItem onClick={_logOut}>Выход</MenuItem>
                                    </Menu>
                                </div>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                    <Button
                                        onClick={() => navigate("/model")}
                                        sx={{
                                            my: 2,
                                            display: "block",
                                            color: "#3498db",
                                            fontWeight: "400",
                                        }}
                                    >
                                        Интернет магазин запасных частей
                                    </Button>
                                </Box>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IconButton
                                        sx={{ display: { xs: "flex", md: "none" } }}
                                        size="large"
                                        onClick={() => navigate("/model")}
                                    >
                                        <ShoppingBagOutlinedIcon sx={{ color: "#3498db" }} />
                                    </IconButton>
                                    <IconButton size="large" onClick={() => setOpen(true)}>
                                        <SupportAgentIcon sx={{ color: "#3498db" }} />
                                    </IconButton>
                                </Typography>
                                <Button
                                    onClick={() => navigate("/")}
                                    sx={{
                                        my: 2,
                                        display: "block",
                                        color: "#3498db",
                                        fontWeight: "400",
                                    }}
                                >
                                    Вход
                                </Button>
                            </Box>
                        )}
                    </Toolbar>
                </Box>
            </AppBar>
            <Drawer
                sx={{
                    width: 280,
                    flexShrink: 0,
                    zIndex: 9,
                }}
                variant="persistent"
                anchor="right"
                open={openRightBar}
            >
                <ListNots />
                <Divider />
            </Drawer>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Помощь</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        В случае возникновения проблем напишите нам на почту
                        <br />
                        <a
                            style={{
                                color: "#3498db",
                                textDecoration: "none",
                            }}
                            href="mailto:supp.monitor@mail.ru"
                        >
                            Техподдержка: supp.monitor@mail.ru
                        </a>
                        <br />
                        <a
                            style={{
                                color: "#3498db",
                                textDecoration: "none",
                            }}
                            href="mailto:dst-tm10@tm10.ru"
                        >
                            Отдел продаж: dst-tm10@tm10.ru
                        </a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Header;
