export const email = (state) => state.AuthReducer.email;
export const password = (state) => state.AuthReducer.password;
export const token = (state) => state.AuthReducer.token;
export const expires_in = (state) => state.AuthReducer.expires_in;
export const companys = (state) => state.AuthReducer.companys;
export const auth = (state) => state.AuthReducer.auth;
export const user = (state) => state.AuthReducer.user;
export const re_password = (state) => state.AuthReducer.re_password;
export const uid = (state) => state.AuthReducer.uid;
export const registrationLoad = (state) => state.AuthReducer.registrationLoad;
export const addMachineLoad = (state) => state.AuthReducer.addMachineLoad;
export const socket = (state) => state.AuthReducer.socket;
export const socketListNots = (state) => state.AuthReducer.socketListNots;
export const socketTicketSel = (state) => state.AuthReducer.socketTicketSel;
export const socketAllTickets = (state) => state.AuthReducer.socketAllTickets;
export const code = (state) => state.AuthReducer.code;
