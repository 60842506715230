import { Button, FormControl, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { authUser, setEmail, setPassword } from '../../store/Auth/actions'
import * as AuthSelectors from "../../store/Auth/selectors"

function Auth() {
    const dispatch = useDispatch()

    const email = useSelector(AuthSelectors.email)
    const password = useSelector(AuthSelectors.password)

    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    label="Пароль"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => dispatch(setPassword(e.target.value))}
                />
                <Button onClick={() => dispatch(authUser())} variant="contained">Войти</Button>
                <Link style={{
                    textAlign: 'center',
                    marginTop: '15px',
                    color: '#3498db',
                    textDecoration: 'none'
                }} to="/reset-password">Восстановить пароль</Link>
                <Link style={{
                    textAlign: 'center',
                    marginTop: '15px',
                    color: '#3498db',
                    textDecoration: 'none'
                }} to="/registration">Регистрация</Link>
            </FormControl>
        </Box>
    )
}

export default Auth