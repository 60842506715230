const DrawCoords = ({ width, height }) => {
    const widthGraph = width * 0.8;
    const line = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

    return (
        <>
            {
                line.map((item, i) => {
                    const x = 90 + (i * widthGraph / 24);
                    return (
                        <g key={i}>
                            <line x1={x} x2={x} y1="0" y2={height} className="coords" stroke="#CCCCCC" strokeWidth="0.5" strokeDasharray="2 1" />
                            {
                                (i < 24) ? <text transform={`translate(${x + 3} 12)`} className="coordsText">{(i < 10) ? "0" + i : i}</text> : null
                            }
                        </g>
                    )
                })
            }
            <g transform={`translate(${widthGraph + 120} 0)`}>
                <g>
                    <circle cx="10" cy="6" r="6" fill="#29ABE2" />
                    <text transform="translate(20 12)" className="coordsText bold">Холостой ход</text>
                </g>
                <g transform="translate(130 0)">
                    <circle cx="10" cy="6" r="6" fill="#72C900" />
                    <text transform="translate(20 12)" className="coordsText bold">Нагрузка</text>
                </g>
                <g transform="translate(260 0)">
                    <text transform="translate(0 12)" className="coordsText bold">Всего</text>
                </g>
            </g>
        </>
    )
}

export default DrawCoords