import {
    SET_DOC,
    SET_DOCS, SET_DOCS_TYPES, SET_DOC_CONTENT,
} from "./types";

const initialState = {
    docs: [],
    docsType: [],
    doc: [],
    content: []
}

export const ClerkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOCS:
            return { ...state, docs: action.payload }
        case SET_DOCS_TYPES:
            return { ...state, docsType: action.payload }
        case SET_DOC:
            return { ...state, doc: action.payload }
        case SET_DOC_CONTENT:
            return { ...state, content: action.payload }
        default:
            return state
    }
}