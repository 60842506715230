import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getMachine } from '../../store/Machine/actions'
import * as MachineSelector from "../../store/Machine/selectors"
import { BackButton } from '../../components'

function MachineDetail() {
    const { nomer } = useParams()
    const dispatch = useDispatch()

    const machine = useSelector(MachineSelector.machine)

    useEffect(() => {
        dispatch(getMachine(nomer))
    }, [])

    return (
        <Box sx={{ padding: '24px', width: '40%' }}>
            <BackButton nomer={nomer} />
            <TableContainer>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell>Модель</TableCell>
                            <TableCell align="right">{machine.model}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Тип машины</TableCell>
                            <TableCell align="right">{machine.type}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Класс машины</TableCell>
                            <TableCell align="right">{machine.classes}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Поколение</TableCell>
                            <TableCell align="right">{machine.generation}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Гарантия</TableCell>
                            <TableCell align="right">{typeof machine.warranty !== 'undefined' && machine.warranty !== null ? `${machine.warranty.month} мес/${machine.warranty.hours} мч` : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Военная</TableCell>
                            <TableCell align="right">{machine.military === 1 ? 'Да' : 'Нет'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Периодичность ТО</TableCell>
                            <TableCell align="right">{machine.period_to_title}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата контракта</TableCell>
                            <TableCell align="right">{machine.date_contract !== null ? moment(machine.date_contract).format("DD.MM.YYYY") : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата отгрузки</TableCell>
                            <TableCell align="right">{machine.date_shipment !== null ? moment(machine.date_shipment).format("DD.MM.YYYY") : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата ввода в эксплуатацию</TableCell>
                            <TableCell align="right">{machine.date_startwork !== null ? moment(machine.date_startwork).format("DD.MM.YYYY") : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Менеджер</TableCell>
                            <TableCell align="right">{typeof machine.manager_info !== 'undefined' ? machine.manager_info.name : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Продавец</TableCell>
                            <TableCell align="right">{typeof machine.seller_info !== "undefined" ? machine.seller_info.title : 'Нет данных'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Владелец</TableCell>
                            <TableCell align="right">{typeof machine.owner_info !== 'undefined' ? machine.owner_info.title : ''}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default MachineDetail