import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getModelShop, setModelShop } from '../../store/Shop/actions'
import * as ShopSelector from "../../store/Shop/selectors"

function ModelSelect() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const model = useSelector(ShopSelector.model)

    useEffect(() => {
        dispatch(getModelShop())

        return () => {
            dispatch(setModelShop([]))
        }
    }, [])

    return (
        <Box sx={{ p: 3, display: 'flex', flexWrap: 'wrap', width: '100%', alignContent: "flex-start" }}>
            {
                model.length !== 0 && model.map(item => (
                    <Box key={item.id} sx={{
                        display: 'block',
                        cursor: 'pointer',
                        boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                        width: '350px',
                        height: '250px',
                        p: '10px',
                        borderRadius: '4px',
                        mr: '24px',
                        mb: '24px'
                    }}
                        onClick={() => navigate(`/model/${item.id}`)}
                    >
                        <img style={{
                            height: '177px',
                            width: '100%',
                            objectFit: 'contain'
                        }} src={`http://shop.dstapi.ru/${item.path}`} alt='' />
                        <Typography sx={{
                            mt: 1,
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '700'
                        }}>{item.title}</Typography>
                    </Box>
                ))
            }
        </Box>
    )
}

export default ModelSelect