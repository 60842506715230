import { Label } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getModelShop, setModelShop } from '../../store/Shop/actions'
import * as ShopSelector from "../../store/Shop/selectors"
import NoAuthShopModal from '../../components/NoAuthShopModal'

function Gen() {
    const { model } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [shop, setShop] = useState(false)

    const model_data = useSelector(ShopSelector.model)

    useEffect(() => {
        dispatch(getModelShop(model))

        return () => {
            dispatch(setModelShop([]))
        }
    }, [])

    return (
        <Box sx={{ p: 3, flex: 1, display: 'flex', justifyContent: 'center' }}>
            {
                model_data.length !== 0 && model_data.map(item => (
                    <Box key={item.id} sx={{
                        cursor: 'pointer',
                        boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                        width: '350px',
                        height: '250px',
                        p: '10px',
                        borderRadius: '4px',
                        mr: '24px',
                        mb: '24px'
                    }}
                        onClick={() => {
                            if (item.gen === 1) {
                                setShop(true)
                            } else {
                                setShop(true)
                                //navigate(`/model/${item.model_group_id}/shop`)
                            }
                        }}
                    >
                        <img style={{
                            height: '177px',
                            width: '100%',
                            objectFit: 'contain'
                        }} src={`http://shop.dstapi.ru/${item.path}`} alt='' />
                        <Typography sx={{
                            mt: 1,
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '700'
                        }}>{item.title} поколение {item.gen}</Typography>
                    </Box>
                ))
            }
            <NoAuthShopModal open={shop} setOpen={setShop} />
        </Box>
    )
}

export default Gen