import { Box, Skeleton } from "@mui/material";
import "./style.css";
import moment from "moment";
import { Message } from "../../components";
import {
  socketTicketSel,
  socketAllTickets,
  socket,
} from "../../store/Auth/selectors";
import { readMessagesSocketListNots } from "../../store/Auth/actions";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Messages = ({}) => {
  let date = null;
  const AllTickets = useSelector(socketAllTickets);
  const ticketSel = useSelector(socketTicketSel);
  const Socket = useSelector(socket);

  const dispath = useDispatch();

  const [msgs, SetMsgs] = useState([]);

  useEffect(() => {
    if (typeof ticketSel === "undefined" || ticketSel === null) return;
    SetMsgs(ticketSel.messages);
  }, [ticketSel, AllTickets]);

  const ref = useRef();

  useEffect(() => {
    if (typeof ref.current !== "undefined" && ref.current !== null)
      ref.current.scrollIntoView({ behavior: "smooth" });
  }, [msgs]);

  const readAllTicket = () => {
    if (
      typeof ticketSel === "undefined" ||
      ticketSel === null ||
      typeof Socket === "undefined" ||
      Socket === null ||
      !Socket.connected
    )
      return;
    Socket.emit("readAllTicket", ticketSel.id);
    dispath(readMessagesSocketListNots(ticketSel.id));
  };

  return typeof msgs !== "undefined" && msgs !== null ? (
    <Box onMouseEnter={readAllTicket}>
      <Box>
        {typeof msgs.length !== "undefined" && msgs.length > 0
          ? msgs.map((item) => {
              if (
                date === null ||
                (moment(item.time).diff(date, "days") > 0 && item.nots !== true)
              ) {
                date = moment(item.time);
                return (
                  <>
                    <Message
                      key={item.id}
                      message={moment(item.time).format("DD.MM.YYYY")}
                      time={moment(item.time).format("HH:mm DD.MM.YYYY")}
                      my={false}
                      nots={true}
                    />
                    <Message
                      key={item.id + 999999}
                      message={item.message}
                      time={moment(item.time).format("HH:mm")}
                      my={item.my}
                      nots={item.nots}
                      status={
                        item.my === true ? item.status_not_mine : item.status
                      }
                    />
                  </>
                );
              }
              return (
                <Message
                  key={item.id}
                  message={item.message}
                  time={moment(item.time).format("HH:mm")}
                  my={item.my}
                  nots={item.nots}
                  status={item.my === true ? item.status_not_mine : item.status}
                />
              );
            })
          : ""}
      </Box>
      <Box ref={ref}></Box>
    </Box>
  ) : (
    <Skeleton
      sx={{ borderRadius: "4px" }}
      variant="rectangular"
      width="100%"
      height="100%"
    />
  );
};

export default Messages;
