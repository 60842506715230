import { useDispatch, useSelector } from "react-redux";
import * as AuthSelector from "../../store/Auth/selectors";
import * as RequestSelector from "../../store/Request/selectors";
import {
  MenuList,
  Box,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { TicketItem } from "../../components";
import { useState } from "react";
import { getRequests } from "../../store/Request/actions";
import { toast } from "react-toastify";
import { serchMachineBool } from "../../store/Machine/actions";

const TicketsList = ({}) => {
  const AllTickets = useSelector(AuthSelector.socketAllTickets);
  const socket = useSelector(AuthSelector.socket);
  const requests = useSelector(RequestSelector.requests);

  const dispatch = useDispatch();

  const [openCreate, setOpenCreate] = useState(false);
  const [machine, setMachine] = useState("");
  const [machineError, setMachineError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [type, setType] = useState("0");
  const [typeError, setTypeError] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [errorForm, setErrorForm] = useState(false);

  const createTicket = () => {
    if (typeof socket === "undefined" || socket === null || !socket.connected) {
      toast.error("Ошибка подключения!");
      return;
    }
    if (machine.length === 0) {
      setMachineError(true);
      setErrorForm(true);
    } else setMachineError(false);
    if (type === "0") {
      setTypeError(true);
      setErrorForm(true);
    } else setTypeError(false);
    if (title.length === 0) {
      setTitleError(true);
      setErrorForm(true);
    } else setTitleError(false);
    if (errorForm) {
      toast.error("Исправьте ошибки в форме!");
      return;
    }
    serchMachineBool(machine)
      .then((res) => {
        if (!res) {
          toast.error("Машина не найдена!");
          return;
        }
        setOpenCreate(false);
        setErrorForm(false);
        socket.emit(
          "createTicket",
          machine,
          title,
          type,
          requestId === 0 ? null : requestId,
          null
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <MenuList
        sx={{
          height: "calc(100vh - 95px)",
          overflowY: "scroll",
          padding: "20px 30px",
        }}
      >
        <MenuItem
          className="ticket-item create"
          sx={{
            color: "#3498db",
            whiteSpace: "pre-wrap",
            display: "block",
            padding: "10px 20px",
            backgroundColor: "#F5F6FA",
            maxWidth: "400px",
            margin: "0 auto 40px",
            border: "1px solid #C4C4C4",
            textAlign: "center",
            fontSize: "0.9em",
            color: "#999999",
            borderRadius: "10px",
          }}
          onClick={() => {
            setOpenCreate(true);
          }}
        >
          Создать обращение
        </MenuItem>
        {typeof AllTickets !== "undefined" && AllTickets !== null ? (
          AllTickets.length > 0 ? (
            AllTickets.map((item) => {
              return <TicketItem key={item.id} ticket={item}></TicketItem>;
            })
          ) : (
            <Box sx={{ textAlign: "center" }}>Нет данных</Box>
          )
        ) : (
          ""
        )}
      </MenuList>
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <DialogTitle>Создание обращения</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Номер машины"
            fullWidth
            InputProps={{ type: "number" }}
            variant="outlined"
            error={machineError}
            value={machine}
            onChange={(e) => {
              setMachine(e.target.value);
            }}
            onBlur={(e) => {
              if (e.target.value.length === 0) return;
              dispatch(getRequests(machine));
            }}
          ></TextField>
          <TextField
            margin="dense"
            select
            label="Тип обращения"
            fullWidth
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            error={typeError}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="0">Выбрать</option>
            <option value="service">Сервис</option>
            <option value="shop">Магазин</option>
          </TextField>
          <TextField
            margin="dense"
            select
            label="На основе заявки"
            fullWidth
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            value={requestId}
            onChange={(e) => {
              setRequestId(parseInt(e.target.value));
              if (parseInt(e.target.value) !== 0 && title.length === 0)
                setTitle(`Обращение по заявке №${parseInt(e.target.value)}`);
            }}
          >
            <option value={0}>Без заявки</option>
            {typeof requests !== "unedfined" &&
            requests !== null &&
            requests.length > 0
              ? requests.map((item) => (
                  <option key={item.id} value={item.id}>
                    {`${item.id} - ${item.description}`}
                  </option>
                ))
              : null}
          </TextField>
          <TextField
            margin="dense"
            label="Название обращения"
            type="text"
            fullWidth
            variant="outlined"
            error={titleError}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreate(false)}>Закрыть</Button>
          <Button onClick={() => createTicket()}>Создать</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketsList;
