import { SET_FORM_MESSAGE, SET_FORM_STATE } from "./types";

const initialState = {
    message: {},
    formStatus: null
}

export const FormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_MESSAGE:
            return { ...state, message: action.payload }
        case SET_FORM_STATE:
            return { ...state, formStatus: action.payload }
        default:
            return state
    }
}