import { toast } from "react-toastify";
import api from "../../services/api";
import {
    SET_DOC,
    SET_DOCS, SET_DOCS_TYPES, SET_DOC_CONTENT,
} from "./types";
import { trackPromise } from "react-promise-tracker";


export const setDocs = data => {
    return {
        type: SET_DOCS,
        payload: data
    }
}

export const getDocs = (type_id = 1, model_id, gen_id) => async (dispatch, getState) => {
    try {
        const response = await trackPromise(api.get(`/api/v1/clerk/view/docs?type=${type_id}&model=${model_id}&gen=${gen_id}`))

        if (response.status === 200) {
            const json = await response.data
            dispatch(setDocs(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const setDocsTypes = data => {
    return {
        type: SET_DOCS_TYPES,
        payload: data
    }
}

export const getDocsTypes = () => async (dispatch, getState) => {
    try {
        const response = await trackPromise(api.get(`/api/v1/clerk/view/types`))

        if (response.status === 200) {
            const json = await response.data
            dispatch(setDocsTypes(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const setDoc = data => {
    return {
        type: SET_DOC,
        payload: data
    }
}

export const getDoc = (id) => async (dispatch, getState) => {
    try {
        const response = await trackPromise(api.get(`/api/v1/clerk/view/docs/${id}`))

        if (response.status === 200) {
            const json = await response.data
            dispatch(setDoc(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const setDocContent = data => {
    return {
        type: SET_DOC_CONTENT,
        payload: data
    }
}

export const getDocContent = (id) => async (dispatch, getState) => {
    try {
        const response = await trackPromise(api.get(`/api/v1/clerk/view/part/${id}`))

        if (response.status === 200) {
            const json = await response.data
            dispatch(setDocContent(json))
        }

    } catch (e) {
        console.error(e)
    }
}
