import moment from "moment"

const WorkDataItem = ({ data, width }) => {
    let color
    const widthGraph = width * 0.8;
    const days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

    const timeFormat = seconds => {
        let hours = Math.floor(seconds / 60 / 60);
        let minutes = Math.floor(seconds / 60) - (hours * 60);
        return hours + " ч " + minutes + " мин";
    }

    const countTime = (idling, activity) => {
        return [timeFormat(idling), timeFormat(activity), timeFormat(idling + activity)]
    }

    const [idling, activity, all] = countTime(data.idling, data.activity)

    return (
        <>
            <g transform="translate(0 12)">
                <text className="svgText">{moment(data.date).format("DD.MM.YYYY")}</text>
                <text transform="translate(0 15)" className="coordsText">{days[moment(data.date).day()]}</text>
            </g>
            {
                data.data.map((item, i) => {
                    switch (item.status) {
                        case "off":
                            color = "#E6E6E6";
                            break;
                        case "idling":
                            color = "#29ABE2";
                            break;
                        case "activity":
                            color = "#72C900";
                            break;
                        default:
                            break
                    }

                    const x = (item.time * widthGraph / 86400) + 90;

                    const widthBlock = (i < item.length - 1) ?
                        (data.data[i + 1].time - item.time) * widthGraph / 86400 :
                        (86400 - item.time) * widthGraph / 86400

                    return <rect key={i} x={x} y="0" width={widthBlock} height="30" fill={color} />
                })
            }
            <g transform={`translate(${widthGraph + 140} 0)`}>
                <text transform="translate(0 15)" className="svgT">{idling}</text>
                <text transform="translate(130 15)" className="svgT">{activity}</text>
                <text transform="translate(240 15)" className="svgT">{all}</text>
            </g>
        </>
    )

}

export default WorkDataItem