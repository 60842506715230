import "./style.css";
import moment from "moment";
import {
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const Table = ({ cols, data, params, rows = 40, onRowClick = null }) => {
  const getParams = (vars, id) => {
    if (params[vars].length === 0) {
      return <span className="placeholder" />;
    }
    if (id !== null) {
      const index = params[vars].find((item) => item.id === id);
      if (typeof index === "undefined" || index === null) {
        return "---";
      }
      if (index.name) {
        return index.name;
      }
      return index.title;
    } else {
      return "---";
    }
  };

  const Thead = () => {
    return cols.map((item, i) => {
      if (
        typeof item.orderBy !== "undefined" &&
        item.orderBy !== null &&
        typeof item.func !== "undefined" &&
        item.func !== null
      )
        return (
          <TableCell key={i}>
            <Button
              onClick={() => item.func(item.orderBy)}
              sx={{
                ...item.style,
                textTransform: "none",
                textDecoration: "underline",
                color: "inherit",
              }}
            >
              {item.title}
            </Button>
          </TableCell>
        );
      else
        return (
          <TableCell key={i} sx={item.style}>
            {item.title}
          </TableCell>
        );
    });
  };

  const Ceil = ({ ceil, col, item, index }) => {
    if (item === null) {
      return (
        <TableCell>
          <Skeleton variant="text" height={50} />
        </TableCell>
      );
    } else if (col.fun && col.type === "link" && col.val) {
      return (
        <TableCell>
          <span className="span-link" onClick={() => col.fun(ceil)}>
            {col.val(item)}
          </span>
        </TableCell>
      );
    } else if (col.fun && col.type === "link") {
      return (
        <TableCell>
          <span
            style={{ cursor: "pointer", color: "#5955b3", fontWeight: "600" }}
            className="span-link"
            onClick={() => col.fun(ceil)}
          >
            {ceil}
          </span>
        </TableCell>
      );
    } else if (col.vars) {
      return <TableCell>{getParams(col.vars, ceil)}</TableCell>;
    } else if (col.type === "date") {
      return (
        <TableCell>
          {typeof ceil !== "undefined"
            ? moment(ceil).format("DD.MM.YYYY")
            : "Нет данных"}
        </TableCell>
      );
    } else if (col.type === "time") {
      return (
        <TableCell>
          {typeof ceil !== "undefined"
            ? moment(ceil).format("HH:mm:ss")
            : "Нет данных"}
        </TableCell>
      );
    } else if (col.type === "dateTime") {
      return (
        <TableCell>
          {typeof ceil !== "undefined"
            ? moment(ceil).format("DD.MM.YYYY HH:mm:ss")
            : "Нет данных"}
        </TableCell>
      );
    } else if (col.type === "bool") {
      return (
        <TableCell>
          {typeof ceil !== "undefined" ? (
            ceil ? (
              <CheckCircleIcon color="success" />
            ) : (
              <ErrorIcon color="action" />
            )
          ) : (
            "Нет данных"
          )}
        </TableCell>
      );
    } else if (col.val) {
      return <TableCell>{col.val(item, index)}</TableCell>;
    } else {
      return (
        <TableCell>{typeof ceil !== "undefined" ? ceil : "---"}</TableCell>
      );
    }
  };

  const Row = ({ item, onClick, index }) => {
    return (
      <TableRow
        onClick={(e) => {
          e.stopPropagation();
          if (onClick !== null) onClick(item, index);
        }}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {item !== null
          ? cols.map((col, i) => (
              <Ceil
                key={i}
                index={index}
                ceil={item[col.code]}
                col={col}
                item={item}
              />
            ))
          : cols.map((col, i) => (
              <Ceil key={i} index={index} ceil={null} col={col} item={null} />
            ))}
      </TableRow>
    );
  };

  const Rows = () => {
    if (typeof data === "undefined" || data === null) {
      let arr = [];
      for (let i = 0; i < 20; i++)
        arr.push(<Row key={i} index={1} item={null} onClick={null} />);
      return arr;
    }
    return data.map((item, i) => (
      <Row key={i} index={i} item={item} onClick={onRowClick} />
    ));
  };

  return (
    <TableContainer component={Paper}>
      <TableMui aria-label="simple table">
        <TableHead>
          <TableRow>
            <Thead />
          </TableRow>
        </TableHead>
        <TableBody>
          {typeof data === "undefined" || data === null || data.length > 0 ? (
            <Rows />
          ) : (
            <></>
          )}
        </TableBody>
      </TableMui>
    </TableContainer>
  );
};

export default Table;
