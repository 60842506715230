import { toast } from "react-toastify";
import api from "../../services/api";
import {
  SET_COMPANY,
  SET_COMPANY_USERS,
  SET_REQUEST,
  SET_REQUESTS,
  SET_SERVICE,
  SET_STATUS,
  SET_TICKET_ID,
  SET_WORK_TYPE,
} from "./types";

export const setCompanyUsers = (data) => {
  return {
    type: SET_COMPANY_USERS,
    payload: data,
  };
};

export const getCompanyUsers = () => async (dispatch, getState) => {
  try {
    const company_id = getState().AuthReducer.companys;

    const response = await api.get(`/api/company/${company_id["0"]}/users`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyUsers(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompany = (data) => {
  return {
    type: SET_COMPANY,
    payload: data,
  };
};

export const getCompany = () => async (dispatch, getState) => {
  try {
    const company_id = getState().AuthReducer.companys;

    const response = await api.get(`/api/company/${company_id["0"]}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompany(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setWorkType = (data) => {
  return {
    type: SET_WORK_TYPE,
    payload: data,
  };
};

export const getWorkType = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/work/types`);

    if (response.status === 200) {
      const json = await response.data;
      const type = json.filter((item) => item.id !== 9);
      dispatch(setWorkType(type));
    }
  } catch (e) {
    console.error(e);
  }
};

export const addRequest = (data) => async (dispatch, getState) => {
  try {
    let formData = new FormData();

    formData.append("nomer", data.nomer);
    formData.append("service_id", data.service_id);
    formData.append("work_time", data.work_time);
    formData.append("description", data.description);
    formData.append("work_type_id", data.work_type_id);
    formData.append("address", data.address);
    formData.append("inn", data.inn);

    if (data.person_id !== 0) {
      formData.append("person_id", data.person_id);
    } else {
      formData.append("person", data.person);
      formData.append("person_phone", data.person_phone);
    }

    if (data.files.length !== 0) {
      data.files.forEach((item, i) => {
        formData.append(`files[${i}]`, item, item.name);
      });
    }

    const response = await api.post(`/api/v1/work/request`, formData);

    if (response.status === 201) {
      const json = await response.data;

      toast.success("Заявка создана");
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineService = (data) => {
  return {
    type: SET_SERVICE,
    payload: data,
  };
};

export const getMachineService = (nomer) => async (dispatch, getStatus) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}/service`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineService(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setRequests = (data) => {
  return {
    type: SET_REQUESTS,
    payload: data,
  };
};

export const getRequests =
  (nomer, type = 0) =>
  async (dispatch, getState) => {
    try {
      const qType = type !== 0 ? `?types=${type}` : "";
      const response = await api.get(`/api/v1/work/requests/${nomer}${qType}`);

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setRequests(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setStatus = (data) => {
  return {
    type: SET_STATUS,
    payload: data,
  };
};

export const getStatus = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/work/status`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setStatus(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setRequest = (data) => {
  return {
    type: SET_REQUEST,
    payload: data,
  };
};

export const getRequest = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/work/request/${id}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setRequest(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setTicketId = (data) => {
  return {
    type: SET_TICKET_ID,
    payload: data,
  };
};
