import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import * as AuthSelector from "../../store/Auth/selectors";
import {
  setSocket,
  setSocketListNots,
  addNotsSocketList,
  addMessageSocketList,
  setSocketAllTickets,
  getSocketTicket,
} from "../../store/Auth/actions";
import { setTicketId } from "../../store/Request/actions";
import { toast } from "react-toastify";

const SocketIO = ({ token }) => {
  const userCompanys = useSelector(AuthSelector.companys);
  const socket = useSelector(AuthSelector.socket);
  const auth = useSelector(AuthSelector.auth);
  const dispatch = useDispatch();
  let socketConnection = null;

  const getNameSpace = () => {
    return "SocketUser";
    let companyModerators = [10, 1];
    if (typeof userCompanys === "undefined" || userCompanys.length == 0)
      return "SocketUser";
    for (let company of userCompanys) {
      if (companyModerators.indexOf(company) !== -1) return "SocketAdmin";
    }
    return "SocketUser";
  };

  const onConnect = () => {
    console.log("Подключен", "connect");
  };

  const getNotReadAndNots = (data) => {
    if (typeof data !== "undefined" && data !== null && data.length > 0) {
      dispatch(setSocketListNots(data));
    } else dispatch(setSocketListNots([]));
  };

  const getTicketForRequest = (data) => {
    if (typeof data !== "undefined" && data !== null) {
      dispatch(setTicketId(data));
    } else dispatch(setTicketId(null));
  };

  const getAllTickets = (data) => {
    if (typeof data !== "undefined" && data !== null && data.length > 0) {
      dispatch(setSocketAllTickets(data));
    } else dispatch(setSocketAllTickets([]));
  };

  const message = (data) => {
    toast.info(data);
  };

  const errorMessage = (data) => {
    toast.warning(data);
  };

  const successMessage = (data) => {
    toast.success(data);
  };

  const newMessage = (data) => {
    if (typeof data === "undefined" || data === null) return;
    dispatch(addMessageSocketList(data));
    dispatch(addNotsSocketList(data));
    toast.info("У вас новое сообщение!");
  };

  const closeTicket = (data) => {
    if (typeof data === "undefined" || data === null) return;
    dispatch(addMessageSocketList(data));
    toast.info("Обращение успешно закрыто!");
  };

  const addMessage = (data) => {
    if (typeof data === "undefined" || data === null) return;
    dispatch(addMessageSocketList(data));
  };

  const newNotification = (data) => {
    if (typeof data === "undefined" || data === null) return;
    dispatch(addNotsSocketList(data));
    toast.info("У вас новое уведомление!");
  };

  const newTicket = (data) => {
    toast.info(data + " - newTicket");
  };

  const getTicket = (data) => {
    if (typeof data === "undefined" || data === null) return;
    dispatch(getSocketTicket(data));
  };

  const getTicketsUser = (data) => {
    console.log(data, "getTicketsUser");
  };

  const getTicketsAdmin = (data) => {
    console.log(data, "getTicketsAdmin");
  };

  useEffect(() => {
    if (auth !== null && auth === true) {
      if (socket === null) {
        if (socketConnection === null) {
          const socketConnect = io(
            `http://188.225.24.135:5001/${getNameSpace()}`,
            {
              reconnectionAttempts: 40,
              reconnectionDelayMax: 20000,
              transports: ["websocket"],
              auth: {
                token: token,
              },
            }
          );

          socketConnection = socketConnect;
        }

        dispatch(setSocket(socketConnection));
      }
    }
    if (socket !== null) {
      socket.on("connect", onConnect);
      socket.on("getNotReadAndNots", getNotReadAndNots);
      socket.on("getTicketForRequest", getTicketForRequest);
      socket.on("getAllTickets", getAllTickets);
      socket.on("message", message);
      socket.on("successMessage", successMessage);
      socket.on("errorMessage", errorMessage);
      socket.on("addMessage", addMessage);
      socket.on("newMessage", newMessage);
      socket.on("newNotification", newNotification);
      socket.on("newTicket", newTicket);
      socket.on("getTicket", getTicket);
      socket.on("closeTicket", closeTicket);
      socket.on("getTicketsUser", getTicketsUser);
      socket.on("getTicketsAdmin", getTicketsAdmin);
    }

    return () => {
      if (socket !== null) {
        socket.off("connect", onConnect);
        socket.off("getNotReadAndNots", getNotReadAndNots);
        socket.off("getTicketForRequest", getTicketForRequest);
        socket.off("getAllTickets", getAllTickets);
        socket.off("message", message);
        socket.off("successMessage", successMessage);
        socket.off("errorMessage", errorMessage);
        socket.off("addMessage", addMessage);
        socket.off("newMessage", newMessage);
        socket.off("newNotification", newNotification);
        socket.off("newTicket", newTicket);
        socket.off("getTicket", getTicket);
        socket.off("closeTicket", closeTicket);
        socket.off("getTicketsUser", getTicketsUser);
        socket.off("getTicketsAdmin", getTicketsAdmin);
        if (socket.connected) socket.disconnect();
      }
    };
  }, [socket]);

  return <></>;
};

export default SocketIO;
