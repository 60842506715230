import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  getRequests,
  getStatus,
  getWorkType,
  setRequests,
} from "../../store/Request/actions";
import * as RequestSelector from "../../store/Request/selectors";
import * as AuthSelectors from "../../store/Auth/selectors";
import Img from "../../assets/file.png";
import { FancyBox } from "../../components";
import moment from "moment";
import { type } from "../../store/Machine/selectors";
import { toast } from "react-toastify";

function Request() {
  const { nomer, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const request = useSelector(RequestSelector.request);
  const user = useSelector(AuthSelectors.user);
  const socket = useSelector(AuthSelectors.socket);
  const ticketId = useSelector(RequestSelector.ticketId);

  useEffect(() => {
    dispatch(getRequest(id));
    if (typeof socket !== "undefined" && socket !== null) {
      socket.emit("getTicketForRequest", nomer, id);
    }
  }, [socket]);

  const link = (url) => {
    navigate(url);
  };

  const createTicket = () => {
    if (request === null || typeof request.nomer === "undefined") {
      return;
    }
    if (typeof socket === "undefined" || socket === null || !socket.connected) {
      toast.warning("Ошибка подключения");
      return;
    }
    socket.emit("createTicket", nomer, null, "service", id);
    socket.emit("getTicketForRequest", nomer, id);
  };

  const Info = () => {
    return (
      <>
        {request.status.id === 7 || request.status.id === 8 ? (
          <Box sx={{ mb: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Typography>Причина отмены/отклонения</Typography>
              {request.status.comment ?? "Не указана"}
            </Paper>
          </Box>
        ) : null}
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Номер машины</TableCell>
                <TableCell align="right">{request.nomer}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell align="right">{request.status.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Наработка</TableCell>
                <TableCell align="right">{request.work_time} м/ч</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Тип работ</TableCell>
                <TableCell align="right">{request.work_type.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Описание</TableCell>
                <TableCell align="right">{request.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Адрес</TableCell>
                <TableCell align="right">{request.address}</TableCell>
              </TableRow>
              {request.lon && request.lat ? (
                <TableRow>
                  <TableCell>Координаты машины</TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      alt="карта"
                      href={`https://yandex.ru/maps/?pt=${request.lon},${request.lat}&z=14&l=map`}
                      rel="noreferrer"
                    >
                      Открыть
                    </a>
                  </TableCell>
                </TableRow>
              ) : null}
              <TableRow>
                <TableCell>Дата заявки</TableCell>
                <TableCell align="right">
                  {moment(request.created_at).format("DD.MM.YYYY HH:mm:ss")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Box sx={{ flex: 1, padding: "24px" }}>
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={5}
          sx={{
            height: "100%",
          }}
        >
          {typeof request.nomer !== "undefined" ? <Info /> : null}
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              marginTop: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                margin: "0 -5px",
                div: {
                  width: "77px",
                  height: "77px",
                  mb: "10px",
                  mr: "5px",
                  ml: "5px",
                  position: "relative",
                  img: {
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                    zIndex: "1",
                    backgroundColor: "#c4c4c4",
                    objectFit: "cover",
                  },
                  ".delete-img": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius: "4px",
                    zIndex: "-1",
                    opacity: 0,
                    cursor: "pointer",
                    transition: "0.3s",
                  },
                  ":hover .delete-img": {
                    zIndex: 2,
                    opacity: 1,
                    transition: "0.5s",
                  },
                },
              }}
            >
              <FancyBox>
                {typeof request.files !== "undefined"
                  ? request.files.map((item, i) => {
                      if (item.user_id === user.id || true)
                        return (
                          <div key={i}>
                            <img
                              key={i}
                              data-fancybox={`gallery`}
                              data-src={`http://service.dstapi.ru/uploads/tmp/${request.id}/${item.file}`}
                              src={
                                item.file_type !== "doc"
                                  ? `http://service.dstapi.ru/uploads/tmp/${request.id}/${item.file}`
                                  : Img
                              }
                              alt={item.name}
                            />
                          </div>
                        );
                    })
                  : null}
              </FancyBox>
            </Box>
            <Box
              sx={{
                margin: "80px 20px ",
              }}
            >
              {typeof ticketId !== "undefined" && ticketId !== null ? (
                <Box
                  sx={{
                    minWidth: 200,
                    maxWidth: 400,
                    padding: "25px 30px",
                    width: "80%",
                    margin: "0 auto",
                    backgroundColor: "#F5F6FA",
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ fontWeight: 600 }}>{ticketId.title}</Box>
                  <Button
                    sx={{
                      textAlign: "center",
                      width: "150px",
                      height: "30px",
                      lineHeight: "30px",
                      mt: "20px",
                    }}
                    onClick={() => link(`/tickets?id=${ticketId.id}`)}
                    variant="contained"
                    color="custom_blue2"
                  >
                    Перейти
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    minWidth: 200,
                    maxWidth: 400,
                    padding: "25px 30px",
                    width: "80%",
                    margin: "0 auto",
                    backgroundColor: "#F5F6FA",
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ fontWeight: 600 }}>
                    Создать обращение на основе заявки
                  </Box>
                  <Button
                    sx={{
                      textAlign: "center",
                      width: "150px",
                      height: "30px",
                      lineHeight: "30px",
                      mt: "20px",
                    }}
                    onClick={createTicket}
                    variant="contained"
                    color="custom_blue2"
                  >
                    Создать
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Request;
