import { Box, MenuItem } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";
import { socketAllTickets, socketTicketSel } from "../../store/Auth/selectors";
import { setSocketTicketSel } from "../../store/Auth/actions";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";

const TicketItem = ({ ticket }) => {
  const dispatch = useDispatch();

  const ticketSel = useSelector(socketTicketSel);

  const read = () => {
    if (
      typeof ticket.lastMessage !== "undefined" &&
      ticket.lastMessage !== null
    ) {
      if (ticket.lastMessage.my === true)
        return parseInt(ticket.lastMessage.status_not_mine) === 1 ? (
          <DoneAllIcon fontSize="small" sx={{ color: "#205885" }} />
        ) : (
          <CheckIcon fontSize="small" sx={{ color: "#205885" }} />
        );
      return parseInt(ticket.lastMessage.status) === 1 ? (
        <DoneAllIcon fontSize="small" sx={{ color: "#205885" }} />
      ) : (
        <CheckIcon fontSize="small" sx={{ color: "#205885" }} />
      );
    }
    return "";
  };

  const messageText = () => {
    if (
      typeof ticket.lastMessage !== "undefined" &&
      ticket.lastMessage !== null &&
      typeof ticket.lastMessage.message !== "undefined" &&
      ticket.lastMessage.message !== null
    )
      return ticket.lastMessage.message.length > 50
        ? `${ticket.lastMessage.message.substring(0, 40)}...`
        : ticket.lastMessage.message;
    return "";
  };

  return (
    <MenuItem
      className="ticket-item"
      selected={
        typeof ticketSel !== "undefined" &&
        ticketSel !== null &&
        parseInt(ticket.id) === parseInt(ticketSel.id)
      }
      sx={{
        color: "#3498db",
        whiteSpace: "pre-wrap",
        display: "block",
        padding: "20px",
        backgroundColor: "#F5F6FA",
        maxWidth: "400px",
        margin: "0 auto 20px",
      }}
      onClick={() => {
        dispatch(setSocketTicketSel(ticket));
      }}
    >
      <Box
        sx={{
          fontSize: "1em",
          fontWeight: "700",
          mb: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ color: "#000" }}>{ticket.title}</Box>
        {typeof ticket.countNew !== "undefined" &&
        ticket.countNew !== null &&
        parseInt(ticket.countNew) > 0 ? (
          <Box
            sx={{
              fontSize: "0.7em",
              lineHeight: "14px",
              textAlign: "center",
              width: "auto",
              minWidth: "25px",
              color: "#fff",
              minHeight: "14px",
              backgroundColor: "#205885",
              padding: "2px 5px",
              borderRadius: "7px",
              ml: "10px",
            }}
          >
            {ticket.countNew}
          </Box>
        ) : (
          read()
        )}
      </Box>
      <Box sx={{ fontSize: "0.9em", color: "#999999" }}>{messageText()}</Box>
    </MenuItem>
  );
};

export default TicketItem;
