export const machine = (state) => state.ShopReducer.machine;
export const basket = (state) => state.ShopReducer.basket;
export const orders = (state) => state.ShopReducer.orders;
export const categories = (state) => state.ShopReducer.categories;
export const builds = (state) => state.ShopReducer.builds;
export const category = (state) => state.ShopReducer.category;
export const subcategory = (state) => state.ShopReducer.subcategory;
export const subSubcategory = (state) => state.ShopReducer.subSubcategory;
export const part = (state) => state.ShopReducer.part;
export const scheme = (state) => state.ShopReducer.scheme;
export const schemeItems = (state) => state.ShopReducer.schemeItems;
export const order = (state) => state.ShopReducer.order;
export const orderStatuses = (state) => state.ShopReducer.orderStatuses;
export const orderStatus = (state) => state.ShopReducer.orderStatus;
export const model_group = (state) => state.ShopReducer.model_group;
export const model = (state) => state.ShopReducer.model;
export const shopModelGrouoList = (state) =>
  state.ShopReducer.shopModelGrouoList;
