import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as MachineSelector from "../../store/Machine/selectors"
import { getMachine, getMachineFuel, getMachineFuelChar, getMachineFuelData, getMachineTelemetry, getMachineWorkData, setMachine, setMachineFuel, setMachineFuelChar, setMachineFuelData, setMachineWorkData } from '../../store/Machine/actions'
import { Box, Skeleton, TextField, Button } from '@mui/material'
import { BackButton, DrawChart, Fuel as FuelGraf } from '../../components'
import styles from "./fuel.module.css"
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import 'chartjs-adapter-moment'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2'


function Fuel() {
    const { nomer } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const machineFuelChar = useSelector(MachineSelector.machineFuelChar)
    const machine = useSelector(MachineSelector.machine)
    const machineFuel = useSelector(MachineSelector.machineFuel)
    const machineFuelData = useSelector(MachineSelector.machineFuelData)
    const machineWorkData = useSelector(MachineSelector.machineWorkData)
    const telemetry = useSelector(MachineSelector.telemetry)

    const [fromDate, setFromDate] = useState(moment().subtract(2, 'days').format('yyyy-MM-DDTHH:mm'))
    const [maxFromDate, setMaxFromDate] = useState(0)
    const [toDate, setToDate] = useState(moment().format('yyyy-MM-DDTHH:mm'))
    const [minToDate, setMinToDate] = useState(0)

    const [day, setDays] = useState(moment(toDate).diff(moment(fromDate), 'days'))
    const [diff, setDiff] = useState(0)

    const [charLabels, setCharLabels] = useState(0)
    const [charData, setCharData] = useState(0)


    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const qFromDate = query.get('from') !== null && query.get('from') !== 0 ? query.get('from') : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm')
        const qToDate = query.get('to') !== null && query.get('to') !== 0 ? query.get('to') : moment().format('yyyy-MM-DDTHH:mm')

        if ((qFromDate !== 0 || qToDate !== 0) && moment(qToDate).diff(moment(qFromDate), 'days') >= 1) {
            setFromDate(qFromDate)
            setToDate(qToDate)
            setMinToDate(moment(qFromDate).add(1, 'days').format('yyyy-MM-DDTHH:mm'))
            setMaxFromDate(moment(qToDate).subtract(1, 'days').format('yyyy-MM-DDTHH:mm'))
            setDays(moment(qToDate).diff(moment(qFromDate), 'days'))
        }
        dispatch(getMachine(nomer))
        dispatch(getMachineFuel(nomer))
        dispatch(getMachineTelemetry(nomer))
        dispatch(getMachineFuelChar(nomer, getMomentInUtc(qFromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(qToDate).format('yyyy-MM-DDTHH:mm')))
        dispatch(getMachineFuelData(nomer, getMomentInUtc(qFromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(qToDate).format('yyyy-MM-DDTHH:mm')))
        dispatch(getMachineWorkData(nomer, getMomentInUtc(qFromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(qToDate).format('yyyy-MM-DDTHH:mm')))

        return () => {
            dispatch(setMachine([]))
            dispatch(setMachineFuelChar([]))
            dispatch(setMachineFuel([]))
            dispatch(setMachineFuelData([]))
            dispatch(setMachineWorkData([]))
        }
    }, [nomer])




    useEffect(() => {
        if (typeof telemetry.time !== 'undefined')
            setDiff(moment().diff(moment(telemetry.time), 'days'))
    }, [telemetry])

    const selectByDate = () => {
        dispatch(setMachineFuelChar([]))
        dispatch(setMachineFuelData([]))
        dispatch(setMachineWorkData([]))
        dispatch(getMachineFuelChar(nomer, getMomentInUtc(fromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(toDate).format('yyyy-MM-DDTHH:mm')))
        dispatch(getMachineFuelData(nomer, getMomentInUtc(fromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(toDate).format('yyyy-MM-DDTHH:mm')))
        dispatch(getMachineWorkData(nomer, getMomentInUtc(fromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(toDate).format('yyyy-MM-DDTHH:mm')))
        navigate(`${location.pathname}${fromDate !== 0 || toDate !== 0 ? '?' : ''}${fromDate !== 0 ? 'from=' + fromDate : ''}${toDate !== 0 ? '&to=' + toDate : ''}`)
    }


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend,
        TimeScale
    );

    var data

    const getMomentInUtc = (datetime) => {
        return moment.tz(datetime, Intl.DateTimeFormat().resolvedOptions().timeZone).utc()
    }

    const skipped = (ctx, value) => {
        if (typeof ctx !== 'undefined' &&
            typeof ctx.p1 !== 'undefined' && typeof ctx.p1.raw !== 'undefined' && typeof ctx.p1.raw.time !== 'undefined' &&
            typeof ctx.p0 !== 'undefined' && typeof ctx.p0.raw !== 'undefined' && typeof ctx.p0.raw.time !== 'undefined'
        )
            return (ctx.p1.raw.time - ctx.p0.raw.time) > 300 * 2 ? value : undefined
        return undefined
    }

    return (
        <Box sx={{ flex: 1, padding: '24px' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    margin: '0 0 15px 0',
                }}
            >
                <BackButton nomer={nomer} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px'
                }}
            >
                <TextField id="FromDate"
                    sx={{ mr: '20px' }}
                    value={fromDate != 0 ? fromDate : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm')}
                    inputProps={{
                        min: '25.07.1996T00:07',
                        max: maxFromDate != 0 ? maxFromDate : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm'),
                    }}
                    onChange={
                        ((e) => {
                            if (moment(toDate).diff(e.target.value, 'days') <= 0) {
                                setFromDate(moment(toDate).subtract(1, 'days').format('yyyy-MM-DDTHH:mm'))
                                return
                            }
                            setFromDate(e.target.value)
                            setMinToDate(moment(e.target.value).add(1, 'days').format('yyyy-MM-DDTHH:mm'))
                        })
                    }
                    variant="outlined" type="datetime-local" />
                <TextField id="ToDate"
                    sx={{ mr: '40px' }}
                    value={toDate != 0 ? toDate : moment().format('yyyy-MM-DDTHH:mm')}
                    inputProps={{
                        min: minToDate != 0 ? minToDate : '24.07.1996T00:07',
                        max: moment().format('yyyy-MM-DDTHH:mm')
                    }}
                    onChange={
                        ((e) => {
                            if (moment(e.target.value).diff(fromDate, 'days') <= 0) {
                                setToDate(moment(fromDate).add(1, 'days').format('yyyy-MM-DDTHH:mm'))
                                return
                            }
                            setMaxFromDate(moment(e.target.value).subtract(1, 'days').format('yyyy-MM-DDTHH:mm'))
                            setToDate(e.target.value)
                        })
                    }
                    variant="outlined" type="datetime-local" />
                <Button sx={{ width: '150px', height: '40px', lineHeight: '40px' }} onClick={selectByDate} variant="contained" color="custom_blue2">Найти</Button>
            </Box>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Box
                    sx={{
                        width: '290px'
                    }}
                >
                    <FuelGraf data={machineFuel} width={180} nomer={nomer} diff={diff} skeleton={{ width: 290, height: 317 }} />
                </Box>

                {
                    machineFuel === null ?
                        (
                            <Skeleton sx={{ borderRadius: '4px', marginLeft: '24px' }} variant="rectangular" width={448} height={317} />
                        ) :
                        (
                            <div className={styles.fuelCard}>
                                <h3>Расход топлива за период</h3>
                                <div className={`${styles.divTable} ${styles.whiteTable}`}>
                                    <div className={styles.divTableBody}>
                                        <div className={styles.divTableRow}>
                                            <div className={styles.divTableCell}>Топливо на холостом ходу</div>
                                            <div className={styles.divTableCell}>{(machineFuelData.idling * (machineFuel.volume / 100)).toFixed(2)} л.</div>
                                        </div>
                                        <div className={styles.divTableRow}>
                                            <div className={styles.divTableCell}>Топливо за время работы</div>
                                            <div className={styles.divTableCell}>{(machineFuelData.work * (machineFuel.volume / 100)).toFixed(2)} л.</div>
                                        </div>
                                        <div className={styles.divTableRow}>
                                            <div className={styles.divTableCell}>Всего</div>
                                            <div className={styles.divTableCell}>{(machineFuelData.idling * (machineFuel.volume / 100) + machineFuelData.work * (machineFuel.volume / 100)).toFixed(2)} л.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.divTable} ${styles.smTable}`}>
                                    <div className={styles.divTableBody}>
                                        <div className={styles.divTableRow}>
                                            <div className={styles.divTableCell}>Расход топлива средний</div>
                                            <div className={styles.divTableCell}>{(((machineFuelData.idling * (machineFuel.volume / 100)) + (machineFuelData.work * (machineFuel.volume / 100))) / ((machineWorkData.idling + machineWorkData.activity) / 3600)).toFixed(2)} л/ч</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.dFlex}>
                                    <span>Заправок за период — {machineFuelData.refill}</span>
                                    <span>Возможные сливы — {machineFuelData.drain}</span>
                                </div>
                            </div>
                        )
                }
            </Box>
            <Box sx={{ height: '600px', width: '100%' }}>
                {
                    machineFuel === null || typeof machineFuelChar.data == 'undefined' || machineFuelChar.data.length == 0 ?
                        (
                            <Skeleton sx={{ borderRadius: '4px', marginTop: '24px' }} variant="rectangular" width={1872} height={600} />
                        ) :
                        (
                            data = machineFuelChar.data.map(function (item, index) {
                                return {
                                    down: index > 0 && machineFuelChar.data[index - 1].fuel > item.fuel ? true : false,
                                    time: item.time,
                                    fuel: item.fuel,
                                    drain: item.drain,
                                    y: item.y,
                                    x: new Date(moment(item.x, "hh:mm DD-MM-YYYY").add(5, 'hours').toDate()),
                                }
                            }),
                            <Line width={1872} height={600}
                                options={{
                                    plugins: {
                                        tooltip: {
                                            usePointStyle: true,
                                            callbacks: {
                                                labelColor(tooltipItem, chart) {
                                                    if (tooltipItem.raw.down)
                                                        return {
                                                            borderColor: 'rgb(255, 0, 0)',
                                                            backgroundColor: 'rgb(255, 0, 0)'
                                                        };
                                                    return {
                                                        borderColor: 'rgb(0, 255, 0)',
                                                        backgroundColor: 'rgb(0, 255, 0)'
                                                    };
                                                },
                                                labelPointStyle: function (context) {
                                                    if (context.raw.down)
                                                        return {
                                                            pointStyle: 'triangle',
                                                            rotation: 180,
                                                        };
                                                    return {
                                                        pointStyle: 'triangle',
                                                        rotation: 0,
                                                    };
                                                }
                                            }
                                        }
                                    },
                                    responsive: true,
                                    scales: {
                                        x: {
                                            min: new Date(moment(fromDate).subtract(3, 'hour').toDate()),
                                            max: new Date(moment(toDate).add(3, 'hour').toDate()),
                                            type: 'time',
                                            time: {
                                                unit: moment(toDate).diff(moment(fromDate), 'days') > 2 ? 'day' : 'hour',
                                                parser: 'DD.MM HH:mm',
                                                tooltipFormat: 'DD.MM HH:mm',
                                                displayFormats: {
                                                    millisecond: 'HH:mm:ss.SSS',
                                                    second: 'HH:mm:ss',
                                                    minute: 'HH:mm',
                                                    hour: 'DD.MM HH:mm',
                                                    day: 'DD.MM'
                                                }
                                            },
                                            display: true,
                                            scaleLabel: {
                                                display: true,
                                                labelString: 'Time'
                                            }
                                        },
                                        y: {
                                            max: typeof machineFuel.volume !== 'undefined' ? machineFuel.volume : 400,
                                            min: 0,
                                            ticks: {
                                                callback: function (value, index, ticks) {
                                                    return `${value} л.`
                                                }
                                            }
                                        }
                                    },
                                    parsing: {
                                        xAxisKey: 'x',
                                        yAxisKey: 'y'
                                    }

                                }}
                                data={{
                                    datasets: [
                                        {
                                            fill: true,
                                            // showLine: false,
                                            spanGaps: true,
                                            label: 'Уровень топлива',
                                            borderColor: 'rgb(53, 162, 235, 0.5)',
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            data: data,
                                            pointBackgroundColor: data.map(item => (
                                                item.drain ? '#ff0000' : '#9ad1f5'
                                            )),
                                            segment: {
                                                borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
                                                backgroundColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)'),
                                                borderDash: ctx => skipped(ctx, [6, 6]),
                                            },
                                            pointHoverRadius: 10,
                                            pointHoverBorderWidth: 2
                                        },
                                    ],
                                }
                                } />
                        )
                }
            </Box>
            {/* {
                machineFuel === null ?
                    (
                        <Skeleton sx={{ borderRadius: '4px', marginTop: '24px' }} variant="rectangular" width={1807} height={360} />
                    ) :
                    (
                        <DrawChart data={machineFuelChar} width={1872} day={(day - 1)} volume={typeof machineFuel.volume !== 'undefined' ? machineFuel.volume : 400} endTime={moment(`${moment().add(+1, 'd').format("YYYY-MM-DD")}T00:00:00.000Z`).unix()} startTime={moment(`${moment().add(-(day - 1), 'days').format("YYYY-MM-DD")}T00:00:00.000Z`).unix()} />
                        
                    )
            } */}

        </Box>
    )
}

export default Fuel