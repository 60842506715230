import React, { useEffect, useState } from 'react'
import { Clusterer, Map, Placemark, Polyline, YMaps } from '@pbe/react-yandex-maps'
import styles from "./map.module.css"
import moment from "moment"

const EventMap = ({ data }) => {
    const [center, setCenter] = useState()
    const [coords, setCords] = useState([])

    const mapState = {
        center: center,
        zoom: 10,
        behaviors: ["default", "scrollZoom"],
        controls: []
    }

    useEffect(() => {
        setCords([...coords, ...data.filter((item, i) => {
            if (typeof item.coords !== 'undefined' && typeof item.coords.lat !== 'undefined' && typeof item.coords.lon !== 'undefined'  && item.coords.lat !== "-0.0" && item.coords.lat !== "0.0" && item.coords.lon !== "-0.0" && item.coords.lon !== "0.0") {
                return item
            }
        })])
    }, [data.length])

    useEffect(() => {
        if (coords.length !== 0) {
            setCenter([coords['0'].coords.lat, coords['0'].coords.lon])
        }
    }, [coords])

    return (
        <div className={styles.map}>
            <YMaps>
                <Map
                    state={mapState}
                    width="100%"
                    height="100%"
                    modules={["package.full"]}
                >
                    <Polyline
                        geometry={[...coords.map(item => { return [item.coords.lat, item.coords.lon] })]}
                        options={{
                            balloonCloseButton: false,
                            strokeColor: '#ffb310',
                            strokeWidth: 2,
                            strokeOpacity: 1,
                        }}
                    />
                    <Clusterer
                        options={{
                            clusterIconLayout: "default#pieChart",
                            clusterIconPieChartRadius: 25,
                            clusterIconPieChartCoreRadius: 10,
                            clusterIconPieChartStrokeWidth: 1,
                            clusterDisableClickZoom: true,
                            clusterHideIconOnBalloonOpen: false,
                            geoObjectHideIconOnBalloonOpen: false
                        }}
                    >
                        {
                            coords.map((item, i) => <Placemark
                                key={i}
                                geometry={[item.coords.lat, item.coords.lon]}
                                properties={{
                                    balloonContentHeader: `${moment(item.time).format("DD.MM.YYYY HH:mm:ss")} ${item.event}`,
                                    balloonContentBody: `Скорость ${item.coords.speed} км/ч`
                                }}
                                options={{
                                    preset: "islands#violetIcon",
                                    iconColor: "#108da8"
                                }}
                            />)
                        }
                    </Clusterer>
                </Map>
            </YMaps>
        </div>
    )
}

export default EventMap