import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";
import * as ShopSelector from "../../store/Shop/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addBasket } from "../../store/Shop/actions";
import * as AuthSelector from "../../store/Auth/selectors";
import { formatPrice } from "../../utils/price";

const CartModal = ({ open, onClose, item, machine, sale }) => {
  const dispatch = useDispatch();
  const basket = useSelector(ShopSelector.basket);
  const _uid = useSelector(AuthSelector.uid);
  const auth = useSelector(AuthSelector.auth);

  const rate = item.rate !== null ? item.rate : item.e_com.rate;
  const [count, setCount] = useState(rate);

  const handleClose = () => {
    onClose();
  };

  const handlerCountAdd = () => {
    setCount(count + rate);
  };

  const handlerCountRemove = () => {
    if (count > rate) setCount(count - rate);
  };

  const handlerInput = (e) => {
    if (e < rate) {
      setCount(rate);
    } else {
      setCount(e);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Добавить в корзину</DialogTitle>
      <DialogContent>
        <Typography>
          {item.title !== "" ? item.title : item.shop.title}
        </Typography>
        <Typography>Код — {item.shop.full_code}</Typography>
        <Typography sx={{ mb: 2 }}>
          {Number(item.e_com.price) !== 0
            ? `Цена — ${
                sale
                  ? formatPrice((item.e_com.price / 100) * (100 - sale))
                  : formatPrice(item.e_com.price)
              } рублей`
            : "Цена и наличие по запросу"}
        </Typography>
        <Box>
          <IconButton
            color="primary"
            onClick={handlerCountRemove}
            aria-label="cart"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <TextField
            onChange={(e) => handlerInput(parseInt(e.target.value))}
            InputProps={{
              readOnly: true,
            }}
            id="standard-basic"
            sx={{ width: 80 }}
            value={count}
            size="small"
            type="number"
            variant="outlined"
          />
          <IconButton
            color="primary"
            onClick={handlerCountAdd}
            aria-label="cart"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
        {rate > 1 && (
          <Typography
            sx={{
              fontSize: "14px",
              mt: 2,
            }}
          >
            Кратность заказа ({rate}).
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start" }}>
        <Button
          onClick={() => {
            let data = {
              item_id: item.id,
              count: count,
              machine_nomer: machine,
            };

            if (!auth) data["uid"] = _uid;
            dispatch(addBasket(data));
            handleClose();
          }}
          autoFocus
        >
          Добавить
        </Button>
        <Button onClick={handleClose}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CartModal;
