import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Crumbs from "./Crumbs";
import ShopSidebar from "./ShopSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as ShopSelector from "../../store/Shop/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addOrder,
  editBasket,
  getShopBasket,
  removeItemShopBasket,
  setShopBasket,
  removeAllShopBasket,
} from "../../store/Shop/actions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Preloader } from "../../components";
import * as AuthSelector from "../../store/Auth/selectors";
import DocumentPdf from "./DocumentPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import icoPdf from "../../assets/pdf-Ico.png";
import { formatPrice } from "../../utils/price";
import { getMachineOwner } from "../../store/Machine/actions";
import * as MachineSelector from "../../store/Machine/selectors";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basket = useSelector(ShopSelector.basket);

  const auth = useSelector(AuthSelector.auth);
  const user = useSelector(AuthSelector.user);
  const machineOwner = useSelector(MachineSelector.machineOwner);

  const [comment, setComment] = useState("");
  const [summ, setSumm] = useState(0);

  useEffect(() => {
    dispatch(getMachineOwner(user.company_id));
    return () => {
      dispatch(setShopBasket(null));
    };
  }, []);

  useEffect(() => {
    if (basket !== null) {
      let sum = 0;

      basket.price.forEach((element) => {
        sum = sum + element.price * element.count;
      });

      setSumm(sum);
    } else {
      dispatch(getShopBasket());
    }
  }, [basket]);

  const removeItem = (id) => {
    let conf = window.confirm("Удалить?");
    if (conf) {
      dispatch(removeItemShopBasket(id));
    }
  };

  const removeBasket = () => {
    let conf = window.confirm("Очистить всю корзину?");
    if (conf) {
      dispatch(removeAllShopBasket());
    }
  };

  const ItemCount = ({ item }) => {
    const [fullCount, setFullCount] = useState(0);

    useEffect(() => {
      if (fullCount === 0) {
        let c = 0;

        const inPriceArray = basket.price.findIndex((el) => el.id === item.id);
        const inNotPriceArray = basket.notPrice.findIndex(
          (el) => el.id === item.id
        );

        if (inPriceArray > -1) {
          c = c + basket.price[inPriceArray].count;
        }

        if (inNotPriceArray > -1) {
          c = c + basket.notPrice[inNotPriceArray].count;
        }

        setFullCount(c);
      }
    }, []);

    const handlerCountAdd = () => {
      dispatch(editBasket({ id: item.id, count: fullCount + item.rate }));
    };

    const handlerCountRemove = () => {
      if (fullCount > item.rate)
        dispatch(editBasket({ id: item.id, count: fullCount - item.rate }));
    };

    const handlerInput = (e) => {
      dispatch(editBasket({ id: item.id, count: e }));
    };

    return (
      <>
        <Box>
          <IconButton
            color="primary"
            onClick={handlerCountRemove}
            aria-label="cart"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <TextField
            onChange={(e) => handlerInput(parseInt(e.target.value))}
            id="standard-basic"
            sx={{ width: 80 }}
            value={item.count}
            size="small"
            type="number"
            variant="outlined"
          />
          <IconButton
            color="primary"
            onClick={handlerCountAdd}
            aria-label="cart"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
      </>
    );
  };

  const sendBasket = () => {
    if (auth) {
      const data = {};
      data.comment = comment;
      data.sum = machineOwner.sale
        ? (summ / 100) * (100 - machineOwner.sale)
        : summ;

      dispatch(addOrder(data, navigate));
    } else {
      navigate("/registration");
    }
  };

  if (basket === null) return <Preloader />;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      <Crumbs />
      <Grid container spacing={3}>
        <Grid item xs={2}>
          {auth && <ShopSidebar b={false} o={true} />}
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Корзина
            </Typography>
            {(typeof basket.price !== "undefined" && basket.price.length > 0) ||
            (typeof basket.notPrice !== "undefined" &&
              basket.notPrice.length > 0) ? (
              <PDFDownloadLink
                document={
                  <DocumentPdf
                    items={[...basket.notPrice, ...basket.price]}
                    sale={machineOwner.sale}
                  />
                }
                fileName="Basket.pdf"
                style={{
                  margin: "0px 24px",
                  width: "auto",
                  height: "25px",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "..."
                  ) : (
                    <Box
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={icoPdf}
                        alt="Download PDF"
                        style={{
                          width: "25px",
                          height: "auto",
                          margin: "0 auto 5px",
                          display: "block",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Печать в PDF
                      </Typography>
                    </Box>
                  )
                }
              </PDFDownloadLink>
            ) : (
              ""
            )}
          </Box>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {auth && <TableCell>Машина</TableCell>}
                  <TableCell>Код</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Наличие</TableCell>
                  <TableCell>Количество</TableCell>
                  <TableCell>Цена с НДС, руб</TableCell>
                  <TableCell>Сумма</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeof basket.price !== "undefined" &&
                  basket.price.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {auth && (
                        <TableCell scope="row">{row.machine_nomer}</TableCell>
                      )}
                      <TableCell>{row.full_code}</TableCell>
                      <TableCell>
                        {row.alter_title === "" ? row.title : row.alter_title}
                      </TableCell>
                      <TableCell>
                        {row.warehouse == 0 ? "Под заказ" : row.warehouse}
                      </TableCell>
                      <TableCell>
                        <ItemCount item={row} />
                      </TableCell>
                      <TableCell>
                        {machineOwner.sale
                          ? formatPrice(
                              (row.price / 100) * (100 - machineOwner.sale)
                            )
                          : formatPrice(row.price)}
                      </TableCell>
                      <TableCell>
                        {machineOwner.sale
                          ? formatPrice(
                              ((row.price * row.count) / 100) *
                                (100 - machineOwner.sale)
                            )
                          : formatPrice(row.price * row.count)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => removeItem(row.id)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {typeof basket.notPrice !== "undefined" &&
                basket.notPrice.length > 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}></TableCell>
                  </TableRow>
                ) : null}
                {typeof basket.notPrice !== "undefined" &&
                  basket.notPrice.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {auth && (
                        <TableCell scope="row">{row.machine_nomer}</TableCell>
                      )}
                      <TableCell>{row.full_code}</TableCell>
                      <TableCell>
                        {row.alter_title === "" ? row.title : row.alter_title}
                      </TableCell>
                      <TableCell>Под заказ</TableCell>
                      <TableCell>
                        <ItemCount item={row} />
                      </TableCell>
                      <TableCell colSpan={2}>По запросу</TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => removeItem(row.id)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography>
                Стоимость деталей в наличии —{" "}
                {machineOwner.sale
                  ? formatPrice((summ / 100) * (100 - machineOwner.sale))
                  : formatPrice(summ)}{" "}
                рублей
              </Typography>
              <Typography>
                Итоговая стоимость расчитывается менеджером
              </Typography>
            </Box>
            {(typeof basket.price !== "undefined" && basket.price.length > 0) ||
            (typeof basket.notPrice !== "undefined" &&
              basket.notPrice.length > 0) ? (
              <Button onClick={() => removeBasket()}>Очистить корзину</Button>
            ) : (
              ""
            )}
          </Box>
          <Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Комментарий к заказу"
                multiline
                sx={{ width: 500 }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                minRows={4}
              />
            </Box>
            <Button
              onClick={() => sendBasket()}
              sx={{ mb: 2 }}
              variant="contained"
            >
              Оформить
            </Button>
            <Typography>
              Нажимая кнопку "Оформить" Вы подтверждаете согласие с{" "}
              <Link
                style={{
                  color: "#1976d2",
                }}
                target="_blank"
                to="/policy"
              >
                пользовательским соглашением
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
