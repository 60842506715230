import { Box, Typography, Paper, Button } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getShopOrders } from "../../../store/Shop/actions"
import * as ShopSelector from "../../../store/Shop/selectors"

const Orders = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const orders = useSelector(ShopSelector.orders)

    useEffect(() => {
        if (orders.length === 0) {
            dispatch(getShopOrders(1))
        }
    }, [])

    return (
        <Box component={Paper} sx={{pt: 1, pl: 2, pr: 2, pb: 1}}>
            <Typography variant="h6">
                Заказы
            </Typography>
            {orders.length > 0 ?
            <Box sx={{mb: 1}}>
                <Typography sx={{mb: 1}}>
                    Создано заказов — {orders.length}
                </Typography>
                <Button variant="contained" onClick={() => navigate(`/shop/orders`)} >Открыть</Button>
            </Box> :
            <Typography sx={{mb: 1}}>
                Заказов нет
            </Typography> }
        </Box>
    )
}

export default Orders