import moment from "moment";
import api from "../../services/api";

import {
  SET_MACHINE,
  SET_MACHINES,
  SET_MACHINE_CLASS,
  SET_MACHINE_EVENT,
  SET_MACHINE_FUEL,
  SET_MACHINE_FUEL_CHAR,
  SET_MACHINE_FUEL_DATA,
  SET_MACHINE_MAP,
  SET_MACHINE_MODEL,
  SET_MACHINE_OWNER,
  SET_MACHINE_TELEMETRY,
  SET_MACHINE_TYPE,
  SET_MACHINE_WORK,
  SET_MACHINE_WORK_CHAR,
  SET_MACHINE_WORK_DATA,
  SET_MACHINE_WORRANTY,
  SET_MACHINE_WORK_TABLE,
} from "./types";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";

export const setMachineMap = (data) => {
  return {
    type: SET_MACHINE_MAP,
    payload: data,
  };
};

export const getMachineMap =
  (type = 0) =>
  async (dispatch, getState) => {
    try {
      const typeqery = type !== 0 ? `?type=${type}` : "";
      const response = await trackPromise(
        api.get(`/api/v1/machines/map${typeqery}`)
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineMap(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachine = (data) => {
  return {
    type: SET_MACHINE,
    payload: data,
  };
};

export const getMachine = (nomer) => async (dispatch, getState) => {
  try {
    const response = await trackPromise(
      api.get(`/api/v1/machines/${nomer}/params`)
    );

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachine(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachines = (data) => {
  return {
    type: SET_MACHINES,
    payload: data,
  };
};

export const getMachines =
  (page = 1, type = 0, params = 0, orderBy = "", orderByType = 1, model = 0) =>
  async (dispatch, getState) => {
    try {
      const typeqery = type !== 0 ? `&type=${type}` : "";
      const modelqery = model !== 0 ? `&model=${model}` : "";
      const qParams = params !== 0 ? `&warto=${params}` : "";
      const qOrderBy =
        orderBy.length > 0
          ? `&orderBy=${orderBy}&orderByType=${orderByType}`
          : "";
      const response = await trackPromise(
        api.get(
          `/api/v1/machines?limit=40&page=${page}${typeqery}${modelqery}${qParams}${qOrderBy}`
        )
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachines(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const serchMachine = (nomer, navigate) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}`);

    if (response.status === 200) {
      const json = await response.data;

      if (typeof json.nomer !== "undefined") {
        navigate(`/machine/${json.nomer}`);
      } else {
        toast.error("Машина не найдена");
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const serchMachineBool = async (nomer) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}`);

    if (response.status === 200) {
      const json = await response.data;

      if (typeof json.nomer !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineTelemetry = (data) => {
  return {
    type: SET_MACHINE_TELEMETRY,
    payload: data,
  };
};

export const getMachineTelemetry = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}/telemetry`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineTelemetry(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineFuel = (data) => {
  return {
    type: SET_MACHINE_FUEL,
    payload: data,
  };
};

export const getMachineFuel = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}/fuel`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineFuel(json));
    }
  } catch (e) {
    dispatch(setMachineFuel(false));
    console.error(e);
  }
};

export const setMachineWork = (data) => {
  return {
    type: SET_MACHINE_WORK,
    payload: data,
  };
};

export const getMachineWork = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}/work`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineWork(json));
    }
  } catch (e) {
    dispatch(setMachineWork(false));
    console.error(e);
  }
};

export const setMachineOwner = (data) => {
  return {
    type: SET_MACHINE_OWNER,
    payload: data,
  };
};

export const getMachineOwner = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/${id}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineOwner(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineFuelChar = (data) => {
  return {
    type: SET_MACHINE_FUEL_CHAR,
    payload: data,
  };
};

export const getMachineFuelChar =
  (nomer, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/machines/${nomer}/fuel?type=char${fromDateQery}${toDateQery}`
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineFuelChar(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineFuelData = (data) => {
  return {
    type: SET_MACHINE_FUEL_DATA,
    payload: data,
  };
};

export const getMachineFuelData =
  (nomer, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/machines/${nomer}/fuel?type=data${fromDateQery}${toDateQery}`
      );
      /* export const getMachineFuelData = (nomer, to) => async (dispatch, getState) => {
                      try {
                          const response = await api.get(`/api/v1/machines/${nomer}/fuel?type=data&from=${moment().add(-(to - 1), 'days').format("YYYY-MM-DD")}&to=${to}`) */

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineFuelData(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineWorkData = (data) => {
  return {
    type: SET_MACHINE_WORK_DATA,
    payload: data,
  };
};

export const getMachineWorkData =
  (nomer, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/machines/${nomer}/work?type=data${fromDateQery}${toDateQery}`
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineWorkData(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineWorkChar = (data) => {
  return {
    type: SET_MACHINE_WORK_CHAR,
    payload: data,
  };
};

export const getMachineWorkChar =
  (nomer, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/machines/${nomer}/work?type=char${fromDateQery}${toDateQery}`
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineWorkChar(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineEvent = (data) => {
  return {
    type: SET_MACHINE_EVENT,
    payload: data,
  };
};

export const getMachineEvent =
  (nomer, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/machines/${nomer}/warning?${fromDateQery}${toDateQery}`
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineEvent(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineType = (data) => {
  return {
    type: SET_MACHINE_TYPE,
    payload: data,
  };
};

export const getMachineType =
  (id = 0) =>
  async (dispatch, getState) => {
    try {
      let response = [];
      if (id === 0) {
        response = await trackPromise(api.get(`/api/v1/types`));
      } else {
        response = await api.get(`/api/v1/types/${id}`);
      }

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineType(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setMachineClass = (data) => {
  return {
    type: SET_MACHINE_CLASS,
    payload: data,
  };
};

export const getMachineClass = (nomer, to) => async (dispatch, getState) => {
  try {
    const response = await trackPromise(api.get(`/api/v1/classes`));

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineClass(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineModel = (data) => {
  return {
    type: SET_MACHINE_MODEL,
    payload: data,
  };
};

export const getMachineModel = (nomer, to) => async (dispatch, getState) => {
  try {
    const response = await trackPromise(api.get(`/api/v1/models`));

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineModel(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineWorranty = (data) => {
  return {
    type: SET_MACHINE_WORRANTY,
    payload: data,
  };
};

export const getMachineWorranty = (nomer, to) => async (dispatch, getState) => {
  try {
    const response = await trackPromise(api.get(`/api/v1/warranty`));

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachineWorranty(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setMachineWorkTable = (data) => {
  return {
    type: SET_MACHINE_WORK_TABLE,
    payload: data,
  };
};

export const getMachineWorkTable =
  (nomer, page = 1, fromDate = 0, toDate = 0) =>
  async (dispatch, getState) => {
    try {
      const fromDateQery =
        fromDate !== 0
          ? `&from=${moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&from=${moment()
              .subtract(2, "days")
              .format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;
      const toDateQery =
        toDate !== 0
          ? `&to=${moment(toDate).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`
          : `&to=${moment().format("YYYY-MM-DDTHH:mm:ss.sss")}Z`;

      const response = await api.get(
        `/api/v1/statistic/worktable?sn=${nomer}&limit=100&page=${page}${fromDateQery}${toDateQery}`
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setMachineWorkTable(json));
      }
    } catch (e) {
      console.error(e);
    }
  };
