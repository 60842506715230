import { Button } from '@mui/material'
import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from 'react-router-dom'


const BackButton = ({ nomer = null, url = null }) => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (url !== null) {
            navigate(url)
        } else if (nomer !== null) {
            navigate(`/machine/${nomer}`)
        } else if (url === null && nomer === null) {
            navigate(-1)
        }
    }

    return <Button onClick={handleClick} sx={{
        fontSize: '13px',
        textTransform: 'none',
        color: "#1B89C7",
        fontWeight: '400',
        pl: 0
    }} startIcon={<ArrowBackIosNewIcon sx={{ color: '#7E84A3', height: '11px' }} />}>{url === null && nomer !== null ? `К машине ${nomer}` : 'Назад'}</Button>
}

export default BackButton