import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as MachineSelector from "../../store/Machine/selectors";
import * as AuthSelector from "../../store/Auth/selectors";
import {
  getMachineClass,
  getMachineModel,
  getMachines,
  getMachineType,
  getMachineWorranty,
  setMachineClass,
  setMachineModel,
  setMachines,
  setMachineType,
  setMachineWorranty,
  serchMachine,
} from "../../store/Machine/actions";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Pagination,
  TextField,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Divider,
  Typography,
  FormControl,
} from "@mui/material";
import { Preloader, Table } from "../../components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import moment from "moment";
import { usePromiseTracker } from "react-promise-tracker";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { addMachineRequest, setAddMachineLoad } from "../../store/Auth/actions";

function Machines() {
  const { nomer } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { promiseInProgress } = usePromiseTracker();

  const machines = useSelector(MachineSelector.machines);
  const classes = useSelector(MachineSelector.classes);
  const type = useSelector(MachineSelector.type);
  const model = useSelector(MachineSelector.model);
  const worranty = useSelector(MachineSelector.worranty);
  const addMachineLoad = useSelector(AuthSelector.addMachineLoad);
  const user = useSelector(AuthSelector.user);

  const [type_id, setType_id] = useState(0);
  const [model_id, setModel_id] = useState(0);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState(0);
  const [machine_nomer, setMachineNomer] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderByType, setOrderByType] = useState(0);
  const [openAddMachine, setOpenAddMachine] = useState(false);

  const [psm, setPsm] = useState([]);
  const [formAddMachineError, setFormAddMachineError] = useState(false);
  const [sved, setSved] = useState([]);
  const [loader, setLoader] = useState(false);

  const fieldsParams = [
    {
      id: "1",
      label: "Предстоит ТО",
    },
    {
      id: "2",
      label: "Гарантия",
    },
    {
      id: "0",
      label: "Все",
    },
  ];

  const ref = useRef();

  useEffect(() => {
    if (addMachineLoad !== null && typeof addMachineLoad === "boolean")
      setTimeout(() => {
        setLoader(false);
        if (addMachineLoad === true) {
          dispatch(setAddMachineLoad(null));
          setFormAddMachineError(false);
          setOpenAddMachine(false);
          setPsm([]);
          setSved([]);
        }
      }, 1500);
  }, [addMachineLoad]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qPage = query.get("page") !== null ? query.get("page") : 1;
    const qType = query.get("type") !== null ? query.get("type") : 0;
    const qParams = query.get("params") !== null ? query.get("params") : 0;
    const qOrderBy =
      query.get("orderBy") !== null ? query.get("orderBy") : "nomer";
    const qOrderByType =
      query.get("orderByType") !== null ? query.get("orderByType") : "DESC";

    if (
      qPage !== null ||
      qType !== null ||
      qParams !== null ||
      qOrderBy.length > 0
    ) {
      setPage(parseInt(qPage));
      setType_id(parseInt(qType));
      setParams(parseInt(qParams));
      setOrderBy(qOrderBy);
      setOrderByType(qOrderByType);
      dispatch(
        getMachines(
          parseInt(qPage),
          parseInt(qType),
          parseInt(qParams),
          qOrderBy,
          qOrderByType
        )
      );
      navigate(
        `${location.pathname}?page=${qPage !== 0 ? qPage : 1}${
          parseInt(qType) !== 0 ? "&type=" + qType : ""
        }${parseInt(qParams) !== 0 ? "&params=" + qParams : ""}${
          qOrderBy.length > 0 && qOrderByType !== 0
            ? "&orderBy=" + qOrderBy + "&orderByType=" + qOrderByType
            : ""
        }`
      );
    } else {
      dispatch(getMachines());
    }
    dispatch(getMachineClass());
    dispatch(getMachineType());
    dispatch(getMachineModel());
    dispatch(getMachineWorranty());
    return () => {
      dispatch(setMachines([]));
      dispatch(setMachineClass([]));
      dispatch(setMachineType([]));
      dispatch(setMachineModel([]));
      dispatch(setMachineWorranty([]));
    };
  }, []);

  const editType = ({ typeId = 0, modelid = 0 }) => {
    setPage(1);
    setType_id(typeId);
    setModel_id(modelid);
    dispatch(setMachines([]));
    dispatch(
      getMachines(
        1,
        parseInt(typeId),
        parseInt(params),
        orderBy,
        orderByType,
        parseInt(modelid)
      )
    );
    navigate(
      `${location.pathname}?page=${1}${
        parseInt(typeId) !== 0 ? "&type=" + typeId : ""
      }${parseInt(modelid) !== 0 ? "&model=" + modelid : ""}${
        parseInt(params) !== 0 ? "&params=" + params : ""
      }
      `
    );
  };

  const editParams = (paramsNew) => {
    setPage(1);
    setParams(paramsNew);
    dispatch(setMachines([]));
    dispatch(
      getMachines(
        1,
        type_id,
        parseInt(paramsNew),
        orderBy,
        orderByType,
        parseInt(model_id)
      )
    );
    navigate(
      `${location.pathname}?page=${1}${
        parseInt(type_id) !== 0 ? "&type=" + type_id : ""
      }${parseInt(model_id) !== 0 ? "&model=" + model_id : ""}${
        parseInt(paramsNew) !== 0 ? "&params=" + paramsNew : ""
      }${
        orderBy.length > 0 && orderByType !== 0
          ? "&orderBy=" + orderBy + "&orderByType=" + orderByType
          : ""
      }`
    );
  };

  const orderByMachines = (orderByNew) => {
    var qOrderBy = orderBy;
    var qOrderByType = orderByType;
    if (qOrderBy !== orderByNew) {
      qOrderBy = orderByNew;
      qOrderByType = "DESC";
    } else if (qOrderByType === "DESC") {
      qOrderByType = "ASC";
    } else {
      qOrderByType = "DESC";
    }
    setOrderBy(qOrderBy);
    setOrderByType(qOrderByType);
    setPage(1);
    dispatch(setMachines([]));
    dispatch(
      getMachines(
        1,
        parseInt(type_id),
        parseInt(params),
        qOrderBy,
        qOrderByType,
        parseInt(model_id)
      )
    );
    navigate(
      `${location.pathname}?page=${1}${
        parseInt(type_id) !== 0 ? "&type=" + type_id : ""
      }${parseInt(model_id) !== 0 ? "&model=" + model_id : ""}${
        parseInt(params) !== 0 ? "&params=" + params : ""
      }${
        qOrderBy.length > 0 && qOrderByType !== 0
          ? "&orderBy=" + qOrderBy + "&orderByType=" + qOrderByType
          : ""
      }`
    );
  };

  const handlePage = (page) => {
    setPage(page);
    dispatch(
      getMachines(
        page,
        parseInt(type_id),
        parseInt(params),
        orderBy,
        orderByType,
        parseInt(model_id)
      )
    );
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    navigate(
      `${location.pathname}?page=${page}${
        parseInt(type_id) !== 0 ? "&type=" + type_id : ""
      }${parseInt(model_id) !== 0 ? "&model=" + model_id : ""}${
        parseInt(params) !== 0 ? "&params=" + params : ""
      }${
        orderBy.length > 0 && orderByType !== 0
          ? "&orderBy=" + orderBy + "&orderByType=" + orderByType
          : ""
      }`
    );
  };

  const next = (nomer, black_date) => {
    /* if (moment(black_date).unix() >= moment().unix()) {
            navigate((`/machine/${nomer}`))
        } else {
            toast.info('Подписка к данной машине истекла')
        } */
    navigate(`/machine/${nomer}`);
  };

  const machineNomer = (item) => {
    return (
      <span
        style={{
          cursor: "pointer",
          color: "#3498db",
          fontWeight: "600",
        }}
        className="span-link"
        onClick={() => next(item.nomer, item.black_date)}
      >
        {item.nomer}
      </span>
    );
  };

  const status = (item) => {
    return typeof item.warranty_status === "undefined" ||
      typeof item.warranty === "undefined" ||
      item.warranty == null ? (
      <Box textAlign="center">
        <ErrorIcon
          titleAccess="Нет информации"
          fontSize="small"
          color="action"
        />
      </Box>
    ) : item.warranty_status ? (
      <Box textAlign="center">
        <CheckCircleIcon
          titleAccess="Гарантия активна"
          fontSize="small"
          color="success"
        />
      </Box>
    ) : (
      <Box textAlign="center">
        <ErrorIcon
          fontSize="small"
          titleAccess="Гарантия не активна"
          sx={{ color: "#ff3300e3" }}
        />
      </Box>
    );
  };

  const period_to = (item) => {
    if (
      typeof item.period_to === "undefined" ||
      item.period_to == null ||
      typeof item.period_to_title === "undefined" ||
      item.period_to_title == null ||
      typeof item.motoHours === "undefined" ||
      typeof item.warranty_status === "undefined" ||
      item.warranty_status == null ||
      item.warranty_status === 0
    )
      return (
        <Box textAlign="center">
          <ErrorIcon
            titleAccess="Нет информации"
            fontSize="small"
            color="action"
          />
        </Box>
      );
    if (
      item.date_next_to != null &&
      moment(item.date_next_to).diff(moment(), "days") < 32
    ) {
      var next_to =
        (Math.floor(Number(item.motoHours) / Number(item.period_to_title)) +
          1) *
        Number(item.period_to_title);
      return (
        <Box textAlign="center">
          <CircleNotificationsIcon
            titleAccess={`ТО ожидается ${moment(item.date_next_to).format(
              "DD.MM.YYYY"
            )}`}
            fontSize="small"
            sx={{ color: "#ff3300e3", mb: "5px" }}
          />
          <Box>ТО-{next_to}</Box>
        </Box>
      );
    }
    return (
      <Box textAlign="center">
        <CheckCircleIcon
          titleAccess="ТО произведено"
          fontSize="small"
          color="success"
        />
      </Box>
    );
  };

  const tabCols = [
    {
      title: "Номер",
      val: machineNomer,
      orderBy: "nomer",
      func: orderByMachines,
    },
    { title: "Класс", code: "classes_id", vars: "classes" },
    { title: "Тип машины", code: "type_id", vars: "type" },
    {
      title: "Модель",
      code: "model",
      vars: "model",
      orderBy: "model",
      func: orderByMachines,
    },
    { title: "Наработка", code: "motoHours" },
    { title: "Последние данные", code: "time", type: "dateTime" },
    { title: "Гарантия", code: "warranty", vars: "worranty" },
    { title: "Статус гарантии", val: status, style: { textAlign: "center" } },
    { title: "ТО", val: period_to, style: { textAlign: "center" } },
    { title: "Оповещения", code: "date_event", style: { textAlign: "center" } },
  ];

  const tabParams = {
    classes,
    type,
    model,
    worranty,
  };

  const Loader = () => {
    switch (addMachineLoad) {
      case "load":
        return (
          <div className="cssload-container">
            <div className="cssload-speeding-wheel"></div>
          </div>
        );
      case "error":
        return (
          <>
            <Typography>Необходимо добавить файлы</Typography>
            <ErrorTwoToneIcon sx={{ color: "#d32f2f", fontSize: "50px" }} />
          </>
        );
      case true:
        return (
          <CheckCircleTwoToneIcon sx={{ color: "#2e7d32", fontSize: "50px" }} />
        );
      case null:
        return null;
      default:
        if (typeof addMachineLoad === "string" && addMachineLoad.length > 0) {
          return (
            <>
              <Typography>{addMachineLoad}</Typography>
              <ErrorTwoToneIcon sx={{ color: "#d32f2f", fontSize: "25px" }} />
            </>
          );
        }
        return null;
    }
  };

  if (promiseInProgress) return <Preloader />;

  return (
    <Box ref={ref} sx={{ flex: 1, padding: "24px", overflow: "scroll" }}>
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={1.7}
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "#f5f6fa",
              padding: "20px 24px",
              borderRadius: "4px",
              marginBottom: "24px",
            }}
          >
            <h3
              style={{
                margin: "0",
                marginBottom: "10px",
              }}
            >
              Поиск по номеру
            </h3>
            <TextField
              margin="dense"
              id="name"
              label="Номер"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              value={machine_nomer}
              onChange={(e) => setMachineNomer(e.target.value)}
            />
            <Button
              onClick={() => dispatch(serchMachine(machine_nomer, navigate))}
              variant="contained"
              color="custom_blue2"
            >
              Найти
            </Button>
          </Box>
          <Box
            sx={{
              background: "#f5f6fa",
              padding: "20px 24px",
              borderRadius: "4px",
            }}
          >
            <h3
              style={{
                margin: "0",
                marginBottom: "10px",
              }}
            >
              Фильтр
            </h3>

            <TextField
              id="select-currency-native"
              margin="normal"
              select
              label="Тип машины"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              size="small"
              value={type_id}
              onChange={(e) =>
                editType({
                  typeId: parseInt(e.target.value),
                })
              }
            >
              <option value={0}>Все</option>
              {type.length &&
                type.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
            </TextField>
            <TextField
              id="select-currency-native"
              margin="normal"
              select
              label="Модель"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              size="small"
              value={model_id}
              onChange={(e) =>
                editType({
                  modelid: parseInt(e.target.value),
                })
              }
            >
              <option value={0}>Все</option>
              {model.length &&
                model.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
            </TextField>
            <FormLabel id="radio-buttons-group-label">Параметры</FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {fieldsParams.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  checked={params == item.id}
                  control={<Checkbox />}
                  label={item.label}
                  onChange={(e) => editParams(e.target.value)}
                />
              ))}
            </RadioGroup>
          </Box>
          {typeof user !== "undefined" && user.company_id && (
            <Box
              sx={{
                background: "#f5f6fa",
                padding: "20px 10px",
                borderRadius: "4px",
                textAlign: "center",
                mt: "20px",
              }}
            >
              <Button
                onClick={() => setOpenAddMachine(true)}
                variant="contained"
                color="custom_blue2"
              >
                Добавить машину
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={10.3}>
          <Table
            cols={tabCols}
            data={typeof machines.data !== "undefined" ? machines.data : null}
            params={tabParams}
            rows={40}
          />
          <Pagination
            sx={{
              marginTop: "24px",
              marginBottom: "24px",
            }}
            count={
              typeof machines.last_page !== "undefined" ? machines.last_page : 0
            }
            page={page}
            shape="rounded"
            color="custom_blue2"
            onChange={(event, page) => handlePage(page)}
          />
        </Grid>
      </Grid>
      <Dialog open={openAddMachine} onClose={() => setOpenAddMachine(false)}>
        <FormControl
          component="form"
          onSubmit={(e) => {
            e.preventDefault();

            if (psm.length === 0 || sved.length === 0) {
              setFormAddMachineError(true);
              return false;
            }
            dispatch(addMachineRequest(user.id, user.company_id, sved, psm));
            setLoader(true);
          }}
        >
          <DialogTitle>Заявка на добавление машины</DialogTitle>
          <DialogContent>
            <>
              <Box
                sx={{
                  mt: "15px",
                  mb: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    width: "65%",
                  }}
                >
                  ПСМ *
                  <input
                    multiple
                    hidden
                    type="file"
                    onChange={(e) => {
                      setPsm([...psm, ...e.target.files]);
                      setFormAddMachineError(false);
                    }}
                  />
                </Button>
              </Box>
              {psm.length !== 0 && (
                <List>
                  {psm.map((item, i) => (
                    <>
                      <ListItem
                        key={item.name}
                        secondaryAction={
                          <IconButton
                            color="error"
                            onClick={() =>
                              setPsm((items) =>
                                items.filter((item, index) => i !== index)
                              )
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              )}
              <Box
                sx={{
                  mt: "15px",
                  mb: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    width: "65%",
                  }}
                >
                  Свидетельство о регистрации машины(трактора) *
                  <input
                    multiple
                    hidden
                    type="file"
                    onChange={(e) => {
                      setFormAddMachineError(false);
                      setSved([...sved, ...e.target.files]);
                    }}
                  />
                </Button>
              </Box>
              {sved.length !== 0 && (
                <List>
                  {sved.map((item, i) => (
                    <>
                      <ListItem
                        key={item.name}
                        secondaryAction={
                          <IconButton
                            color="error"
                            onClick={() =>
                              setSved((items) =>
                                items.filter((item, index) => i !== index)
                              )
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              )}
            </>
            {formAddMachineError ? (
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "12px",
                  color: "red",
                }}
              >
                Необходимо добавить все файлы
              </Typography>
            ) : (
              ""
            )}
            <Typography
              sx={{
                mt: 2,
                fontSize: "14px",
              }}
            >
              Нажимая кнопку "Отправить" Вы подтверждаете согласие на{" "}
              <Link
                style={{
                  color: "#1976d2",
                }}
                target="_blank"
                to="/policy"
              >
                обработку персональных данных
              </Link>{" "}
              и с{" "}
              <Link
                style={{
                  color: "#1976d2",
                }}
                target="_blank"
                to="/policy"
              >
                пользовательским соглашением
              </Link>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" sx={{ marginLeft: "83%" }} variant="text">
              Отправить
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
      <Dialog open={loader}>
        <DialogContent
          sx={{
            width: "350px",
            height: "250px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Machines;
