import { Button, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import "./style.css"

const Fuel = ({ data, width, nomer = null, diff, skeleton = { width: 344, height: 267 } }) => {
    const navigate = useNavigate()

    const Graf = ({ data, width, nomer }) => {
        const fuelLevel = data.level * 0.75

        let fuelColor = "#72C900";
        if (data.level < 30) {
            fuelColor = "#fbb03b";
        }
        if (data.level < 15) {
            fuelColor = "#f15a24";
        }

        const volume = data.volume

        const indicatorCoords = level => {
            let angle = (level * 360 * 0.75 / 100) - 225;
            let cos = Math.cos(angle * (Math.PI / 180));
            let sin = Math.sin(angle * (Math.PI / 180));
            let x1 = 10 + cos * 5;
            let y1 = 10 + sin * 5;
            let x2 = 10 + cos * 10;
            let y2 = 10 + sin * 10;
            return [x1, y1, x2, y2];
        }

        const [x1, y1, x2, y2] = indicatorCoords(data.level);
        let balance = data.liters;

        return (
            <div id="fuel" onClick={() => nomer !== null ? navigate(`/machine/${nomer}/fuel`) : null}>
                <p>Уровень топлива</p>
                <svg height={width} width={width} viewBox="0 0 20 20">
                    <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke="#E6E6E6"
                        strokeWidth="10"
                        strokeDasharray={`${75 * 31.4 / 100} 31.4`}
                        transform="rotate(-225, 10, 10)" />
                    <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke={fuelColor}
                        strokeWidth="10"
                        strokeDasharray={`${fuelLevel * 31.4 / 100} 31.4`}
                        transform="rotate(-225, 10, 10)" />
                    <circle r="6" cx="10" cy="10" fill="#FFFFFF" />

                    <path d="M0,10h10V0C10,5.5,5.5,10,0,10z" transform="translate(4.85, 14.23) scale(0.07) rotate(-45)" fill="#FFFFFF" />
                    <path d="M0,10h10V0C10,5.5,5.5,10,0,10z" transform="translate(2.93, 16.14) scale(0.07) rotate(45)" fill="#FFFFFF" />
                    <path d="M0,10h10V0C10,5.5,5.5,10,0,10z" transform="translate(15.15, 14.23) scale(0.07) rotate(135)" fill="#FFFFFF" />
                    <path d="M0,10h10V0C10,5.5,5.5,10,0,10z" transform="translate(17.05, 16.14) scale(0.07) rotate(45)" fill="#FFFFFF" />
                    <line x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth="0.25" stroke={fuelColor} />
                    <g transform="translate(5.5, 15.5)">
                        <rect x="0" y="0" width="9" height="3" fill="#FFFFFF" stroke="#E6E6E6" strokeWidth="0.2" rx="0.4" ry="0.4" />
                        <line x1="4.5" x2="4.5" y1="0.5" y2="2.5" stroke="#E6E6E6" strokeWidth="0.2" />
                        <text textAnchor="middle" className="svgText small" transform="translate(2.2, 2)">{balance}</text>
                        <text textAnchor="middle" className="svgText small" transform="translate(6.7, 2)">{volume}</text>
                    </g>
                    <text textAnchor="middle" transform="translate(10, 11.4)" className="svgText big">{data.level}%</text>
                </svg>
            </div>
        )
    }

    const NoData = ({ button = false }) => {
        return (
            <Box id="fuel" sx={{
                display: 'flex',
                flexDirection: "column"
            }}>
                <p>Уровень топлива</p>
                <div style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <span style={{
                        fontSize: '12px',
                        color: "#787878"
                    }}>Нет данных</span>
                </div>
                {
                    button ? (
                        <Button
                            onClick={() => navigate(`/machine/${nomer}/fuel`)}
                            variant="outlined"
                            color="custom_blue"
                            sx={{
                                fontSize: "13px",
                                borderRadius: "20px",
                                margin: "0 30px 30px 30px"
                            }}
                        >Подробнее</Button>
                    ) : null
                }
            </Box>
        )
    }

    return data !== null ? (
        typeof data.level !== 'undefined' ? (
            diff === 0 ? (
                <Graf data={{
                    level: data.level,
                    drain: data.drain,
                    liters: data.liters,
                    volume: data.volume
                }} width={width} nomer={nomer} />
            ) : diff <= 14 ? (
                <NoData button={true} />
            ) : (
                <NoData />
            )
        ) : (
            <NoData />
        )
    ) : <Skeleton sx={{ borderRadius: '4px' }} variant="rectangular" width={skeleton.width} height={skeleton.height} />
}

export default Fuel