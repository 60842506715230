import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShopSidebar from "./ShopSidebar";
import * as ShopSelector from "../../store/Shop/selectors";
import * as MachineSelector from "../../store/Machine/selectors";
import { useEffect } from "react";
import * as AuthSelector from "../../store/Auth/selectors";
import {
  getShopCategories,
  getShopMachine,
  getShopModelGroup,
  getShopModelGroupList,
  setShopCategories,
} from "../../store/Shop/actions";
import ButtonsList from "./ButtonsList";
import Crumbs from "./Crumbs";
import {
  getMachine,
  getMachineType,
  setMachine,
  setMachineType,
  setMachines,
} from "../../store/Machine/actions";
import { Preloader } from "../../components";

const Shop = () => {
  const { nomer, model } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const machine = useSelector(MachineSelector.machine);
  const type = useSelector(MachineSelector.type);
  const categories = useSelector(ShopSelector.categories);
  const model_group = useSelector(ShopSelector.model_group);
  const shopModelGrouoList = useSelector(ShopSelector.shopModelGrouoList);

  const auth = useSelector(AuthSelector.auth);

  useEffect(() => {
    if (nomer) {
      dispatch(getMachine(nomer));
    }

    return () => {
      dispatch(setMachine([]));
      dispatch(setMachines([]));
      dispatch(setMachineType([]));
      dispatch(setShopCategories([]));
    };
  }, [nomer]);

  useEffect(() => {
    if (machine.nomer === parseInt(nomer)) {
      dispatch(getMachineType(machine.type_id));
      dispatch(getShopModelGroupList());
      dispatch(
        getShopModelGroup(
          machine.real_model_id !== null
            ? machine.real_model_id
            : machine.model_id
        )
      );
    }
  }, [machine]);

  useEffect(() => {
    if (typeof model_group.group_id !== "undefined") {
      if (categories.length === 0) {
        dispatch(
          getShopCategories(
            shopModelGrouoList.filter(
              (item) => item.id === model_group.group_id
            )[0].machine_type_id,
            model_group.group_id
          )
        );
      }
    }

    if (model) {
      dispatch(getShopCategories(1, parseInt(model)));
    }
  }, [model_group, shopModelGrouoList, model]);

  if (categories.length === 0) return <Preloader />;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      {!model && <Crumbs machine={machine} />}
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ShopSidebar b={true} o={true} auth={auth} />
        </Grid>
        <Grid item xs={10}>
          {categories.length > 0 ? (
            <Box
              sx={{
                width: "800px",
                height: "800px",
                margin: "0 auto",
                position: "relative",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                src={`http://machine.dstapi.ru/${
                  type.image ? type.image : "/upload/cover/1682046808D9.jpg"
                }`}
                alt={type.title}
              />
              {categories.map((item) => {
                if (item.hidden === 1) return null;
                if (item.children !== null) {
                  return (
                    <Box
                      key={item.id}
                      variant="contained"
                      sx={{
                        position: "absolute",
                        left: `${item.button_cords[0]}%`,
                        top: `${item.button_cords[1]}%`,
                        borderRadius: "4px",
                        padding: "8px 10px",
                        textTransform: "none",
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "normal",
                        backgroundColor: "#1976d2",
                        color: "rgb(255, 255, 255)",
                        userSelect: "none",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() =>
                        navigate(
                          `${model ? `/model/${model}` : ""}/shop/${
                            !model ? `${machine.nomer}/` : ""
                          }c/${item.id}/`
                        )
                      }
                    >
                      {item.title}
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={item.id}
                      variant="contained"
                      sx={{
                        position: "absolute",
                        left: `${item.button_cords[0]}%`,
                        top: `${item.button_cords[1]}%`,
                        borderRadius: "4px",
                        padding: "6px 10px 8px",
                        textTransform: "none",
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "normal",
                        backgroundColor: "#1976d2",
                        color: "rgb(255, 255, 255)",
                        userSelect: "none",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                    >
                      {item.title}
                    </Box>
                  );
                }
              })}
            </Box>
          ) : (
            <>Load</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Shop;
