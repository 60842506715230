import { Box, Typography } from "@mui/material";

const Item = ({ title, parent = false, onClick = null, children }) => (
    <Box>
        <Typography onClick={onClick} sx={{
            fontSize: '14px',
            fontWeight: parent ? 700 : 400,
            color: '#035B86',
            cursor: "pointer",
            ":hover": {
                textDecoration: "underline"
            }
        }} component={parent ? "p" : "li"}>{title}</Typography>
        {
            children !== null ? (
                <Box component="ul" sx={{
                    pl: 3,
                    mt: '6px'
                }}>
                    {children}
                </Box>
            ) : null
        }
    </Box>
)

export default Item