import api from "../../services/api";
import { toast } from "react-toastify";

import {
  SET_SHOP_BASKET,
  SET_SHOP_BUILDS,
  SET_SHOP_CATEGORIES,
  SET_SHOP_CATEGORY,
  SET_SHOP_MACHINE,
  SET_SHOP_MODEL,
  SET_SHOP_MODEL_GROUP,
  SET_SHOP_MODEL_GROUP_LIST,
  SET_SHOP_ORDER,
  SET_SHOP_ORDERS,
  SET_SHOP_ORDER_STATUS,
  SET_SHOP_ORDER_STATUSES,
  SET_SHOP_PART,
  SET_SHOP_SCHEME,
  SET_SHOP_SCHEME_ITEMS,
  SET_SHOP_SUBCATEGORY,
  SET_SHOP_SUBSUBCATEGORY,
} from "./types";

export const setShopMachine = (data) => {
  return {
    type: SET_SHOP_MACHINE,
    payload: data,
  };
};

export const getShopMachine = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(
      `http://test.dstapi.ru/api/v1/parts/machine?nomer=${nomer}`
    );

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopMachine(json.message.data));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopBasket = (data) => {
  return {
    type: SET_SHOP_BASKET,
    payload: data,
  };
};

export const getShopBasket = () => async (dispatch, getState) => {
  try {
    const auth = getState().AuthReducer.auth;
    const uid = getState().AuthReducer.uid;
    const response = await api.get(
      `/api/v1/shop/basket/item${!auth ? `?uid=${uid}` : ""}`
    );

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopBasket(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const removeItemShopBasket = (id) => async (dispatch, getState) => {
  const user = getState().AuthReducer.user;
  try {
    const response = await api.delete(`/api/v1/shop/basket/item/${id}`);

    if (response.status === 200) {
      dispatch(getShopBasket());
    }
  } catch (e) {
    console.error(e);
  }
};

export const removeAllShopBasket = () => async (dispatch, getState) => {
  try {
    const response = await api.delete(`/api/v1/shop/basket`);

    if (response.status === 200) {
      toast.success(`Корзина очищена`);
      dispatch(setShopBasket(null));
    } else toast.error(`Ошибка очистки`);
  } catch (e) {
    console.error(e);
  }
};

export const addBasket = (data) => async (dispatch, getState) => {
  try {
    const response = await api.post(`/api/v1/shop/basket/item`, data);

    if (response.status === 201) {
      toast.success(`Добавлено`);
      dispatch(getShopBasket());
    } else {
      toast.error(`Ошибка`);
    }
  } catch (e) {
    console.error(e);
  }
};

export const editBasket = (data) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/v1/shop/basket/item/${data.id}`, {
      count: data.count,
    });
    const json = await response.data;
    if (response.status === 200) {
      dispatch(getShopBasket());
    }
  } catch (error) {
    console.error(error);
  }
};

export const setShopOrderStatuses = (data) => {
  return {
    type: SET_SHOP_ORDER_STATUSES,
    payload: data,
  };
};

export const getShopOrderStatuses = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/order/statuses`);

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setShopOrderStatus(json[0].id));

      dispatch(setShopOrderStatuses(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopOrderStatus = (data) => {
  return {
    type: SET_SHOP_ORDER_STATUS,
    payload: data,
  };
};

export const setShopOrders = (data) => {
  return {
    type: SET_SHOP_ORDERS,
    payload: data,
  };
};

export const getShopOrders = (status) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/orders?status=${status}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopOrders(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const addOrder = (data, navigate) => async (dispatch, getState) => {
  try {
    const response = await api.post(`/api/v1/shop/orders`, data);
    const json = await response.data;
    if (response.status === 201) {
      navigate(`/shop/orders/${json.message}`);
      dispatch(setShopBasket(null));
      toast.success(`Заказ №${json.message} оформлен`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const setOrder = (data) => {
  return {
    type: SET_SHOP_ORDER,
    payload: data,
  };
};

export const getOrder = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/orders/${id}`, {});
    const json = await response.data;

    if (response.status === 200) {
      dispatch(setOrder(json));
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeOrder = (id, navigate) => async (dispatch, getState) => {
  try {
    const user = getState().AuthReducer.user;

    const data = {
      id: id,
      status: 7,
      user_id: user.id,
    };

    const response = await api.put(`/api/v1/order/status`, data);

    if (response.status === 200) {
      toast.success("Заказ отменен");
      navigate("/shop/orders");
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopCategories = (data) => {
  return {
    type: SET_SHOP_CATEGORIES,
    payload: data,
  };
};

export const getShopCategories =
  (type, model) => async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/v1/shop/map?model_id=${model}&type_id=${type}`
      );

      if (response.status === 200) {
        const json = await response.data;
        if (json.length > 0) dispatch(setShopCategories(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setShopBuilds = (data) => {
  return {
    type: SET_SHOP_BUILDS,
    payload: data,
  };
};

export const getShopBuilds = (model, subPart) => async (dispatch, getState) => {
  try {
    const response = await api.get(
      `/api/v1/shop/installation?map_id=${subPart}&model_id=${model}`
    );

    if (response.status === 200) {
      const json = await response.data;
      if (json.length > 0) dispatch(setShopBuilds(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopCategory = (data) => {
  return {
    type: SET_SHOP_CATEGORY,
    payload: data,
  };
};

export const setShopSubCategory = (data) => {
  return {
    type: SET_SHOP_SUBCATEGORY,
    payload: data,
  };
};

export const setShopSubSubCategory = (data) => {
  return {
    type: SET_SHOP_SUBSUBCATEGORY,
    payload: data,
  };
};

export const setShopPart = (data) => {
  return {
    type: SET_SHOP_PART,
    payload: data,
  };
};

export const setShopScheme = (data) => {
  return {
    type: SET_SHOP_SCHEME,
    payload: data,
  };
};

export const getShopScheme = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/installation/${id}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopScheme(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopSchemeItems = (data) => {
  return {
    type: SET_SHOP_SCHEME_ITEMS,
    payload: data,
  };
};

export const getShopSchemeItems = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/installation/${id}/item`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopSchemeItems(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setShopModelGroup = (data) => {
  return {
    type: SET_SHOP_MODEL_GROUP,
    payload: data,
  };
};

export const setShopModelGroupList = (data) => {
  return {
    type: SET_SHOP_MODEL_GROUP_LIST,
    payload: data,
  };
};

export const getShopModelGroupList = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/model/group`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopModelGroupList(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getShopModelGroup = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(
      `/api/v1/shop/model/group/items?model_id=${id}`
    );

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setShopModelGroup(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setModelShop = (data) => {
  return {
    type: SET_SHOP_MODEL,
    payload: data,
  };
};

export const getModelShop =
  (id = 0) =>
  async (dispatch, getState) => {
    try {
      const parent_id = id !== 0 ? `?parent_id=${id}` : "";
      const response = await api.get(`/api/v1/shop/model${parent_id}`);

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setModelShop(json));
      }
    } catch (e) {
      console.error(e);
    }
  };
