import { addMachineLoad } from "./selectors";
import {
  SET_AUTH,
  SET_AUTH_EMAIL,
  SET_AUTH_PASSWORD,
  SET_AUTH_RE_PASSWORD,
  SET_COMPANYS,
  SET_EXPRES_IN,
  SET_REG_LOAD,
  SET_ADD_MACHINE_LOAD,
  SET_TOKEN,
  SET_UID,
  SET_USER,
  SET_SOCKET,
  SET_LIST_NOTS,
  SET_TICKET_SEL,
  SET_ALL_TICKETS,
  SET_CHECK_CODE,
} from "./types";

const initialState = {
  auth: null,
  email: "",
  password: "",
  re_password: "",
  token: localStorage.getItem("token"),
  expires_in: localStorage.getItem("expires_in"),
  user: [],
  companys: localStorage.getItem("companys")
    ? JSON.parse(localStorage.getItem("companys"))
    : [],
  uid: localStorage.getItem("uid") ? localStorage.getItem("uid") : "",
  registrationLoad: null,
  addMachineLoad: null,
  socket: null,
  socketListNots: [],
  socketAllTickets: [],
  socketTicketSel: null,
  code: "",
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_EMAIL:
      return { ...state, email: action.payload };
    case SET_AUTH_PASSWORD:
      return { ...state, password: action.payload };
    case SET_AUTH_RE_PASSWORD:
      return { ...state, re_password: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_EXPRES_IN:
      return { ...state, expires_in: action.payload };
    case SET_AUTH:
      return { ...state, auth: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_COMPANYS:
      return { ...state, companys: action.payload };
    case SET_UID:
      return { ...state, uid: action.payload };
    case SET_REG_LOAD:
      return { ...state, registrationLoad: action.payload };
    case SET_ADD_MACHINE_LOAD:
      return { ...state, addMachineLoad: action.payload };
    case SET_SOCKET:
      return { ...state, socket: action.payload };
    case SET_LIST_NOTS:
      return { ...state, socketListNots: action.payload };
    case SET_TICKET_SEL:
      return { ...state, socketTicketSel: action.payload };
    case SET_ALL_TICKETS:
      return { ...state, socketAllTickets: action.payload };
    case SET_CHECK_CODE:
      return { ...state, code: action.payload };
    default:
      return state;
  }
};
