export const SET_AUTH_EMAIL = "SET_AUTH_EMAIL";
export const SET_AUTH_PASSWORD = "SET_AUTH_PASSWORD";
export const SET_TOKEN = "SET_TOKEN";
export const SET_EXPRES_IN = "SET_EXPRES_IN";
export const SET_AUTH = "SET_AUTH";
export const SET_USER = "SET_USER";
export const SET_COMPANYS = "SET_COMPANYS";
export const SET_AUTH_RE_PASSWORD = "SET_AUTH_RE_PASSWORD";
export const SET_UID = "SET_UID";
export const SET_REG_LOAD = "SET_REG_LOAD";
export const SET_ADD_MACHINE_LOAD = "SET_ADD_MACHINE_LOAD";
export const SET_SOCKET = "SET_SOCKET";
export const SET_LIST_NOTS = "SET_LIST_NOTS";
export const SET_TICKET_SEL = "SET_TICKET_SEL";
export const SET_ALL_TICKETS = "SET_ALL_TICKETS";
export const SET_CHECK_CODE = "SET_CHECK_CODE";
