export const machineMap = state => state.MachineReducer.machineMap
export const machine = state => state.MachineReducer.machine
export const machines = state => state.MachineReducer.machines
export const telemetry = state => state.MachineReducer.telemetry
export const machineFuel = state => state.MachineReducer.machineFuel
export const machineWork = state => state.MachineReducer.machineWork
export const machineOwner = state => state.MachineReducer.machineOwner
export const machineFuelChar = state => state.MachineReducer.machineFuelChar
export const machineFuelData = state => state.MachineReducer.machineFuelData
export const machineWorkData = state => state.MachineReducer.machineWorkData
export const event = state => state.MachineReducer.event
export const machineWorkChar = state => state.MachineReducer.machineWorkChar
export const type = state => state.MachineReducer.type
export const classes = state => state.MachineReducer.class
export const model = state => state.MachineReducer.model
export const worranty = state => state.MachineReducer.worranty
export const machineWorkTable = state => state.MachineReducer.machineWorkTable