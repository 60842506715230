import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import POPD from "../../assets/Положение об обработке и защите персональных данных Р24.04.2023 г.docx"
import PS from "../../assets/Пользовательское соглашение Р24.04.2023 г.docx"

function Policy() {
    return (
        <Box sx={{
            m: 2,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Link style={{
                color: '#1976d2'
            }} to={PS} target="_blank">Пользовательское соглашение</Link>
            <Link style={{
                color: '#1976d2',
                marginTop: '10px'
            }} to={POPD} target="_blank">Положение об обработке и защите персональных данных </Link>
        </Box>
    )
}

export default Policy