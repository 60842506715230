import { SET_FORM_MESSAGE, SET_FORM_STATE } from "./types";
import api from "../../services/api";
import { toast } from "react-toastify";

export const setFormMessage = data => {
    return {
        type: SET_FORM_MESSAGE,
        payload: data
    }
}

export const sendMailState = data => {
    return {
        type: SET_FORM_STATE,
        payload: data
    }
}

export const sendMail = (data) => async (dispatch, getState) => {
    try {
        dispatch(sendMailState('load'))
        let formData = new FormData()

        formData.append('nomer', data.nomer)
        formData.append('inn', data.inn)
        formData.append('phone', data.phone)
        formData.append('email', data.email)
        formData.append('comment', data.comment)

        formData.append('psm', data.psm, data.psm.name)
        formData.append('sved', data.sved, data.sved.name)

        if (data.spis !== null)
            formData.append('spis', data.spis, data.spis.name)

        const response = await api.post(`/api/v1/shop/order/form`, formData)

        if (response.status === 201) {
            dispatch(sendMailState('success'))
            toast.success('Заявка создана')
        }

    } catch (e) {
        dispatch(sendMailState('error'))
        console.error(e)
    }
}