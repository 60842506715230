import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShopSidebar from "./ShopSidebar";
import * as ShopSelector from "../../store/Shop/selectors";
import * as MachineSelector from "../../store/Machine/selectors";
import { useEffect, useState } from "react";
import {
  getShopCategories,
  getShopCategory,
  getShopMachine,
  getShopModelGroup,
  getShopModelGroupList,
  setShopCategories,
  setShopCategory,
} from "../../store/Shop/actions";
import ButtonsList from "./ButtonsList";
import Crumbs from "./Crumbs";
import {
  getMachine,
  getMachineType,
  setMachine,
} from "../../store/Machine/actions";
import { Preloader } from "../../components";
import * as AuthSelector from "../../store/Auth/selectors";

const Cat = () => {
  const { nomer, model } = useParams();

  const { cat } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const machine = useSelector(MachineSelector.machine);
  const categories = useSelector(ShopSelector.categories);
  const category = useSelector(ShopSelector.category);
  const model_group = useSelector(ShopSelector.model_group);
  const shopModelGrouoList = useSelector(ShopSelector.shopModelGrouoList);

  const auth = useSelector(AuthSelector.auth);

  useEffect(() => {
    if (nomer) {
      dispatch(getMachine(nomer));
    }

    return () => {
      dispatch(setMachine([]));
      dispatch(setShopCategory(null));
      dispatch(setShopCategories([]));
    };
  }, [nomer]);

  useEffect(() => {
    if (machine.nomer === parseInt(nomer)) {
      dispatch(getMachineType(machine.type_id));
      dispatch(getShopModelGroupList());
      dispatch(
        getShopModelGroup(
          machine.real_model_id !== null
            ? machine.real_model_id
            : machine.model_id
        )
      );
    }
  }, [machine]);

  useEffect(() => {
    if (typeof model_group.group_id !== "undefined") {
      if (categories.length === 0) {
        dispatch(
          getShopCategories(
            shopModelGrouoList.filter(
              (item) => item.id === model_group.group_id
            )[0].machine_type_id,
            model_group.group_id
          )
        );
      }
    }

    if (model) {
      dispatch(getShopCategories(1, parseInt(model)));
    }
  }, [model_group, shopModelGrouoList, model]);

  useEffect(() => {
    if (categories) {
      const itemCat = categories.find((item) => item.id === parseInt(cat));
      dispatch(setShopCategory(itemCat));
    }
  }, [categories]);

  if (categories.length === 0) return <Preloader />;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      {!model && <Crumbs machine={machine} />}
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ShopSidebar b={true} o={true} auth={auth} />
        </Grid>
        <Grid item xs={10}>
          {category !== null && typeof category !== "undefined" ? (
            <Box
              sx={
                category.image !== "http://shop.dstapi.ru/"
                  ? {
                      width: "800px",
                      height: "800px",
                      margin: "0 auto",
                      position: "relative",
                    }
                  : {
                      button: {
                        display: "block",
                        mb: "15px",
                      },
                    }
              }
            >
              {category.image !== "http://shop.dstapi.ru/" && (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={`${category.image}`}
                  alt={category.title}
                />
              )}
              {category.children.map((item) => {
                if (item.children.length === 1) {
                  return (
                    <Box
                      key={item.id}
                      variant="contained"
                      sx={
                        category.image !== "http://shop.dstapi.ru/" && {
                          position: "absolute",
                          left: `${item.button_cords[0]}%`,
                          top: `${item.button_cords[1]}%`,
                          padding: "8px 10px",
                          backgroundColor: "#1976d2",
                          color: "rgb(255, 255, 255)",
                          userSelect: "none",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }
                      }
                      onClick={() =>
                        navigate(
                          `${model ? `/model/${model}` : ""}/shop/${
                            !model ? `${machine.nomer}/` : ""
                          }c/${category.id}/s/${item.id}/b/${
                            item.children[0].id
                          }`
                        )
                      }
                    >
                      {item.title}
                    </Box>
                  );
                } else if (item.children !== null) {
                  return (
                    <Box
                      key={item.id}
                      variant="contained"
                      sx={
                        category.image !== "http://shop.dstapi.ru/" && {
                          position: "absolute",
                          left: `${item.button_cords[0]}%`,
                          top: `${item.button_cords[1]}%`,
                          padding: "8px 10px",
                          backgroundColor: "#1976d2",
                          color: "rgb(255, 255, 255)",
                          userSelect: "none",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }
                      }
                      onClick={() =>
                        navigate(
                          `${model ? `/model/${model}` : ""}/shop/${
                            !model ? `${machine.nomer}/` : ""
                          }c/${category.id}/s/${item.id}`
                        )
                      }
                    >
                      {item.title}
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      key={item.id}
                      variant="contained"
                      sx={
                        category.image !== "http://shop.dstapi.ru/" && {
                          position: "absolute",
                          left: `${item.button_cords[0]}%`,
                          top: `${item.button_cords[1]}%`,
                          padding: "8px 10px",
                          backgroundColor: "#1976d2",
                          color: "rgb(255, 255, 255)",
                          userSelect: "none",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }
                      }
                    >
                      {item.title}
                    </Box>
                  );
                }
              })}
            </Box>
          ) : (
            <>Load</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cat;
