export const SET_MACHINE_MAP = "SET_MACHINE_MAP"
export const SET_MACHINE = "SET_MACHINE"
export const SET_MACHINES = "SET_MACHINES"
export const SET_MACHINE_TELEMETRY = "SET_MACHINE_TELEMETRY"
export const SET_MACHINE_FUEL = "SET_MACHINE_FUEL"
export const SET_MACHINE_WORK = "SET_MACHINE_WORK"
export const SET_MACHINE_OWNER = "SET_MACHINE_OWNER"
export const SET_MACHINE_FUEL_CHAR = "SET_MACHINE_FUEL_CHAR"
export const SET_MACHINE_FUEL_DATA = "SET_MACHINE_FUEL_DATA"
export const SET_MACHINE_WORK_DATA = "SET_MACHINE_WORK_DATA"
export const SET_MACHINE_EVENT = "SET_MACHINE_EVENT"
export const SET_MACHINE_WORK_CHAR = "SET_MACHINE_WORK_CHAR"
export const SET_MACHINE_TYPE = "SET_MACHINE_TYPE"
export const SET_MACHINE_CLASS = "SET_MACHINE_CLASS"
export const SET_MACHINE_MODEL = "SET_MACHINE_MODEL"
export const SET_MACHINE_WORRANTY = "SET_MACHINE_WORRANTY"
export const SET_MACHINE_WORK_TABLE="SET_MACHINE_WORK_TABLE"