import api from "../../services/api";
import jwt from "jwt-decode";

import {
  SET_AUTH,
  SET_AUTH_EMAIL,
  SET_AUTH_PASSWORD,
  SET_AUTH_RE_PASSWORD,
  SET_COMPANYS,
  SET_EXPRES_IN,
  SET_REG_LOAD,
  SET_SOCKET,
  SET_TOKEN,
  SET_UID,
  SET_USER,
  SET_LIST_NOTS,
  SET_TICKET_SEL,
  SET_ALL_TICKETS,
  SET_CHECK_CODE,
  SET_ADD_MACHINE_LOAD,
} from "./types";
import { toast } from "react-toastify";

export const setEmail = (data) => {
  return {
    type: SET_AUTH_EMAIL,
    payload: data,
  };
};

export const setPassword = (data) => {
  return {
    type: SET_AUTH_PASSWORD,
    payload: data,
  };
};

export const setRe_Password = (data) => {
  return {
    type: SET_AUTH_RE_PASSWORD,
    payload: data,
  };
};

export const setToken = (data) => {
  return {
    type: SET_TOKEN,
    payload: data,
  };
};

export const setExpires_in = (data) => {
  return {
    type: SET_EXPRES_IN,
    payload: data,
  };
};

export const setAuth = (data) => {
  return {
    type: SET_AUTH,
    payload: data,
  };
};

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const setUid = (data) => {
  return {
    type: SET_UID,
    payload: data,
  };
};

export const setCompanys = (data) => {
  return {
    type: SET_COMPANYS,
    payload: data,
  };
};

export const setSocket = (data) => {
  return {
    type: SET_SOCKET,
    payload: data,
  };
};

export const setSocketListNots = (data) => {
  return {
    type: SET_LIST_NOTS,
    payload: data,
  };
};

export const readNotSocketListNots = (notId) => (dispatch, getState) => {
  const list = getState().AuthReducer.socketListNots;
  const newList = list.map((item) => {
    if (item.id === notId && item.ticket_id === null) {
      item.status = 1;
    }
    return item;
  });
  dispatch(setSocketListNots(newList));
};

export const readMessagesSocketListNots =
  (ticket_id) => (dispatch, getState) => {
    const list = getState().AuthReducer.socketListNots;
    const newList = [];
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].ticket_id !== ticket_id) {
        newList.push(list[i]);
      }
    }
    dispatch(setSocketListNots(newList));
  };

export const getSocketTicket = (data) => (dispatch, getState) => {
  const list = getState().AuthReducer.socketAllTickets;
  const selTicket = getState().AuthReducer.socketTicketSel;
  const newList = list.map((item) => {
    if (parseInt(data.id) === parseInt(item.id)) {
      return data;
    }
    return item;
  });
  if (
    typeof selTicket !== "undefined" &&
    selTicket !== null &&
    parseInt(data.id) === parseInt(selTicket.id)
  )
    dispatch(setSocketTicketSel(data));
  dispatch(setSocketAllTickets(newList));
};

export const addNotsSocketList = (data) => (dispatch, getState) => {
  const list = getState().AuthReducer.socketListNots;
  const newList = list.map((item) => {
    return item;
  });
  newList.unshift(data);
  dispatch(setSocketListNots(newList));
};

export const addMessageSocketList = (data) => (dispatch, getState) => {
  const list = getState().AuthReducer.socketAllTickets;
  const ticketSel = getState().AuthReducer.socketTicketSel;
  const newList = list.map((item) => {
    if (
      typeof data !== "undefined" &&
      data !== null &&
      typeof data.ticket_id !== "undefined" &&
      parseInt(data.ticket_id) === parseInt(item.id)
    ) {
      item.lastMessage = data;
      if (typeof item.countNew === "undefined" || item.countNew === null)
        item.countNew = 0;
      if (
        typeof data.my === "undefined" ||
        data.my === null ||
        data.my !== true
      )
        item.countNew = item.countNew + 1;
      item.messages.push(data);
      if (parseInt(ticketSel.id) === parseInt(item.id))
        dispatch(setSocketTicketSel(item));
    }
    return item;
  });
  dispatch(setSocketAllTickets(newList));
};

export const setSocketTicketSel = (data) => {
  return {
    type: SET_TICKET_SEL,
    payload: data,
  };
};

export const setSocketAllTickets = (data) => {
  return {
    type: SET_ALL_TICKETS,
    payload: data,
  };
};

export const authUser = () => async (dispatch, getState) => {
  const email = getState().AuthReducer.email;
  const password = getState().AuthReducer.password;

  try {
    const response = await api.post("/api/auth/login", {
      email: email,
      password: password,
    });

    if (response.status === 200) {
      const { access_token, expires_in } = await response.data;

      const companys = JSON.stringify(jwt(access_token).companys);

      localStorage.setItem("token", access_token);
      localStorage.setItem("expires_in", expires_in);
      localStorage.setItem("companys", companys);

      dispatch(setCompanys(companys));
      dispatch(setToken(access_token));
      dispatch(setExpires_in(expires_in));
      dispatch(setAuth(true));
    }
  } catch (e) {
    console.error(e);
  }
};

export const createlink = () => async (dispatch, getState) => {
  const email = getState().AuthReducer.email;

  try {
    const response = await api.post("/api/auth/reset-password/link", {
      email: email,
    });

    if (response.status === 200) {
      toast.success("Сообщение отправлено на почту");
    }
  } catch (e) {
    console.error(e);
  }
};

export const createNewPassword = (token, nav) => async (dispatch, getState) => {
  const password = getState().AuthReducer.password;

  try {
    const response = await api.post(`/api/auth/reset-password?token=${token}`, {
      password: password,
    });

    if (response.status === 200) {
      toast.success("Пароль изменен");
      nav("/");
    }
  } catch (e) {
    console.error(e);
  }
};

export const logOut = () => async (dispatch, getState) => {
  try {
    const response = await api.post("/api/auth/logout", {});

    if (response.status === 200) {
      localStorage.setItem("token", "");
      localStorage.setItem("expires_in", "");

      dispatch(setToken(""));
      dispatch(setExpires_in(""));
      dispatch(setAuth(false));
      dispatch(setUser([]));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getUser = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/user", {});

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setUser(json));
      dispatch(setAuth(true));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setRegLoad = (data) => {
  return {
    type: SET_REG_LOAD,
    payload: data,
  };
};

export const addRegistrationRequest =
  (data, uid = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch(setRegLoad("load"));

      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("inn", data.inn);
      formData.append("kpp", data.kpp);
      formData.append("address", data.address);
      formData.append("email", data.email);
      formData.append("name", data.name);
      formData.append("phone", data.phone);

      if (uid !== 0) formData.append("uid", uid);

      data.psm.map((item, i) => formData.append(`psm[${i}]`, item, item.name));
      data.sved.map((item, i) =>
        formData.append(`sved[${i}]`, item, item.name)
      );

      const response = await api.post("/api/registration/request", formData);

      if (response.status === 201) {
        dispatch(setRegLoad(true));
        toast.success("Заявка на регистрацию отправлена");
      }
      if (response.status === 400 && response.message == "company exist") {
        toast.error("Компания с таким ИНН уже существует");
      }
    } catch (e) {
      dispatch(setRegLoad(false));
      console.error(e.response.data.message);
    }
  };

export const setAddMachineLoad = (data) => {
  return {
    type: SET_ADD_MACHINE_LOAD,
    payload: data,
  };
};

export const addMachineRequest =
  (user_id, company_id, sved, psm) => async (dispatch, getState) => {
    try {
      dispatch(setAddMachineLoad("load"));

      const formData = new FormData();

      formData.append("user_id", user_id);
      formData.append("company_id", company_id);

      psm.map((item, i) => formData.append(`psm[${i}]`, item, item.name));
      sved.map((item, i) => formData.append(`sved[${i}]`, item, item.name));

      const response = await api.post(
        "/api/machine/add-machine-request",
        formData
      );

      if (response.status === 201) {
        dispatch(setAddMachineLoad(true));
        toast.success("Заявка на добавление машины отправлена");
        return;
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        typeof e.response.data.message === "string"
      ) {
        switch (e.response.data.message) {
          case "User in company not exists":
            dispatch(setAddMachineLoad("Пользователь не найден в компании"));
            return;
          case "fail insert addMachineRequests":
            dispatch(setAddMachineLoad("Ошибка записи в базу"));
            return;
          case "fail insert file sved":
            dispatch(setAddMachineLoad("Ошибка сохранения файла"));
            return;
          case "fail insert file psm":
            dispatch(setAddMachineLoad("Ошибка сохранения файла"));
            return;
          default:
            dispatch(setAddMachineLoad("Ошибка отправки заявки"));
            return;
        }
      }

      if (e.response.status === 400) {
        dispatch(setAddMachineLoad("Ошибка отправки заявки"));
        return;
      }

      dispatch(setAddMachineLoad(false));
      console.error(e.response.data.message);
    }
  };

export const setCheckCode = (data) => {
  return {
    type: SET_CHECK_CODE,
    payload: data,
  };
};

export const getCheckCode = (email) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/user/code?email=${email}`);

    if (response.status === 200) {
      const json = await response.data;
      toast.info("Код отрпавлен на почту");
      dispatch(setCheckCode(json.code));
    }
  } catch (e) {
    console.error(e);
  }
};

export const editUser = (data) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/user`, data);

    if (response.status === 200) {
      toast.success("Данные в профиле обновлены");
      dispatch(getUser());
    }
  } catch (e) {
    console.error(e);
  }
};

export const editUserPassword = (data) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/auth/new-password`, data);

    if (response.status === 200) {
      toast.success("Пароль изменен");
    }
  } catch (e) {
    console.log(e.response);
  }
};

let refreshTokenRequest = null;
export const getAccessToken = () => async (dispatch, getState) => {
  try {
    const accessToken = getState().AuthReducer.token;

    if (!accessToken || isTokenExpired(accessToken)) {
      if (refreshTokenRequest === null) {
        refreshTokenRequest = api.post("/api/auth/refresh-tokens", [], {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      const res = await refreshTokenRequest;
      refreshTokenRequest = null;

      dispatch(setToken(res.data.access_token));
      localStorage.setItem("token", res.data.access_token);

      return res.data.access_token;
    }

    return accessToken;
  } catch (e) {
    console.error(e);
    dispatch(setAuth(false));
    return null;
  }
};

export const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const tokenInfo = token.split(".")[1];
    const tokenInfoDecoded = window.atob(tokenInfo);
    const { exp, iat } = JSON.parse(tokenInfoDecoded);

    const tokenLeftTime = exp - getUnixTime();

    return tokenLeftTime < 300;
  } catch (e) {
    console.error(e);
    return true;
  }
};

export const getUnixTime = () => Math.round(+new Date() / 1000);
