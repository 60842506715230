import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useParams, Link } from 'react-router-dom'
import Img from "../../assets/file.png"
import { useDispatch, useSelector } from 'react-redux'
import { addRequest, getCompany, getCompanyUsers, getMachineService, getWorkType } from '../../store/Request/actions'
import * as RequestSelector from "../../store/Request/selectors"
import * as MachineSelector from "../../store/Machine/selectors"
import { getMachine, getMachineTelemetry } from '../../store/Machine/actions'
import { toast } from 'react-toastify'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function RequestCreate() {
    const { nomer } = useParams()

    const dispatch = useDispatch()

    const company_users = useSelector(RequestSelector.company_users)
    const company = useSelector(RequestSelector.company)
    const work_type = useSelector(RequestSelector.work_type)
    const telemetry = useSelector(MachineSelector.telemetry)
    const service = useSelector(RequestSelector.service)

    const [files, setFiles] = useState([])
    const [contact, setContact] = useState(0)
    const [address, setAddress] = useState('')
    const [type, setType] = useState(0)
    const [service_id, setServiceId] = useState(12)
    const [workTime, setWorkTime] = useState('')
    const [comment, setComment] = useState('')
    const [person, setPerson] = useState('')
    const [personPhone, setPersonPhone] = useState('')

    const [typeError, setTypeError] = useState(false)
    const [workTimeError, setWorkTimeError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [contactError, setContactError] = useState(false)
    const [personError, setPersonError] = useState(false)
    const [personPhoneError, setPersonPhoneError] = useState(false)

    useEffect(() => {
        dispatch(getCompanyUsers())
        dispatch(getCompany())
        dispatch(getWorkType())
        dispatch(getMachineTelemetry(nomer))
        dispatch(getMachineService(nomer))
    }, [])

    useEffect(() => {
        if (typeof company.address !== 'undefined' && typeof telemetry.motoHours !== 'undefined') {
            setAddress(company.address)
            setWorkTime(telemetry.motoHours)
        }
    }, [company, telemetry])

    const handleAddImg = (imgs) => {
        setFiles([
            ...files,
            ...imgs
        ])
    }

    const handleDeleteImg = (index) => {
        const file = files.filter((item, i) => i !== index)

        setFiles(file)
    }

    const handleSend = () => {
        setTypeError(false)
        setWorkTimeError(false)
        setAddressError(false)
        setContactError(false)
        setPersonError(false)
        setPersonPhoneError(false)

        if (type === 0) {
            setTypeError(true)
            return false
        }

        if (workTime === '') {
            setWorkTimeError(true)
            return false
        }

        if (address === '') {
            setAddressError(true)
            return false
        }

        if (contact === 0) {
            if (person === '') {
                setContactError(true)
                setPersonError(true)
                return false
            }

            if (personPhone === '') {
                setContactError(true)
                setPersonPhoneError(true)
                return false
            }
        }

        dispatch(addRequest({
            nomer: nomer,
            service_id: service_id,
            work_time: workTime,
            description: comment,
            work_type_id: type,
            address: address,
            person_id: contact,
            inn: company.inn,
            person: person,
            person_phone: personPhone,
            files: files
        }))
    }

    return (
        <Box sx={{ padding: '24px' }}>
            <Box    
                sx={{
                    alignItems: 'center',
                    margin: '0 0 15px 15px',
                }}
            >
                <Link to={`/machine/${nomer}`}
                style={{ textDecoration: 'none', color: "#3498db", display: 'inline-flex', alignItems: 'center', cursor: "pointer"}}>
                    <ArrowBackIcon titleAccess="К машине" fontSize="medium" color="custom_blue2"/>
                    <Box sx={{fontSize: "14px"}}>{nomer}</Box>
                </Link>
            </Box>
            <Box sx={{ flex: 1, display: 'flex'}}>
                <Box sx={{ flex: 1, display: 'flex' }}>
                    <Box sx={{
                        flex: 1
                    }}>
                        <Box sx={{
                            flex: 1,
                            display: "flex",
                            backgroundColor: "#f5f6fa",
                            borderRadius: "4px",
                            padding: "24px"
                        }}>
                            <Box sx={{ flex: 1 }}>
                                <TextField
                                    margin="dense"
                                    select
                                    label="Тип работ"
                                    fullWidth
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    error={typeError}
                                    value={type}
                                    onChange={e => setType(parseInt(e.target.value))}
                                >
                                    <option value={0}>Выбрать</option>
                                    {
                                        work_type.length !== 0 ? work_type.map((item) => <option key={item.id} value={item.id}>{item.title}</option>) : null
                                    }
                                </TextField>
                                <TextField
                                    margin="dense"
                                    select
                                    label="Сервисная компания"
                                    fullWidth
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    value={service_id}
                                    onChange={e => setServiceId(parseInt(e.target.value))}
                                >
                                    {
                                        service.length !== 0 ? service.map((item) => <option key={item.id} value={item.id}>{item.title}</option>) : null
                                    }
                                </TextField>
                                <TextField
                                    margin="dense"
                                    label="Наработка"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    error={workTimeError}
                                    value={workTime}
                                    onChange={e => setWorkTime(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    label="Адрес"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    error={addressError}
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                                <TextField
                                    multiline
                                    rows={4}
                                    margin="dense"
                                    label="Описание"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ flex: 1, ml: "24px" }}>
                                <TextField
                                    margin="dense"
                                    select
                                    label="Контакт"
                                    fullWidth
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    error={contactError}
                                    value={contact}
                                    onChange={e => setContact(parseInt(e.target.value))}
                                >
                                    <option value={0}>Выбрать</option>
                                    {
                                        company_users.length !== 0 ? company_users.map((item) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                                    }
                                </TextField>
                                {
                                    contact === 0 ? (
                                        <>
                                            <TextField
                                                margin="dense"
                                                label="ФИО"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                error={personError}
                                                value={person}
                                                onChange={e => setPerson(e.target.value)}
                                            />
                                            <TextField
                                                margin="dense"
                                                label="Телефон"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                error={personPhoneError}
                                                value={personPhone}
                                                onChange={e => setPersonPhone(e.target.value)}
                                            />
                                        </>
                                    ) : ''
                                }
                                <Box
                                    sx={{
                                        marginTop: "8px"
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        margin: '0 -5px',
                                        'div': {
                                            width: '77px',
                                            height: '77px',
                                            mb: '10px',
                                            mr: '5px',
                                            ml: '5px',
                                            position: "relative",
                                            'img': {
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: "4px",
                                                zIndex: "1",
                                                backgroundColor: '#c4c4c4',
                                                objectFit: "cover"
                                            },
                                            '.delete-img': {
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                border: 'none',
                                                borderRadius: "4px",
                                                zIndex: '-1',
                                                opacity: 0,
                                                cursor: 'pointer',
                                                transition: '0.3s'
                                            },
                                            ':hover .delete-img': {
                                                zIndex: 2,
                                                opacity: 1,
                                                transition: '0.5s'
                                            }
                                        }
                                    }}>
                                        {
                                            files.map((item, i) => (
                                                <div key={i}>
                                                    <img src={(item.type === 'image/jpeg' || item.type === 'image/png') ? URL.createObjectURL(item) : Img} alt={item.name} />
                                                    <button onClick={() => handleDeleteImg(i)} className='delete-img'>
                                                        <CloseIcon sx={{ color: "#3498db" }} />
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </Box>
                                    <input
                                        id="files_add"
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }}
                                        onChange={e => handleAddImg(e.target.files)}
                                    />
                                    <label htmlFor="files_add">
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="custom_blue2"
                                        >
                                            Добавить файлы
                                        </Button>
                                    </label>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="custom_blue2"
                                    sx={{
                                        mt: '8px',
                                        ml: 'auto',
                                        display: 'block'
                                    }}
                                    onClick={handleSend}
                                >
                                    Создать
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: 1,
                        ml: '24px'
                    }}>
                        <h3>Как заполнить завку</h3>
                        <p>В настоящее время выпускаются:</p>
                        <ul>
                            <li>бульдозеры 5 тяговых классов массой от 12 до 44 тонн</li>
                            <li>трубоукладчики грузоподъемностью от 12 до 50 тонн</li>
                            <li>гусеничные и колесные погрузчики грузоподъемностью от 2 до 5 тонн техника на базе промышленных тракторов кабелеукладчики, мульчеры</li>
                        </ul>
                        <p>В настоящее время выпускаются:</p>
                        <ul>
                            <li>бульдозеры 5 тяговых классов массой от 12 до 44 тонн</li>
                            <li>трубоукладчики грузоподъемностью от 12 до 50 тонн</li>
                            <li>гусеничные и колесные погрузчики грузоподъемностью от 2 до 5 тонн техника на базе промышленных тракторов кабелеукладчики, мульчеры</li>
                        </ul>
                        <h4>Техническое обслуживание бульдозера {nomer}</h4>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default RequestCreate