import { Breadcrumbs, Link, Skeleton } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as ShopSelector from "../../store/Shop/selectors"

const Crumbs = ({ machine = null }) => {
    const navigate = useNavigate()

    const category = useSelector(ShopSelector.category)
    const subcategory = useSelector(ShopSelector.subcategory)
    const subSubcategory = useSelector(ShopSelector.subSubcategory)
    const builds = useSelector(ShopSelector.builds)
    const schemeData = useSelector(ShopSelector.scheme)

    if (!machine) return null

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link underline="hover" color="inherit" onClick={() => navigate(`/machine/${machine.nomer}`)} sx={{ cursor: 'pointer' }}>
                    {machine.nomer || <Skeleton width={36} height={19} />}
                </Link>
                <Link underline="hover" color="inherit" onClick={() => navigate(`/shop/${machine.nomer}`)} sx={{ cursor: 'pointer' }}>
                    Каталог
                </Link>
                {(category !== null && typeof category !== "undefined") &&
                    <Link underline="hover" color="inherit" onClick={() => navigate(`/shop/${machine.nomer}/c/${category.id}`)} sx={{ cursor: 'pointer' }}>
                        {category.title}
                    </Link>}
                {(subcategory !== null && typeof subcategory !== "undefined") &&
                    <Link underline="hover" color="inherit" onClick={() => navigate(`/shop/${machine.nomer}/c/${category.id}/s/${subcategory.id}`)} sx={{ cursor: 'pointer' }}>
                        {subcategory.title}
                    </Link>}
                {subSubcategory !== null &&
                    <Link underline="hover" color="inherit" onClick={() => navigate(`/shop/${machine.nomer}/c/${category.id}/s/${subcategory.id}/b/${subSubcategory.id}`)} sx={{ cursor: 'pointer' }}>
                        {subSubcategory.title}
                    </Link>}
                {schemeData.length !== 0 &&
                    <Link underline="none" color="inherit" sx={{ cursor: 'default' }}>
                        {schemeData.title}
                    </Link>}
            </Breadcrumbs>
        </>
    )
}

export default Crumbs