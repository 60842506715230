import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShopSidebar from "./ShopSidebar";
import * as ShopSelector from "../../store/Shop/selectors";
import * as MachineSelector from "../../store/Machine/selectors";
import { useEffect } from "react";
import {
  getShopBuilds,
  getShopCategories,
  getShopMachine,
  setShopBuilds,
  setShopSubCategory,
  setShopCategory,
  setShopCategories,
  setShopSubSubCategory,
  getShopModelGroup,
  getShopModelGroupList,
} from "../../store/Shop/actions";
import Crumbs from "./Crumbs";
import { getMachine, setMachine } from "../../store/Machine/actions";
import { Preloader } from "../../components";
import * as AuthSelector from "../../store/Auth/selectors";

const Builds = () => {
  const { nomer, model, cat, sub, part } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const machine = useSelector(MachineSelector.machine);
  const categories = useSelector(ShopSelector.categories);
  const category = useSelector(ShopSelector.category);
  const subcategory = useSelector(ShopSelector.subcategory);
  const builds = useSelector(ShopSelector.builds);
  const model_group = useSelector(ShopSelector.model_group);
  const auth = useSelector(AuthSelector.auth);
  const shopModelGrouoList = useSelector(ShopSelector.shopModelGrouoList);

  useEffect(() => {
    if (nomer) {
      dispatch(getMachine(nomer));
    }

    return () => {
      dispatch(setMachine([]));
      dispatch(setShopCategory(null));
      dispatch(setShopSubCategory(null));
      dispatch(setShopCategories([]));
      dispatch(setShopSubSubCategory(null));
      dispatch(setShopBuilds([]));
    };
  }, [nomer]);

  useEffect(() => {
    if (machine.nomer === parseInt(nomer)) {
      dispatch(getShopModelGroupList());
      dispatch(
        getShopModelGroup(
          machine.real_model_id !== null
            ? machine.real_model_id
            : machine.model_id
        )
      );
    }
  }, [machine]);

  useEffect(() => {
    if (typeof model_group.group_id !== "undefined") {
      dispatch(
        getShopBuilds(
          shopModelGrouoList.filter(
            (item) => item.id === model_group.group_id
          )[0].id,
          part
        )
      );
      if (categories.length === 0) {
        dispatch(
          getShopCategories(
            shopModelGrouoList.filter(
              (item) => item.id === model_group.group_id
            )[0].machine_type_id,
            model_group.group_id
          )
        );
      }
    }

    if (model) {
      dispatch(
        getShopCategories(
          shopModelGrouoList.filter(
            (item) => item.id === model_group.group_id
          )[0].machine_type_id,
          parseInt(model)
        )
      );
      dispatch(getShopBuilds(parseInt(model), part));
    }
  }, [model_group, shopModelGrouoList, model]);

  useEffect(() => {
    if (categories.length !== 0) {
      const itemCat = categories.find((item) => item.id === parseInt(cat));
      dispatch(setShopCategory(itemCat));
    }
  }, [categories]);

  useEffect(() => {
    if (category) {
      const itemSub = category.children.find(
        (item) => item.id === parseInt(sub)
      );
      dispatch(setShopSubCategory(itemSub));
    }
  }, [category]);

  useEffect(() => {
    if (subcategory) {
      const itemSub = subcategory.children.find(
        (item) => item.id === parseInt(part)
      );
      dispatch(setShopSubSubCategory(itemSub));
    }
  }, [subcategory]);

  if (categories.length === 0) return <Preloader />;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      {!model && <Crumbs machine={machine} />}
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ShopSidebar b={true} o={true} auth={auth} />
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {builds.map((item) => (
              <Box
                key={item.id}
                onClick={() => navigate(`v/${item.id}`)}
                sx={{
                  width: 250,
                  borderWidth: 1,
                  borderColor: "#dedede",
                  borderStyle: "solid",
                  mr: 2,
                  p: 2,
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={`http://shop.dstapi.ru/${item.image}`}
                  alt={item.title}
                />
                <Box>
                  <Typography sx={{ width: "80%", fontSize: 14 }}>
                    {item.title}
                  </Typography>
                  <Typography sx={{ width: "100%", fontSize: 12 }}>
                    {item.hidden === 1 ? "Скрыто" : ""}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Builds;
