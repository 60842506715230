import { Divider, List, ListItem, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { readNotSocketListNots } from "../../store/Auth/actions";
import * as AuthSelector from "../../store/Auth/selectors";

import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";

const ListNots = ({}) => {
  const socketListNots = useSelector(AuthSelector.socketListNots);
  const socket = useSelector(AuthSelector.socket);

  const [ListNots, SetListNots] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hoverReadNotification = (id) => {
    if (typeof socket !== "undefined" && socket !== null && socket.connected) {
      dispatch(readNotSocketListNots(id));
      socket.emit("readNotifications", id);
    }
  };

  useEffect(() => {
    if (
      typeof socketListNots !== "undefined" &&
      socketListNots !== null &&
      socketListNots.length > 0
    )
      SetListNots(socketListNots);
    else SetListNots([]);

    return () => {
      SetListNots([]);
    };
  }, [socketListNots]);

  return (
    <List
      sx={{
        width: 280,
      }}
    >
      {typeof ListNots !== "undefined" &&
      ListNots !== null &&
      ListNots.length > 0 ? (
        ListNots.map((item, index) => {
          return (
            <>
              <ListItem
                key={index + 1}
                sx={{
                  textDecoration: "none",
                  padding: 0,
                  backgroundColor: () => {
                    return item.status == 0 ? "#F5F6FA" : "#fff";
                  },
                  borderLeft: () => {
                    return item.status == 0 ? "3px solid #4d93d9" : "none";
                  },
                }}
                onMouseEnter={(e) => {
                  if (item.ticket_id === null && item.status === 0)
                    hoverReadNotification(item.id, item.status);
                }}
              >
                <Link
                  to={
                    item.ticket_id !== null
                      ? `/tickets?id=${item.ticket_id}`
                      : `#`
                  }
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "20px 30px",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {item.ticket_title !== null ? (
                    <Box
                      sx={{
                        fontWeight: "700",
                        mb: "10px",
                      }}
                    >
                      {item.ticket_title}
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      mb: "10px",
                    }}
                  >
                    {item.message.length > 90
                      ? `${item.message.substring(0, 70)}...`
                      : item.message}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "0.8em",
                      color: "#999999",
                    }}
                  >
                    {moment(item.time).format("HH:mm DD.MM.YYYY")}
                  </Box>
                </Link>
              </ListItem>
              <Divider />
            </>
          );
        })
      ) : socket !== null && socket.connected ? (
        <Box
          sx={{
            fontWeight: "700",
            padding: "10px",
            textAlign: "center",
          }}
        >
          Нет уведомлений
        </Box>
      ) : (
        <Box
          sx={{
            fontWeight: "700",
            padding: "10px",
            textAlign: "center",
          }}
        >
          Нет подключения
        </Box>
      )}
    </List>
  );
};
export default ListNots;
