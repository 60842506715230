import { Box, Button, FormControl, TextField } from '@mui/material'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as AuthSelectors from "../../store/Auth/selectors"
import { createlink, createNewPassword, setEmail, setPassword, setRe_Password } from '../../store/Auth/actions'
import { toast } from 'react-toastify'

function ResetPassword() {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search)

    const email = useSelector(AuthSelectors.email)
    const password = useSelector(AuthSelectors.password)
    const re_password = useSelector(AuthSelectors.re_password)

    const sendNewPassword = () => {
        if (password !== re_password) {
            toast.error('Пароли не совпадают')
            return false
        }

        dispatch(createNewPassword(query.get('token'), navigate))
    }

    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
        }}>
            {
                !query.get('token') ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Link style={{
                            color: '#3498db',
                            textDecoration: 'none',
                            marginBottom: '15px'
                        }} to="/">Назад</Link>
                        <FormControl>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Email"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={email}
                                onChange={(e) => dispatch(setEmail(e.target.value))}
                            />
                            <Button onClick={() => dispatch(createlink())} variant="contained">Сменить пароль</Button>
                        </FormControl>
                    </Box>
                ) : (
                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="Пароль"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={password}
                            onChange={(e) => dispatch(setPassword(e.target.value))}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="Пароль повторно"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={re_password}
                            onChange={(e) => dispatch(setRe_Password(e.target.value))}
                        />
                        <Button onClick={sendNewPassword} variant="contained">Сохранить</Button>
                    </FormControl>
                )
            }
        </Box>
    )
}

export default ResetPassword