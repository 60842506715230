import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as MachineSelector from "../../store/Machine/selectors"
import { getMachine, setMachineWorkChar, getMachineWorkChar, getMachineEvent, setMachineEvent, getMachineFuel, getMachineFuelChar, getMachineFuelData, getMachineWork, getMachineWorkData, setMachine, setMachineFuel, setMachineFuelData, setMachineWork, setMachineWorkData } from '../../store/Machine/actions'
import { Box, TextField, Button, Skeleton } from '@mui/material'
import { BackButton, EventMap, Table, WorkGraf } from '../../components'
import styles from "./work.module.css"
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    ArcElement
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2'

function Work() {
    const { nomer } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const machine = useSelector(MachineSelector.machine)
    const machineWorkData = useSelector(MachineSelector.machineWorkData)
    const machineWork = useSelector(MachineSelector.machineWork)
    const machineFuelData = useSelector(MachineSelector.machineFuelData)
    const event = useSelector(MachineSelector.event)
    const machineWorkChar = useSelector(MachineSelector.machineWorkChar)
    const machineFuel = useSelector(MachineSelector.machineFuel)

    const [fromDate, setFromDate] = useState(moment().subtract(1, 'days').format('yyyy-MM-DD'))
    const [maxFromDate, setMaxFromDate] = useState(0)
    const [toDate, setToDate] = useState(moment().format('yyyy-MM-DD'))
    const [minToDate, setMinToDate] = useState(0)

    const [day, setDays] = useState(moment(toDate).diff(moment(fromDate), 'days'))
    const [sec, setSec] = useState(day * 24 * 60 * 60)



    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const qFromDate = query.get('from') != null && query.get('from') !== 0 ? query.get('from') : moment().subtract(1, 'days').format('yyyy-MM-DD')
        const qToDate = query.get('to') != null && query.get('to') !== 0 ? query.get('to') : moment().format('yyyy-MM-DD')

        if ((qFromDate !== 0 || qToDate !== 0) && moment(qToDate).diff(moment(qFromDate), 'days') >= 1) {
            setFromDate(qFromDate)
            setToDate(qToDate)
            setMinToDate(moment(qFromDate).add(1, 'days').format('yyyy-MM-DD'))
            setMaxFromDate(moment(qToDate).subtract(1, 'days').format('yyyy-MM-DD'))
            setDays(moment(qToDate).diff(moment(qFromDate), 'days'))
            setSec(moment(qToDate).diff(moment(qFromDate), 'days') * 24 * 60 * 60)

        }

        dispatch(getMachine(nomer))
        dispatch(getMachineFuel(nomer))
        dispatch(getMachineWork(nomer))
        dispatch(getMachineFuelData(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineWorkData(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineWorkChar(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineEvent(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))

        return () => {
            dispatch(setMachine([]))
            dispatch(setMachineFuel([]))
            dispatch(setMachineWork([]))
            dispatch(setMachineFuelData([]))
            dispatch(setMachineWorkData([]))
            dispatch(setMachineWorkChar([]))
            dispatch(setMachineEvent([]))
        }
    }, [nomer])


    useEffect(() => {
        if (machine.fuel_volume === null)
            machine.fuel_volume = 400
    }, [machine])

    const selectByDate = () => {
        dispatch(setMachineFuelData([]))
        dispatch(setMachineWorkData([]))
        dispatch(setMachineWorkChar([]))
        dispatch(setMachineEvent([]))
        setDays(moment(toDate).diff(moment(fromDate), 'days'))
        setSec(day * 24 * 60 * 60)
        dispatch(getMachineFuelData(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineWorkData(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineWorkChar(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        dispatch(getMachineEvent(nomer, moment(fromDate).format('yyyy-MM-DD'), moment(toDate).add(1, 'days').format('yyyy-MM-DD')))
        navigate(`${location.pathname}${fromDate !== 0 || toDate !== 0 ? '?' : ''}${fromDate !== 0 ? 'from=' + fromDate : ''}${toDate !== 0 ? '&to=' + toDate : ''}`)
    }

    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        ArcElement
    );


    const getDataWork = (data) => {
        if (typeof data.idling === 'undefined' || typeof data.activity === 'undefined' || data.activity == 0) return [0, 0, 0]
        const idling = data.idling !== 0 ? Math.round(data.idling * 100 / 86400) : 0
        const activity = data.activity !== 0 ? Math.round(data.activity * 100 / 86400) : 0
        const noInfo = 100 - activity - idling
        return [activity, idling, noInfo]
    }

    const optionsChart = {
        cutout: "63%",
        maintainAspectRatio: false,
        layout: {
            autoPadding: false,
            padding: 0
        },
        plugins: {
            tooltip: {
                usePointStyle: true,
                callbacks: {
                    label(label) {
                        return `${label.formattedValue}%`
                    },
                }
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            }
        }
    }

    const WorkStatus = ({ data }) => {

        const dvs_no_active = (type) => {
            return type === "h" ? (sec - (data.idling + data.activity)) / 3600 : Math.round((sec - (data.idling + data.activity)) / (sec / 100))
        }

        const idling = (type) => {
            return type === "h" ? data.idling / 3600 : Math.round(data.idling / (sec / 100))
        }

        const active = (type) => {
            return type === "h" ? data.activity / 3600 : Math.round(data.activity / (sec / 100))
        }

        const all_work = (type) => {
            return type === "h" ? (data.idling + data.activity) / 3600 : Math.round((data.idling + data.activity) / (sec / 100))
        }
        return (
            <div className={styles.table}>
                <div className={`${styles.divTable} ${styles.blueTable}`}>
                    <div className={styles.divTableHeading}>
                        <div className={styles.divTableRow}>
                            <div className={styles.divTableHead}>Состояние</div>
                            <div className={styles.divTableHead}>Время, часов</div>
                            <div className={styles.divTableHead}>% от общего</div>
                        </div>
                    </div>
                    <div className={styles.divTableBody}>
                        <div className={styles.divTableRow}>
                            <div className={styles.divTableCell}>ДВС заглушен</div>
                            <div className={styles.divTableCell}>{dvs_no_active("h").toFixed(2)}</div>
                            <div className={styles.divTableCell}>{dvs_no_active()}%</div>
                        </div>
                        <div className={styles.divTableRow}>
                            <div className={styles.divTableCell}>ДВС на холостом ходу</div>
                            <div className={styles.divTableCell}>{idling("h").toFixed(2)}</div>
                            <div className={styles.divTableCell}>{idling()}%</div>
                        </div>
                        <div className={styles.divTableRow}>
                            <div className={styles.divTableCell}>Работа под нагрузкой</div>
                            <div className={styles.divTableCell}>{active("h").toFixed(2)}</div>
                            <div className={styles.divTableCell}>{active()}%</div>
                        </div>
                        <div className={styles.divTableRow}>
                            <div className={styles.divTableCell}>Всего в работе</div>
                            <div className={styles.divTableCell}>{all_work("h").toFixed(2)}</div>
                            <div className={styles.divTableCell}>{all_work()}%</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const index = (item, index) => {
        return index + 1
    }

    const color = (item, index) => {
        return typeof item.errors === "object" ? <p style={{ color: "#F15A24" }}>{item.event}</p> : (item.event === "" ? <p style={{ color: "#29ABE2" }}>Основной периодический отчет</p> : item.event)
    }

    const HiddenBlock = ({type, children}) => {
        const list = [11]
        if(list.includes(type)) {
            return null
        } else {
            return children
        }
    }

    const tabCols = [
        { title: "Маркер", val: index },
        { title: "Дата", code: "time", type: "date" },
        { title: "Время", code: "time", type: "time" },
        { title: "Событие", code: "event", val: color }
    ]

    return (
        <Box sx={{ flex: 1, padding: '24px' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    margin: '0 0 15px 0',
                }}
            >
                <BackButton nomer={nomer} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px'
                }}
            >

                <TextField id="FromDate"
                    sx={{ mr: '20px' }}
                    value={fromDate !== 0 ? fromDate : moment().subtract(1, 'days').format('yyyy-MM-DD')}
                    inputProps={{
                        min: '25.07.1996',
                        max: maxFromDate !== 0 ? maxFromDate : moment().subtract(1, 'days').format('yyyy-MM-DD'),
                    }}
                    onChange={
                        ((e) => {
                            if (moment(toDate).diff(e.target.value, 'days') <= 0) {
                                setFromDate(moment(toDate).subtract(1, 'days').format('yyyy-MM-DD'))
                                return
                            }
                            setFromDate(e.target.value)
                            setMinToDate(moment(e.target.value).add(1, 'days').format('yyyy-MM-DD'))
                        })
                    }
                    variant="outlined" type="date" />
                <TextField id="ToDate"
                    sx={{ mr: '40px' }}
                    value={toDate !== 0 ? toDate : moment().format('yyyy-MM-DD')}
                    inputProps={{
                        min: minToDate !== 0 ? minToDate : '24.07.1996',
                        max: moment().format('yyyy-MM-DD')
                    }}
                    onChange={
                        ((e) => {
                            if (moment(e.target.value).diff(fromDate, 'days') <= 0) {
                                setToDate(moment(fromDate).add(1, 'days').format('yyyy-MM-DD'))
                                return
                            }
                            setMaxFromDate(moment(e.target.value).subtract(1, 'days').format('yyyy-MM-DD'))
                            setToDate(e.target.value)
                        })
                    }
                    variant="outlined" type="date" />
                <Button sx={{ width: '150px', height: '40px', lineHeight: '40px' }} onClick={selectByDate} variant="contained" color="custom_blue2">Найти</Button>
            </Box>
            <HiddenBlock type={machine.type_id}>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <div className={styles.state_work}>
                    <div className={styles.state_work_body}>
                        <div className={styles.graf}>
                            <h3>Время работы</h3>
                            <div className={styles.graf_wrapper}>
                                <div className={styles.graf_desc}>
                                    <div className={styles.min}>
                                        <span></span>
                                        <p>Нет данных</p>
                                    </div>
                                    <div className={styles.mid}>
                                        <span></span>
                                        <p>Холостой ход</p>
                                    </div>
                                    <div className={styles.max}>
                                        <span></span>
                                        <p>С нагрузкой</p>
                                    </div>
                                </div>
                                {
                                    typeof machineWork.idling === 'undefined' || typeof machineWork.activity === 'undefined' || machineWork.activity == 0 ?
                                        (
                                            ""
                                        )
                                        : (
                                            <Box sx={{ height: 150, width: 150, position: "relative", mr: "35px" }}>
                                                <Doughnut
                                                    data={{
                                                        labels: [
                                                            'С нагрузкой',
                                                            'Холостой ход',
                                                            'Нет данных',
                                                        ],
                                                        datasets: [{
                                                            data: getDataWork(machineWork),
                                                            backgroundColor: [
                                                                '#6DCC1D',
                                                                '#48A6E1',
                                                                '#E6E6E6',
                                                            ],
                                                            hoverOffset: 4
                                                        }],
                                                    }}
                                                    options={optionsChart}
                                                />
                                                <Box sx={{
                                                    position: "absolute",
                                                    top: 60,
                                                    left: "0",
                                                    cursor: "default",
                                                    fontWeight: 700,
                                                    fontSize: 28,
                                                    textAlign: "center",
                                                    color: "#fff",
                                                    width: "100%",
                                                    zIndex: 0
                                                }}>{`${machineWork.activity !== 0 ? Math.ceil(machineWork.activity * 100 / 86400) : 0}%`}</Box>
                                            </Box>
                                        )
                                }
                            </div>
                        </div>
                        <WorkStatus data={machineWorkData} />
                    </div>
                    <div className={styles.state_work_footer}>
                        <p>Число пусков двигателя — {machineWorkData.engineStartCount}</p>
                        <p>Дней без пуска двигателя — {machineWorkData.daysInactive}</p>
                        <p>Наработка в начале периода — {machineWorkData.startWorkTime} м/ч</p>
                        <p>В конце — {machineWorkData.endWorkTime} м/ч</p>
                    </div>
                </div>
                <div className={styles.state_fuel}>
                    <h3>Расход топлива за период</h3>
                    <div className={`${styles.divTable} ${styles.whiteTable}`}>
                        <div className={styles.divTableBody}>
                            <div className={styles.divTableRow}>
                                <div className={styles.divTableCell}>Топливо на холостом ходу</div>
                                <div className={styles.divTableCell}>{(machineFuelData.idling * (machineFuel.volume / 100)).toFixed(2)} л.</div>
                            </div>
                            <div className={styles.divTableRow}>
                                <div className={styles.divTableCell}>Топливо за время работы</div>
                                <div className={styles.divTableCell}>{(machineFuelData.work * (machineFuel.volume / 100)).toFixed(2)} л.</div>
                            </div>
                            <div className={styles.divTableRow}>
                                <div className={styles.divTableCell}>Всего</div>
                                <div className={styles.divTableCell}>{((machineFuelData.idling * (machineFuel.volume / 100)) + (machineFuelData.work * (machineFuel.volume / 100))).toFixed(2)} л.</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.divTable} ${styles.smTable}`}>
                        <div className={styles.divTableBody}>
                            <div className={styles.divTableRow}>
                                <div className={styles.divTableCell}>Расход топлива средний</div>
                                <div className={styles.divTableCell}>{(((machineFuelData.idling * (machineFuel.volume / 100)) + (machineFuelData.work * (machineFuel.volume / 100))) / ((machineWorkData.idling + machineWorkData.activity) / 3600)).toFixed(2)} л/ч</div>
                            </div>
                        </div>
                    </div>
                    <span>Заправок за период — {machineFuelData.refill}</span>
                </div>
            </Box>
            </HiddenBlock>
            <div className={styles.event_wrapper}>
                <div className={styles.table_wrapper}>
                    <Table cols={tabCols} data={event} rows={30} />
                </div>
                <div className={styles.map_wrapper}>
                    {event != null && event.length !== 0 ? <EventMap data={event} /> : <p className={styles.notData}>Нет данных</p>}
                </div>
            </div>
            <HiddenBlock type={machine.type_id}>
            <div className={styles.work_wrapper}>
                {
                    machineWorkChar === null || machineWorkChar.length == 0 ?
                        (
                            <Skeleton sx={{ borderRadius: '4px', marginLeft: '24px' }} variant="rectangular" width={1807} height={200} />
                        )
                        : <WorkGraf data={machineWorkChar} width={1807} />

                }
            </div>
            </HiddenBlock>
        </Box>
    )
}

export default Work