import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";

const Message = ({ message, time, my, nots, status }) => {
  return typeof nots === "undefined" || nots === null || !nots ? (
    <div className={`message-wrap ${my === true ? "my" : "some"}`}>
      <div className="message-item">
        <div className="message">{message}</div>
        <div className="bottom">
          <div className="time">
            {typeof time !== "undefined" && time !== null ? time : ""}
          </div>
          <div className="status">
            {parseInt(status) === 1 ? (
              <DoneAllIcon fontSize="small" sx={{ color: "#205885" }} />
            ) : (
              <CheckIcon fontSize="small" sx={{ color: "#205885" }} />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="message-wrap">
      <div className="message-nots">
        <div>{message}</div>
      </div>
    </div>
  );
};

export default Message;
