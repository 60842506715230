import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { MachineReducer } from "./Machine/reducers"
import { RequestReducer } from "./Request/reducers"
import { ShopReducer } from "./Shop/reducers"
import { FormReducer } from "./Form/reducers"
import { ClerkReducer } from "./Clerk/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    MachineReducer,
    RequestReducer,
    ShopReducer,
    FormReducer,
    ClerkReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))