import React, { useEffect, useState } from 'react'
import { YMaps, Map, Clusterer, Placemark, ListBox, ListBoxItem, GeoObject, Circle } from '@pbe/react-yandex-maps'
import { useDispatch, useSelector } from 'react-redux'
import * as MachineSelector from "../../store/Machine/selectors"
import { getMachineMap, getMachineType, serchMachine, setMachineType } from '../../store/Machine/actions'
import moment from 'moment'
import { Box } from '@mui/system'
import { Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { usePromiseTracker } from 'react-promise-tracker'
import Preloader from '../../components/Preloader'

function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { promiseInProgress } = usePromiseTracker()

    const machineMap = useSelector(MachineSelector.machineMap)
    const type = useSelector(MachineSelector.type)

    const [type_id, setType_id] = useState(0)
    const [machine_nomer, setMachineNomer] = useState('')
    const [active, setActive] = useState(true)
    const [medium, setMedium] = useState(true)
    const [diactive, setDiactive] = useState(true)

    useEffect(() => {
        dispatch(getMachineMap())
        dispatch(getMachineType())

        return () => {
            dispatch(setMachineType([]))
        }
    }, [])

    const mapState = {
        center: [55.751574, 80.573856],
        zoom: 4,
        behaviors: ["default", "scrollZoom"],
        controls: []
    }

    const getColor = state => {
        switch (state) {
            case 1:
                return "#BCD331";
            case 2:
                return "#FFC522";
            case 3:
                return "#EA5D0F";
            default:
                return false
        }
    }

    const getMachineState = state => {
        switch (state) {
            case 1:
                return "В работе";
            case 2:
                return "Нет данных от 3 до 15 дней";
            case 3:
                return "Нет данных больше 15 дней";
            default:
                return false
        }
    }

    const getPointData = (machine) => {
        return {
            balloonContentHeader: machine.nomer,
            balloonContentBody: `
            <div>${getMachineState(machine.state)}</div>
            <div>${machine.model_title}${machine.complectation !== null && machine.complectation !== '' ? '.' + machine.complectation : ''}</div>
            <div>Последние данные — ${moment(machine.time).format("DD.MM.YYYY HH:mm:ss")}</div>
            <div><a href="/machine/${machine.nomer}" class="link">К машине</a></div>
            <div>Наработка — ${machine.motoHours} м/ч</div>
        `,
            iconCaption: machine.nomer,
            clusterCaption: "<span style='width: 10px; height: 10px; display: inline-block; background: " + getColor(machine.state) + "; border-radius: 50%; margin-right: 12px;'></span>" + machine.nomer
        }
    }

    const getPointOptions = (machine) => {
        return {
            preset: "islands#violetIcon",
            iconColor: getColor(machine.state),
        }
    }

    const filter = () => {
        dispatch(getMachineMap(type_id))
    }


    if (promiseInProgress)
        return <Preloader />

    return (
        <YMaps query={{
            apikey: "be2343a6-8407-4405-81d3-603bb18c2d4e"
        }}>
            <Box sx={{
                position: 'absolute',
                zIndex: 1000,
                width: '218px',
                top: '24px',
                right: '24px'
            }}>
                <Box
                    sx={{
                        background: "#f5f6fa",
                        padding: "20px 24px",
                        borderRadius: '4px',
                        marginBottom: "24px"
                    }}
                >
                    <h3
                        style={{
                            margin: "0",
                            marginBottom: "10px"
                        }}
                    >Поиск по номеру</h3>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Номер"
                        type="text"
                        fullWidth
                        variant="outlined"
                        size='small'
                        value={machine_nomer}
                        onChange={e => setMachineNomer(e.target.value)}
                    />
                    <Button onClick={() => dispatch(serchMachine(machine_nomer, navigate))} variant="contained" color="custom_blue2">Найти</Button>
                </Box>
                <Box
                    sx={{
                        background: "#f5f6fa",
                        padding: "20px 24px",
                        borderRadius: '4px'
                    }}
                >
                    <h3
                        style={{
                            margin: "0",
                            marginBottom: "10px"
                        }}
                    >Фильтр</h3>

                    <TextField
                        id="select-currency-native"
                        margin="dense"
                        select
                        label="Тип машины"
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                        size='small'
                        value={type_id}
                        onChange={e => setType_id(parseInt(e.target.value))}
                    >
                        <option value={0}>Все</option>
                        {
                            type.length && type.map(item => <option key={item.id} value={item.id}>{item.title}</option>)
                        }
                    </TextField>
                    <Button onClick={filter} variant="contained" color="custom_blue2">Применить</Button>
                </Box>
            </Box>
            <Map
                state={mapState}
                width="100%"
                height="100%"
                modules={["package.full"]}
            >
                {/* <Circle
                    geometry={[[69.343973, 88.210247], 2000000]}
                    options={{
                        fillColor: "#DB709377",
                        strokeColor: "#990066",
                        strokeOpacity: 0.8,
                        strokeWidth: 5,
                    }}
                /> */}
                <Clusterer
                    options={{
                        clusterIconLayout: "default#pieChart",
                        clusterIconPieChartRadius: 25,
                        clusterIconPieChartCoreRadius: 10,
                        clusterIconPieChartStrokeWidth: 1,
                        clusterDisableClickZoom: true,
                        clusterHideIconOnBalloonOpen: false,
                        geoObjectHideIconOnBalloonOpen: false
                    }}
                >
                    {
                        machineMap.length !== 0 ? machineMap.map((machine) => {
                            if (machine.lat !== '0.0' && machine.lon !== '0.0')
                                if (machine.lat !== '-0.0' && machine.lon !== '-0.0')
                                    return (
                                        <Placemark
                                            key={machine.id}
                                            geometry={[machine.lat, machine.lon]}
                                            properties={getPointData(machine)}
                                            options={getPointOptions(machine)}
                                        />
                                    )
                        }) : null
                    }
                </Clusterer>
                <ListBox data={{ content: "Фильтр" }}>
                    <ListBoxItem data={{ content: "В работе" }} state={{ selected: active }} />
                    <ListBoxItem data={{ content: "Нет данных то 3 до 15 дней" }} state={{ selected: medium }} />
                    <ListBoxItem data={{ content: "Нет данных больше 15 дней" }} state={{ selected: diactive }} />
                </ListBox>
            </Map>
        </YMaps>
    )
}

export default Home