import DrawCoords from "../DrawCoords";
import WorkDataItem from "../WorkDataItem";

const WorkDataDraw = ({ data, width }) => {
    const height = (data.length * 45) + 15;

    return (
        <svg height={height} width={width}>
            {
                data.map((item, i) => {
                    return (
                        <g key={i} transform={`translate(0 ${(i * 45) + 25})`}>
                            <WorkDataItem data={item} width={width} />
                        </g>
                    )
                })
            }
            <DrawCoords width={width} height={height} />
        </svg>
    )
}

export default WorkDataDraw