import { Box, Button, Grid, ListItemText, MenuItem, MenuList, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { BackButton, PageTitle, Preloader } from '../../components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDocs, getDocsTypes, setDocs } from '../../store/Clerk/actions'
import * as ClerkSelector from '../../store/Clerk/selectors'
import * as MachineSelector from "../../store/Machine/selectors"
import { usePromiseTracker } from 'react-promise-tracker'
import Item from './Item'
import { getMachine } from '../../store/Machine/actions'

function Manual() {
    const { nomer } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { promiseInProgress } = usePromiseTracker()

    const docs = useSelector(ClerkSelector.docs)
    const docsType = useSelector(ClerkSelector.docsType)
    const machine = useSelector(MachineSelector.machine)

    const [type, setType] = useState(0)

    const Card = ({ title = '', url, cover = "" }) => {
        const navigate = useNavigate()

        return (
            <Box sx={{
                width: '218px',
                height: '258px',
                backgroundColor: "#F5F6FA",
                borderRadius: '4px',
                overflow: "hidden",
                ml: '24px',
                mb: '24px',
                cursor: "pointer"
            }}
                onClick={() => navigate(url)}
            >
                <img style={{
                    width: '100%',
                    height: '73.6%',
                    objectFit: 'cover'
                }} src={cover} alt={title} />
                <Box sx={{
                    padding: '15px 20px',
                    height: '26.4%'
                }}>
                    <Typography title={title} sx={{
                        fontSize: '18px',
                        fontWeight: '700',
                        lineHeight: '1',
                    }}>
                        {
                            title.length > 27 ? title.substring(0, 26) + "..." : title
                        }
                    </Typography>
                </Box>
            </Box>
        )
    }

    const CardWrapper = ({ children }) => (
        <Box sx={{
            mt: '15px',
            display: 'flex',
            ml: '-24px',
            flexWrap: 'wrap'
        }}>
            {
                children
            }
        </Box>
    )

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        setType(query.has('type') ? query.get('type') : 1)

        dispatch(getMachine(nomer))
    }, [])

    useEffect(() => {
        if (typeof machine.model_id !== 'undefined') {
            dispatch(getDocsTypes())
            dispatch(getDocs(type, machine.model_id, machine.gen_id))
        }
    }, [machine])

    const handleSelectType = (id) => {
        navigate(`/machine/${nomer}/manual?type=${id}`)
        setType(id)
        dispatch(getDocs(id, machine.model_id, machine.gen_id))
    }

    if (promiseInProgress) return <Preloader />
    return (
        <Box sx={{
            p: 2,
            width: '100%'
        }}>
            <BackButton nomer={nomer} />
            <PageTitle title="Руководства и инструкции" />
            <Grid container
                columnSpacing={3}
                sx={{
                    mt: '15px',
                    height: '100%'
                }}
            >
                <Grid item xs={2}>
                    <Box sx={{
                        backgroundColor: '#F5F6FA',
                        borderRadius: '4px',
                        padding: "10px 0"
                    }}>
                        <MenuList>
                            {docsType.map(item => (
                                <MenuItem disablePadding selected={item.id === parseInt(type)} key={item.id} onClick={() => handleSelectType(item.id)}>
                                    <ListItemText sx={{ ".MuiListItemText-primary": { fontSize: '15px' } }} primary={item.title} />
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <CardWrapper>
                        {
                            docs.map(item => <Card key={item.id} url={`/machine/${nomer}/manual/${item.id}`} title={item.title} cover={`http://clerk.dstapi.ru/uploads/docs/${item.cover}`} />)
                        }
                    </CardWrapper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Manual