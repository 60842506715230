import { Box, Button, Checkbox, FormControlLabel, FormLabel, Grid, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton, Table } from '../../components'
import { getRequests, getStatus, getWorkType, setRequests } from '../../store/Request/actions'
import * as RequestSelector from "../../store/Request/selectors"
import { type } from '../../store/Machine/selectors'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Requests() {
    const { nomer } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [params, setParams] = useState(0)

    const work_type = useSelector(RequestSelector.work_type)
    const requests = useSelector(RequestSelector.requests)
    const status = useSelector(RequestSelector.status)

    useEffect(() => {
        dispatch(getWorkType())
        dispatch(getRequests(nomer))
        dispatch(getStatus())
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const qParams = query.get('param') !== null ? parseInt(query.get('param')) : 0
        if (!isNaN(qParams) && qParams > 0 && qParams < 4) {
            setParams(qParams)
            dispatch(setRequests([]))
            dispatch(getRequests(nomer, qParams))
            navigate(`${location.pathname}`)
            return
        }
        dispatch(setRequests([]))
        dispatch(getRequests(nomer, params))
    }, [params])

    const engineer = (item) => {
        return typeof item.engineer !== 'undefined' ? item.engineer.name : 'Не назначен'
    }

    const next = (id) => {
        navigate(`/machine/${nomer}/request/${id}`)
    }

    const tabCols = [
        { title: "Номер", code: "id", type: 'link', fun: next },
        { title: "Статус", code: "status_id", vars: 'status' },
        { title: "Инженер", val: engineer },
        { title: "Тип работ", code: "work_type_id", vars: 'work_type' },
        { title: "Описание", code: "description" },
        { title: "Наработка", code: "work_time" },
        { title: "Дата заявки", code: "created_at", type: "dateTime" }
    ]

    const tabParams = {
        work_type,
        status
    }

    return (
        <Box sx={{ flex: 1, padding: '24px' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    margin: '0 0 15px 0',
                }}
            >
                <BackButton nomer={nomer} />
            </Box>
            <Grid container
                columnSpacing={3}
                sx={{
                    height: '100%'
                }}
            >
                <Grid item xs={1.7} sx={{
                    height: '100%'
                }}>
                    <Box
                        sx={{
                            background: "#f5f6fa",
                            padding: "20px 24px",
                            borderRadius: '4px'
                        }}
                    >
                        <h3
                            style={{
                                margin: "0",
                                marginBottom: "10px"
                            }}
                        >Фильтр</h3>
                        <FormLabel id="radio-buttons-group-label">Параметры</FormLabel>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {
                                work_type.length !== 0 ?
                                    work_type.map(item => <FormControlLabel key={item.id} value={item.id} checked={params === item.id} control={<Checkbox />} label={item.title} onChange={e => setParams(parseInt(e.target.value))} />)
                                    : null
                            }
                            <FormControlLabel value={0} checked={params === 0} control={<Checkbox />} label="Все" onChange={e => setParams(parseInt(e.target.value))} />
                        </RadioGroup>
                    </Box>
                </Grid>
                <Grid item xs={10.3} sx={{ paddingBottom: '24px' }}>
                    <Table cols={tabCols} data={requests.length ? requests : []} params={tabParams} rows={0} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Requests