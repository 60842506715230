import React from 'react'
import { createTheme, ThemeProvider, Box } from "@mui/material"
import Header from '../Header'
import { Container } from '@mui/system'

function Wrapper({ children }) {

    const theme = createTheme({
        palette: {
            custom_purple: {
                main: '#5955b3',
                light: '#5955b3',
                dark: '#5955b3',
                contrastText: '#fff'
            },
            custom_blue: {
                main: '#035b86',
                light: '#035b86',
                dark: '#035b86',
                contrastText: '#fff'
            },
            custom_white: {
                main: '#fff',
                light: '#fff',
                dark: '#fff',
                contrastText: '#fff'
            },
            custom_blue_outlined: {
                main: '#29ABE2',
                light: '#29ABE2',
                dark: '#29ABE2',
                contrastText: '#29ABE2'
            },
            custom_blue2: {
                main: '#3498db',
                light: '#3498db',
                dark: '#3498db',
                contrastText: '#fff'
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Box sx={{
                height: `calc(100vh - 69px)`,
                width: '100%',
                overflow: 'hidden',
                overflowY: 'scroll',
                display: 'flex',
                zIndex: '0',
                "&::-webkit-scrollbar": {
                    width: 0
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#b9b7ff",
                    borderRadius: '5px'
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#5955b3",
                    borderRadius: '5px'
                },
                position: "relative"
            }}>
                {children}
            </Box>
        </ThemeProvider>
    )
}

export default Wrapper