import {
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as AuthSelector from "../../store/Auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import { addRegistrationRequest, setRegLoad } from "../../store/Auth/actions";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [psm, setPsm] = useState([]);
  const [sved, setSved] = useState([]);
  const [title, setTitle] = useState("");
  const [inn, setInn] = useState("");
  const [kpp, setKpp] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [loader, setLoader] = useState(false);

  const registrationLoad = useSelector(AuthSelector.registrationLoad);
  const uid = useSelector(AuthSelector.uid);

  useEffect(() => {
    if (registrationLoad !== null && typeof registrationLoad === "boolean")
      setTimeout(() => {
        setLoader(false);
        if (registrationLoad === true) {
          dispatch(setRegLoad(null));
          navigate("/");
          setTitle("");
          setInn("");
          setKpp("");
          setAddress("");
          setName("");
          setEmail("");
          setPhone("");
          setPsm([]);
          setSved([]);
        }
      }, 1500);
  }, [registrationLoad]);

  const Loader = () => {
    switch (registrationLoad) {
      case "load":
        return (
          <div className="cssload-container">
            <div className="cssload-speeding-wheel"></div>
          </div>
        );
      case true:
        return (
          <CheckCircleTwoToneIcon sx={{ color: "#2e7d32", fontSize: "50px" }} />
        );
      case false:
        return (
          <>
            <Typography>
              Ошибка подачи заявки. Возможно компания с таким ИНН уже существует
            </Typography>
            <ErrorTwoToneIcon sx={{ color: "#d32f2f", fontSize: "50px" }} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flex: 1, p: "24px" }}>
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "23px",
              mb: 2,
              fontWeight: "500",
            }}
            component="h1"
          >
            Заявка на регистрацию
          </Typography>
          <FormControl
            component="form"
            onSubmit={(e) => {
              e.preventDefault();

              if (psm.length === 0) {
                toast.error("Необходимо добавить файлы");
                return false;
              }

              if (sved.length === 0) {
                toast.error("Необходимо добавить файлы");
                return false;
              }

              dispatch(
                addRegistrationRequest(
                  {
                    title: title,
                    inn: inn,
                    kpp: kpp,
                    address: address,
                    email: email,
                    name: name,
                    phone: phone,
                    sved: sved,
                    psm: psm,
                  },
                  uid
                )
              );
              setLoader(true);
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <TextField
                  margin="dense"
                  label="Наименование"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="ИНН"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={inn}
                  onChange={(e) => setInn(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="КПП"
                  required={true}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={kpp}
                  onChange={(e) => setKpp(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Адрес"
                  required={true}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  marginLeft: "24px",
                }}
              >
                <TextField
                  margin="dense"
                  label="ФИО Ответственного лица"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Почта"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Телефон"
                  type="phone"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Box
                  sx={{
                    mt: "15px",
                    mb: "10px",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      width: "65%",
                    }}
                  >
                    ПСМ *
                    <input
                      multiple
                      hidden
                      type="file"
                      onChange={(e) => setPsm([...psm, ...e.target.files])}
                    />
                  </Button>
                </Box>
                {psm.length !== 0 && (
                  <List>
                    {psm.map((item, i) => (
                      <>
                        <ListItem
                          key={item.name}
                          secondaryAction={
                            <IconButton
                              color="error"
                              onClick={() =>
                                setPsm((items) =>
                                  items.filter((item, index) => i !== index)
                                )
                              }
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.name} />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                )}
                <Box
                  sx={{
                    mt: "15px",
                    mb: "10px",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      width: "65%",
                    }}
                  >
                    Свидетельство о регистрации машины(трактора) *
                    <input
                      multiple
                      hidden
                      type="file"
                      onChange={(e) => setSved([...sved, ...e.target.files])}
                    />
                  </Button>
                </Box>
                {sved.length !== 0 && (
                  <List>
                    {sved.map((item, i) => (
                      <>
                        <ListItem
                          key={item.name}
                          secondaryAction={
                            <IconButton
                              color="error"
                              onClick={() =>
                                setSved((items) =>
                                  items.filter((item, index) => i !== index)
                                )
                              }
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.name} />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                )}
                <Button
                  type="submit"
                  sx={{ marginLeft: "83%" }}
                  variant="contained"
                >
                  Отправить
                </Button>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                  }}
                >
                  Нажимая кнопку "Отправить" Вы подтверждаете согласие на{" "}
                  <Link
                    style={{
                      color: "#1976d2",
                    }}
                    target="_blank"
                    to="/policy"
                  >
                    обработку персональных данных
                  </Link>{" "}
                  и с{" "}
                  <Link
                    style={{
                      color: "#1976d2",
                    }}
                    target="_blank"
                    to="/policy"
                  >
                    пользовательским соглашением
                  </Link>
                </Typography>
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              mt: 7,
              ml: 2,
              mr: 2,
              fontSize: "14px",
              lineHeight: 1.4,
            }}
          >
            В данной форме необходимо заполнить все поля, отмеченные символом
            «*».
            <br /> <br />
            Для добавления техники в личный кабинет необходимо загрузить Паспорт
            Самоходной Машины (ПСМ) и Свидетельство о Регистрации Машины
            (Трактора) на каждую имеющуюся единицу техники.
            <br /> <br />
            Заявка на регистрацию рассматривается в течение 1 (одного) рабочего
            дня с даты ее получения.
          </Typography>
        </Grid>
      </Grid>
      <Dialog open={loader}>
        <DialogContent
          sx={{
            width: "350px",
            height: "250px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Registration;
