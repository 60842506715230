import {
  Box,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import ShopSidebar from "./ShopSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as ShopSelector from "../../store/Shop/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getShopOrders,
  getShopOrderStatuses,
  setShopOrderStatus,
} from "../../store/Shop/actions";
import moment from "moment";
import { formatPrice } from "../../utils/price";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders = useSelector(ShopSelector.orders);

  const orderStatuses = useSelector(ShopSelector.orderStatuses);
  const orderStatus = useSelector(ShopSelector.orderStatus);

  useEffect(() => {
    if (orderStatus !== null) {
      dispatch(getShopOrders(orderStatus));
    }
  }, [orderStatus]);

  useEffect(() => {
    if (orderStatuses.length === 0) dispatch(getShopOrderStatuses());
  }, []);

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ShopSidebar b={true} o={false} />

          <Paper sx={{ maxWidth: "100%", mb: 3 }}>
            <MenuList>
              {orderStatuses.map((item) => (
                <MenuItem
                  key={item.id}
                  selected={item.id === orderStatus}
                  onClick={() => dispatch(setShopOrderStatus(item.id))}
                >
                  <ListItemText
                    sx={{ ".MuiListItemText-primary": { fontSize: "15px" } }}
                    primary={item.title}
                  />
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Заказы
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Сумма</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Позиций</TableCell>
                  <TableCell>Дата</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Typography
                        onClick={() => {
                          navigate(`${row.id}`);
                        }}
                        color="primary"
                        sx={{ cursor: "pointer" }}
                      >
                        {`Заказ №${row.id}`}
                      </Typography>
                    </TableCell>
                    <TableCell>{formatPrice(row.sum)}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>
                      {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Orders;
