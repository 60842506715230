import { Box, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMachineWorkTable, getMachineWorkTable, getMachine } from '../../store/Machine/actions'
import moment from 'moment'
import 'moment-timezone'
import * as MachineSelector from "../../store/Machine/selectors"
import { BackButton } from "../../components"

function MachineWorkTable() {
    const { nomer } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [fromDate, setFromDate] = useState(moment().subtract(2, 'days').format('yyyy-MM-DDTHH:mm'))
    const [maxFromDate, setMaxFromDate] = useState(0)
    const [toDate, setToDate] = useState(moment().format('yyyy-MM-DDTHH:mm'))
    const [minToDate, setMinToDate] = useState(0)
    const machine = useSelector(MachineSelector.machine)

    const [page, setPage] = useState(1)

    const dataTable = useSelector(MachineSelector.machineWorkTable)

    const ref = useRef()

    const handlePage = (page) => {
        dispatch(setMachineWorkTable([]))
        setPage(page)
        dispatch(getMachineWorkTable(nomer, page, getMomentInUtc(fromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(toDate).format('yyyy-MM-DDTHH:mm')))
        ref.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        navigate(`${location.pathname}?page=${page}${fromDate !== 0 ? '&from=' + fromDate : ''}${toDate !== 0 ? '&to=' + toDate : ''}`)
    }

    useEffect(() => {
        dispatch(getMachine(nomer))
        const query = new URLSearchParams(location.search)
        const qPage = query.get('page') !== null ? query.get('page') : 1
        const qFromDate = query.get('from') !== null && query.get('from') !== 0 ? query.get('from') : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm')
        const qToDate = query.get('to') !== null && query.get('to') !== 0 ? query.get('to') : moment().format('yyyy-MM-DDTHH:mm')

        if (qPage !== null || qFromDate !== null || qToDate !== null) {
            setPage(parseInt(qPage))
            setFromDate(qFromDate)
            setToDate(qToDate)
            dispatch(getMachineWorkTable(parseInt(nomer), parseInt(qPage), getMomentInUtc(qFromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(qToDate).format('yyyy-MM-DDTHH:mm')))
        }
        return () => {
            dispatch(setMachineWorkTable([]))
        }
    }, [])

    const selectByDate = () => {
        dispatch(setMachineWorkTable([]))
        setPage(1)
        dispatch(getMachineWorkTable(nomer, 1, getMomentInUtc(fromDate).format('yyyy-MM-DDTHH:mm'), getMomentInUtc(toDate).format('yyyy-MM-DDTHH:mm')))
        navigate(`${location.pathname}?page=${1}${fromDate !== 0 ? '&from=' + fromDate : ''}${toDate != 0 ? '&to=' + toDate : ''}`)
    }

    const getMomentInUtc = (datetime) => {
        return moment.tz(datetime, Intl.DateTimeFormat().resolvedOptions().timeZone).utc()
    }

    const tabColsFirst = machine.type_id === 11 ? [
        { label: "Время", type: "dateTime", rowSpan: 2 },
        { label: "Моточасы", rowSpan: 2 },
        { label: "Давление напорной линии 1, бар", rowSpan: 2 },
        { label: "Давление напорной линии 2, бар", rowSpan: 2 },
        { label: "Давление сливной линии, бар", rowSpan: 2 },
        { label: "Температура масла гидросистемы, °C", rowSpan: 2 },
        { label: "Температура ОЖ, °C", rowSpan: 2 },
        { label: "Фактические обороты ДВС, об/мин", rowSpan: 2 },
        { label: "Требуемые обороты ДВС, об/мин", rowSpan: 2 },
        { label: "Давление масла ДВС, кПа", rowSpan: 2 },
        { label: "Уровень топлива, %", rowSpan: 2 },
        { label: "Состояние системы Fan-drive", rowSpan: 2 },
        { label: "Состояние кнопки безопасности", rowSpan: 2 },
        { label: "Положение опоры 1", rowSpan: 2 },
        { label: "Положение опоры 2", rowSpan: 2 },
        { label: "Положение опоры 3", rowSpan: 2 },
        { label: "Положение опоры 4", rowSpan: 2 },
        { label: "Ошибки", rowSpan: 2 },

    ] : [
        { label: "Время", type: "dateTime", rowSpan: 2 },
        { label: "М/Ч", rowSpan: 2 },
        { label: "Давление борта", colSpan: 2 },
        { label: "Темп. масла ГСТ", rowSpan: 2 },
        { label: "Скорость мотора", colSpan: 2 },
        { label: "Скорость движения", rowSpan: 2 },
        { label: "Режим работы", rowSpan: 2 },
        { label: "Давление тормозной системы", rowSpan: 2 },
        { label: "Обороты ДВС", rowSpan: 2 },
        { label: "Темп. ОЖ", rowSpan: 2 },
        { label: "Давление масла ДВС", rowSpan: 2 },
        { label: "Давление FANDRIVE", rowSpan: 2 },
        { label: "Давление навесного оборудования", rowSpan: 2 },
        { label: "Темп. окруж. среды", rowSpan: 2 },
    ]

    const tabColsSecond = [
        { label: "Лев." },
        { label: "Прав." },
        { label: "Лев." },
        { label: "Прав." },
    ]

    const fanState = [
        'Инициализация',
        'Прямой режим',
        'Реверс',
        'Ожидание',
        'Выключен',
        'Принудительно включен'
    ]

    const swtFoot = [
        'Рабочее',
        'Транспортное',
    ]

    const btnSafety = [
        'Нажата',
        'Отжата'
    ]

    return (
        <Box ref={ref} sx={{ flex: 1, padding: '24px', overflow: 'scroll' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    margin: '0 0 15px 0',
                }}
            >
                <BackButton nomer={nomer} />
            </Box>
            <Box sx={{ display: 'flex', pb: '24px', justifyContent: 'flex-end', alignItems: 'center' }}>
                <TextField id="FromDate"
                    sx={{ mr: '20px' }}
                    value={fromDate != 0 ? fromDate : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm')}
                    inputProps={{
                        min: '25.07.1996T00:07',
                        max: maxFromDate != 0 ? maxFromDate : moment().subtract(1, 'days').format('yyyy-MM-DDTHH:mm'),
                    }}
                    onChange={
                        ((e) => {
                            if (moment(toDate).diff(e.target.value, 'days') <= 0) {
                                setFromDate(moment(toDate).subtract(1, 'days').format('yyyy-MM-DDTHH:mm'))
                                return
                            }
                            setFromDate(e.target.value)
                            setMinToDate(moment(e.target.value).add(1, 'days').format('yyyy-MM-DDTHH:mm'))
                        })
                    }
                    variant="outlined" type="datetime-local" />
                <TextField id="ToDate"
                    sx={{ mr: '40px' }}
                    value={toDate != 0 ? toDate : moment().format('yyyy-MM-DDTHH:mm')}
                    inputProps={{
                        min: minToDate != 0 ? minToDate : '24.07.1996T00:07',
                        max: moment().format('yyyy-MM-DDTHH:mm')
                    }}
                    onChange={
                        ((e) => {
                            if (moment(e.target.value).diff(fromDate, 'days') <= 0) {
                                setToDate(moment(fromDate).add(1, 'days').format('yyyy-MM-DDTHH:mm'))
                                return
                            }
                            setMaxFromDate(moment(e.target.value).subtract(1, 'days').format('yyyy-MM-DDTHH:mm'))
                            setToDate(e.target.value)
                        })
                    }
                    variant="outlined" type="datetime-local" />
                <Button sx={{ width: '150px', height: '40px', lineHeight: '40px' }} onClick={selectByDate} variant="contained" color="custom_blue2">Найти</Button>
            </Box>
            <Box xs={12} sx={{ paddingBottom: '24px' }}>
                <Table id="mainTable" aria-label="simple table" ref={ref} 
                sx={{ 
                    minWidth: 650, 
                    flex: 1, 
                    overflow: 'scroll', 
                    borderCollapse: "separate", 
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderRadius: "6px",
                    position: "relative",
                }} >
                    <TableHead sx={{backgroundColor: "#fff", position: "sticky",top: "-23px"}}>
                        <TableRow>
                            {tabColsFirst.map((headCell, index) => {
                                var css = headCell.label.split(' ').length == 1 ? 'nowrap' : 'normal'
                                return (
                                    <TableCell
                                        colSpan={headCell.colSpan ? headCell.colSpan : 1}
                                        rowSpan={headCell.rowSpan ? headCell.rowSpan : 1}
                                        key={index}
                                        padding='normal'
                                        align='center'
                                        sx={{ whiteSpace: css,  borderBottom: "1px solid rgba(224, 224, 224, 1)"}}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        {machine.type_id !== 11 &&
                        <TableRow>
                            {tabColsSecond.map((headCell, index) => {
                                return (
                                    <TableCell
                                        colSpan={headCell.colSpan ? headCell.colSpan : 1}
                                        rowSpan={headCell.rowSpan ? headCell.rowSpan : 1}
                                        key={index}
                                        padding='normal'
                                        align='center'
                                        sx={{borderBottom: "1px solid rgba(224, 224, 224, 1)"}}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>}
                    </TableHead>
                    <TableBody>
                        {
                            machine.type_id !== 11 ?
                                    dataTable !== null && typeof dataTable.data != 'undefined' ?
                                        (
                                            dataTable.data.map((row, index) => {
                                                //row.errors.map((err, idx) => {return idx > 0 ? `, ${Number(err).toFixed(0)}` : `${Number(err).toFixed(0)}`})
                                                return (
                                                    <TableRow
                                                        key={index}
                                                    >
                                                        <TableCell align="center" component="th" scope="row" type="date">
                                                            {moment(row.time).format('DD.MM.YYYY HH:mm.ss')}
                                                        </TableCell>
                                                        <TableCell align="center">{row.motoHours}</TableCell>
                                                        <TableCell align="center">{row.pressPmpL}</TableCell>
                                                        <TableCell align="center">{row.pressPmpR}</TableCell>
                                                        <TableCell align="center">{row.tempHydOil}</TableCell>
                                                        <TableCell align="center">{row.motSpeedL}</TableCell>
                                                        <TableCell align="center">{row.motSpeedR}</TableCell>
                                                        <TableCell align="center">{row.vehSpeed}</TableCell>
                                                        <TableCell align="center">{row.transportMode ? "Да" : "Нет"}</TableCell>
                                                        <TableCell align="center">{row.pressBrake}</TableCell>
                                                        <TableCell align="center">{row.rpm}</TableCell>
                                                        <TableCell align="center">{row.tempCoolant}</TableCell>
                                                        <TableCell align="center">{row.engOilPress}</TableCell>
                                                        <TableCell align="center">{row.pressFanDrive}</TableCell>
                                                        <TableCell align="center">{row.pressAttach}</TableCell>
                                                        <TableCell align="center">{row.tempEnv}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        ) :
                                        (<TableRow>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                            <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                        </TableRow>
                                        )
                                 : dataTable !== null && typeof dataTable.data != 'undefined' ?
                                 (
                                     dataTable.data.map((row, index) => {
                                         return (
                                             <TableRow
                                                 key={index}
                                             >
                                                 <TableCell align="center" component="th" scope="row" type="date">
                                                     {moment(row.time).format('DD.MM.YYYY HH:mm.ss')}
                                                 </TableCell>
                                                 <TableCell align="center">{row.motoHours}</TableCell>
                                                 <TableCell align="center">{row.pressureLine1}</TableCell>
                                                 <TableCell align="center">{row.pressureLine2}</TableCell>
                                                 <TableCell align="center">{row.pressDrainLine}</TableCell>
                                                 <TableCell align="center">{row.tempHydOil}</TableCell>
                                                 <TableCell align="center">{row.tempCoolant}</TableCell>
                                                 <TableCell align="center">{row.rpm}</TableCell>
                                                 <TableCell align="center">{row.engSpeedTsc1}</TableCell>
                                                 <TableCell align="center">{row.engOilPress}</TableCell>
                                                 <TableCell align="center">{row.fuelLevel/10}</TableCell>
                                                 <TableCell align="center">{fanState[Number(row.fanState)]}</TableCell>
                                                 <TableCell align="center">{btnSafety[Number(row.btnSafety)]}</TableCell>
                                                 <TableCell align="center">{swtFoot[Number(row.swtFoot1)]}</TableCell>
                                                 <TableCell align="center">{swtFoot[Number(row.swtFoot2)]}</TableCell>
                                                 <TableCell align="center">{swtFoot[Number(row.swtFoot3)]}</TableCell>
                                                 <TableCell align="center">{swtFoot[Number(row.swtFoot4)]}</TableCell>
                                                 <TableCell align="center">{row.errors && row.errors.join("\n")}</TableCell>
                                             </TableRow>
                                         )
                                     })
                                 ) :
                                 (<TableRow>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                     <TableCell align="center"><Skeleton sx={{ borderRadius: '4px', width: '100%' }} variant="rectangular" height={600} /></TableCell>
                                 </TableRow>
                                 )
                        }
                    </TableBody>
                </Table>
                <Pagination
                    sx={{
                        marginTop: '24px',
                        marginBottom: '24px'
                    }}
                    count={typeof dataTable.last_page != 'undefined' ? dataTable.last_page : 0}
                    page={page}
                    shape="rounded"
                    color="custom_blue2"
                    onChange={(event, page) => handlePage(page)}
                />
            </Box>
        </Box>
    )
}

export default MachineWorkTable