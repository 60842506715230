import { Box, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as AuthSelector from "../../store/Auth/selectors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const InputMessage = ({}) => {
  const socket = useSelector(AuthSelector.socket);
  const ticketSel = useSelector(AuthSelector.socketTicketSel);

  const [textMessage, setTextMessage] = useState("");

  const sendMessage = () => {
    if (
      typeof ticketSel === "undefined" ||
      ticketSel === null ||
      textMessage.length === 0
    )
      return;
    if (typeof socket === "undefined" || socket === null) {
      toast.error("Ошибка отправки");
      return;
    }
    if (!socket.connected) {
      toast.error("Ошибка подключения");
      return;
    }
    socket.emit("addMessage", ticketSel.id, textMessage);
    setTextMessage("");
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          height: "auto",
          minHeight: "60px",
          width: "calc(100% - 20px)",
          pb: "15px",
          backgroundColor: "#fff",
        }}
      >
        <Paper
          component="form"
          sx={{
            padding: "15px 10px",
            display: "flex",
            alignItems: "center",
            width: "80%",
            maxWidth: 700,
            minWidth: 400,
            boxShadow: "none",
          }}
        >
          <IconButton
            sx={{ p: "10px" }}
            aria-label="menu"
            disabled={
              typeof ticketSel === "undefined" ||
              ticketSel === null ||
              parseInt(ticketSel.status_id) === 4
            }
          >
            <AttachFileIcon sx={{ color: "#035B86" }} />
          </IconButton>
          <TextField
            sx={{ ml: "20px", flex: 1 }}
            placeholder="Сообщение"
            multiline
            size="small"
            value={textMessage}
            maxRows={4}
            inputProps={{ "aria-label": "Сообщение" }}
            onChange={(newValue) => setTextMessage(newValue.target.value)}
            InputProps={{
              readOnly:
                typeof ticketSel === "undefined" ||
                ticketSel === null ||
                parseInt(ticketSel.status_id) === 4,
            }}
            disabled={
              typeof ticketSel === "undefined" ||
              ticketSel === null ||
              parseInt(ticketSel.status_id) === 4
            }
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={sendMessage}
            disabled={
              typeof ticketSel === "undefined" ||
              ticketSel === null ||
              parseInt(ticketSel.status_id) === 4
            }
          >
            <SendIcon sx={{ color: "#035B86" }} />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default InputMessage;
