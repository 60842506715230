/* import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const CartPdf = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);

export default CartPdf;
 */

import {
  PDFDownloadLink,
  Document,
  Page,
  StyleSheet,
  View,
  Font,
  Text,
  Image,
} from "@react-pdf/renderer";

import fontMedium from "../../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontLight from "../../../assets/fonts/Roboto/Roboto-Light.ttf";

import logo from "../../../assets/logo_DST.png";
import backgroundPdf from "../../../assets/backgroundPdf.jpg";
import { formatPrice } from "../../../utils/price";

const DocumentPdf = (items) => {
  Font.register({
    family: "Roboto",
    fonts: [{ src: fontMedium }, { src: fontLight, fontWeight: 400 }],
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Roboto",
      fontSize: 11,
      position: "relative",
      padding: "30px 20px 40px 60px",
    },
    h1: {
      fontSize: 14,
      marginBottom: "40px",
      textAlign: "center",
      width: "100%",
    },
    header: {
      width: "100%",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      fontSize: 10,
      fontWeight: 400,
      marginBottom: "120px",
      position: "relative",
    },
    backgroundImage: {
      width: "200px",
      height: "150px",
      position: "absolute",
      right: "100px",
      top: "-30px",
    },
    headerLogo: { width: "20%", height: "auto" },
    headerInfo: {
      width: "80%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
      fontWeight: 400,
    },
    headerInfoItem: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      lineHeight: 1.5,
    },
    table: { width: "100%" },
    row: { display: "flex", flexDirection: "row" },
    cellName: {
      textAlign: "left",
      width: "20%",
      borderBottom: "1px solid #ccc",
      padding: "10px 0",
    },
    cellValue: {
      textAlign: "center",
      width: "20%",
      borderBottom: "1px solid #ccc",
      padding: "10px 0",
    },
  });

  const getTableItems = ({ items, sale }) => {
    if (typeof items === "undefined" || items.length === 0) return;
    return items.map((row, i) => {
      if (typeof row === "undefined" || row.length === 0) return;
      return (
        <View key={i} style={styles.row}>
          <Text style={styles.cellName}>
            {row.alter_title === "" ? row.title : row.alter_title}
          </Text>
          <Text style={styles.cellValue}>{row.full_code}</Text>
          <Text style={styles.cellValue}>{row.count}</Text>
          <Text style={styles.cellValue}>
            {row.price == 0
              ? "По запросу"
              : sale
              ? formatPrice((row.price / 100) * (100 - sale))
              : formatPrice(row.price)}
          </Text>
          <Text style={styles.cellValue}>
            {row.price == 0
              ? "-"
              : sale
              ? formatPrice(((row.price * row.count) / 100) * (100 - sale))
              : formatPrice(row.price * row.count)}
          </Text>
        </View>
      );
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image source={backgroundPdf} style={styles.backgroundImage} />
          <View style={styles.headerLogo}>
            <Image source={logo} />
          </View>
          <View style={styles.headerInfo}>
            <View style={{ ...styles.headerInfoItem, width: "100px" }}>
              <Text>info@tm10.ru</Text>
              <Text>www.tm10.ru</Text>
            </View>
            <View style={{ ...styles.headerInfoItem, width: "120px" }}>
              <Text>+7 (351) 242-05-51</Text>
              <Text>8-800-500-61-45</Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <Text style={styles.h1}>Список заказа</Text>
          <View style={styles.row}>
            <Text style={styles.cellName}>Навзание</Text>
            <Text style={styles.cellValue}>Код</Text>
            <Text style={styles.cellValue}>Количество</Text>
            <Text style={styles.cellValue}>Цена</Text>
            <Text style={styles.cellValue}>Сумма</Text>
          </View>
          {getTableItems(items)}
        </View>
      </Page>
    </Document>
  );
};

export default DocumentPdf;
