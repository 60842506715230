import { Box, Button, Grid, Typography } from "@mui/material";
import ShopSidebar from "./ShopSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as ShopSelector from "../../store/Shop/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrder, removeOrder } from "../../store/Shop/actions";
import { formatPrice } from "../../utils/price";

const Order = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const order = useSelector(ShopSelector.order);

  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);

  useEffect(() => {
    dispatch(getOrder(id));
  }, []);

  useEffect(() => {
    if (order !== null) {
      let tmp1 = [];
      let tmp2 = [];
      typeof order.items !== "undefined" &&
        order.items.forEach((element) => {
          if (element.price === 0) {
            tmp2.push(element);
          } else {
            tmp1.push(element);
          }
        });
      setList1(tmp1);
      setList2(tmp2);
    }
  }, [order]);

  if (order === null) return null;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ShopSidebar b={false} o={true} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Заказ №{order.id} — {order.status}
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Машина</TableCell>
                  <TableCell>Код</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Количество</TableCell>
                  <TableCell>Цена с НДС, руб</TableCell>
                  <TableCell>Сумма</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list1.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{row.machine_nomer}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>
                      {row.alt_title ? row.alt_title : row.title}
                    </TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>{formatPrice(row.price)}</TableCell>
                    <TableCell>{formatPrice(row.price * row.count)}</TableCell>
                  </TableRow>
                ))}
                {list2.length > 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>Под заказ</TableCell>
                  </TableRow>
                ) : null}
                {list2.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{row.machine_nomer}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>
                      {row.alt_title ? row.alt_title : row.title}
                    </TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell colSpan={2}>По запросу</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mb: 2 }}>
            <Typography>
              Стоимость деталей в наличии — {formatPrice(order.sum)} рублей
            </Typography>
          </Box>
          {order.status_id === 1 ? (
            <Box>
              <Button
                onClick={() => dispatch(removeOrder(id, navigate))}
                sx={{ mb: 2 }}
                color="error"
                variant="contained"
              >
                Отменить
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Order;
