import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AuthSelectors from "../../store/Auth/selectors";
import { setSocketTicketSel } from "../../store/Auth/actions";
import { Messages, InputMessage, TicketsList } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";

function Tickets() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [moreMenu, setMoreMenu] = useState(null);

  const socket = useSelector(AuthSelectors.socket);
  const socketAllTickets = useSelector(AuthSelectors.socketAllTickets);
  const socketTicketSel = useSelector(AuthSelectors.socketTicketSel);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qTicketId =
      query.get("id") !== null && parseInt(query.get("id")) !== 0
        ? parseInt(query.get("id"))
        : null;
    const qMachine =
      query.get("machine") !== null && parseInt(query.get("machine")) !== 0
        ? parseInt(query.get("machine"))
        : null;
    if (qMachine === null && qTicketId !== null) {
      if (typeof socket !== "undefined" && socket !== null)
        socket.emit("getTickets");
      if (
        typeof socketAllTickets !== "undefined" &&
        socketAllTickets !== null
      ) {
        const selTicket = socketAllTickets.find(
          (item) => parseInt(item.id) === parseInt(qTicketId)
        );
        dispatch(setSocketTicketSel(selTicket));
      }
    } else if (
      qMachine !== null &&
      typeof socket !== "undefined" &&
      socket !== null
    ) {
      socket.emit("getTickets", qMachine, null, null, null);
    } else if (
      typeof socket !== "undefined" &&
      socket !== null &&
      (typeof socketAllTickets === "undefined" ||
        socketAllTickets === null ||
        socketAllTickets.length === 0)
    ) {
      socket.emit("getTickets");
      navigate(`${location.pathname}`);
    }
  }, [socket]);

  const handleClose = () => {
    setMoreMenu(null);
  };

  const handleMoreMenu = (event) => {
    setMoreMenu(event.currentTarget);
  };

  const closeTicket = () => {
    handleClose();
    if (typeof socketTicketSel === "undefined" || socketTicketSel === null)
      return;
    if (typeof socket === "undefined" || socket === null || !socket.connected) {
      toast.warning("Ошибка подключения");
      return;
    }
    socket.emit("closeTicket", socketTicketSel.id);
  };

  return (
    <Box sx={{ flex: 1, padding: "24px" }}>
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            height: "100%",
            borderRight: "1px solid #E6E6E6",
          }}
        >
          <TicketsList />
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              paddingLeft: "25px",
              mr: "20px",
              height: 70,
              borderBottom: "1px solid #E6E6E6",
            }}
          >
            {typeof socketTicketSel !== "undefined" &&
            socketTicketSel !== null ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    lineHeight: "70px",
                    fontSize: "1.2em",
                    width: "calc(100% - 50px)",
                    textAlign: "left",
                  }}
                >
                  {socketTicketSel.title}
                </Typography>

                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  sx={{ width: 50, height: 50 }}
                  onClick={handleMoreMenu}
                >
                  <MoreVertIcon sx={{ color: "#C4C4C4" }}></MoreVertIcon>
                </IconButton>
                <Menu
                  id="more-menu"
                  anchorEl={moreMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(moreMenu)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={closeTicket}
                    disabled={parseInt(socketTicketSel.status_id) === 4}
                  >
                    Закрыть обращение
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              padding: "25px 50px 150px",
              height: "calc(100vh - 95px - 70px)",
              overflowY: "scroll",
            }}
          >
            <Messages />
          </Box>
          <InputMessage />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tickets;
