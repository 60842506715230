import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authUser,
  editUser,
  editUserPassword,
  getCheckCode,
  setEmail,
  setPassword,
} from "../../store/Auth/actions";
import * as AuthSelectors from "../../store/Auth/selectors";
import { getMachineOwner } from "../../store/Machine/actions";
import * as MachineSelectors from "../../store/Machine/selectors";
import BackButton from "../../components/BackButton";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

function Profile() {
  const dispatch = useDispatch();

  const user = useSelector(AuthSelectors.user);
  const request_code = useSelector(AuthSelectors.code);
  const machineOwner = useSelector(MachineSelectors.machineOwner);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getMachineOwner(user.company_id));
      setName(user.name);
      setPhone(user.phone);
      setEmail(user.email);
    }
  }, [user]);

  return (
    <Box sx={{ flex: 1, padding: "24px" }}>
      <BackButton />
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            height: "100%",
          }}
        >
          <FormControl
            onSubmit={(e) => {
              e.preventDefault();
              if (user.email !== email) {
                if (parseInt(code) === parseInt(request_code)) {
                  dispatch(
                    editUser({
                      name,
                      phone,
                      email,
                    })
                  );
                } else {
                  toast.error("Не верный код");
                }
              } else {
                dispatch(
                  editUser({
                    name,
                    phone,
                  })
                );
              }
            }}
            component="form"
            sx={{
              width: "40%",
              marginLeft: "auto",
              display: "block",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="ФИО"
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Телефон"
              type="text"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="text"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {user.email !== email ? (
              <Box>
                <TextField
                  margin="dense"
                  label="Код"
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button onClick={() => dispatch(getCheckCode(email))}>
                  Получить код
                </Button>
              </Box>
            ) : null}
            <Box
              sx={{
                mt: 2,
              }}
            >
              <Button type="submit" variant="contained">
                Обновить
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={() => setPasswordModal(true)}
              >
                Изменить пароль
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
            Компания
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography>{machineOwner.title}</Typography>
            <Typography>ИНН — {machineOwner.inn}</Typography>
            <Typography>КПП — {machineOwner.kpp}</Typography>
            <Typography>{machineOwner.address}</Typography>
            <Typography>Размер скидки - {machineOwner.sale}%</Typography>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={passwordModal} onClose={setPasswordModal}>
        <DialogTitle>
          Изменить пароль
          <IconButton
            aria-label="close"
            onClick={() => setPasswordModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const password = e.target.elements.password.value;
              const new_password = e.target.elements.new_password.value;
              const new_password2 = e.target.elements.new_password2.value;

              if (new_password !== new_password2) {
                setError(true);
              } else {
                setError(false);
                dispatch(
                  editUserPassword({
                    email: user.email,
                    password,
                    new_password,
                  })
                );
              }
            }}
          >
            <TextField
              margin="dense"
              label="Старый пароль"
              fullWidth
              variant="outlined"
              type="password"
              name="password"
            />
            <TextField
              margin="dense"
              label="Новый пароль"
              fullWidth
              variant="outlined"
              type="password"
              name="new_password"
            />
            <TextField
              margin="dense"
              label="Новый пароль проверка"
              fullWidth
              variant="outlined"
              type="password"
              name="new_password2"
            />
            {error && (
              <p
                style={{
                  color: "#ff3300e3",
                }}
              >
                Пароли не совпадают
              </p>
            )}
            <Button sx={{ mt: 2 }} type="submit" variant="contained">
              Изменить
            </Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Profile;
