import {
  SET_COMPANY,
  SET_COMPANY_USERS,
  SET_REQUEST,
  SET_REQUESTS,
  SET_SERVICE,
  SET_STATUS,
  SET_WORK_TYPE,
  SET_TICKET_ID,
} from "./types";

const initialState = {
  company_users: [],
  company: [],
  work_type: [],
  requests: [],
  status: [],
  request: [],
  service: [],
  ticketId: null,
};

export const RequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_USERS:
      return { ...state, company_users: action.payload };
    case SET_COMPANY:
      return { ...state, company: action.payload };
    case SET_WORK_TYPE:
      return { ...state, work_type: action.payload };
    case SET_REQUESTS:
      return { ...state, requests: action.payload };
    case SET_STATUS:
      return { ...state, status: action.payload };
    case SET_REQUEST:
      return { ...state, request: action.payload };
    case SET_TICKET_ID:
      return { ...state, ticketId: action.payload };
    case SET_SERVICE:
      return { ...state, service: action.payload };
    default:
      return state;
  }
};
