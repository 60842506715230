import { Box, Typography, Paper, Button } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getShopBasket } from "../../../store/Shop/actions"
import * as ShopSelector from "../../../store/Shop/selectors"

const Basket = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const basket = useSelector(ShopSelector.basket)

    useEffect(() => {
        dispatch(getShopBasket())
    }, [])

    if (basket === null) return null

    return (
        <Box component={Paper} sx={{ pt: 1, pl: 2, pr: 2, pb: 1, mb: 3 }}>
            <Typography variant="h6">
                Корзина
            </Typography>
            {(basket.price.length + basket.notPrice.length) > 0 ?
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1 }}>
                        В корзине {basket.price.length + basket.notPrice.length} позиции
                    </Typography>
                    <Button variant="contained" onClick={() => navigate(`/shop/cart`)} >Открыть</Button>
                </Box> :
                <Typography sx={{ mb: 1 }}>
                    Корзина пуста
                </Typography>}
        </Box>
    )
}

export default Basket