import {
    SET_MACHINE,
    SET_MACHINES,
    SET_MACHINE_CLASS,
    SET_MACHINE_EVENT,
    SET_MACHINE_FUEL,
    SET_MACHINE_FUEL_CHAR,
    SET_MACHINE_FUEL_DATA,
    SET_MACHINE_MAP,
    SET_MACHINE_MODEL,
    SET_MACHINE_OWNER,
    SET_MACHINE_TELEMETRY,
    SET_MACHINE_TYPE,
    SET_MACHINE_WORK,
    SET_MACHINE_WORK_CHAR,
    SET_MACHINE_WORK_DATA,
    SET_MACHINE_WORRANTY,
    SET_MACHINE_WORK_TABLE
} from "./types";

const initialState = {
    machineMap: [],
    machine: [],
    machines: [],
    telemetry: [],
    machineFuel: [],
    machineWork: [],
    machineOwner: [],
    machineFuelChar: [],
    machineFuelData: [],
    machineWorkData: [],
    event: [],
    machineWorkChar: [],
    type: [],
    class: [],
    model: [],
    worranty: [],
    machineWorkTable: []
}

export const MachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MACHINE_MAP:
            return { ...state, machineMap: action.payload }
        case SET_MACHINE:
            return { ...state, machine: action.payload }
        case SET_MACHINES:
            return { ...state, machines: action.payload }
        case SET_MACHINE_TELEMETRY:
            return { ...state, telemetry: action.payload }
        case SET_MACHINE_FUEL:
            return { ...state, machineFuel: action.payload }
        case SET_MACHINE_WORK:
            return { ...state, machineWork: action.payload }
        case SET_MACHINE_OWNER:
            return { ...state, machineOwner: action.payload }
        case SET_MACHINE_FUEL_CHAR:
            return { ...state, machineFuelChar: action.payload }
        case SET_MACHINE_FUEL_DATA:
            return { ...state, machineFuelData: action.payload }
        case SET_MACHINE_WORK_DATA:
            return { ...state, machineWorkData: action.payload }
        case SET_MACHINE_EVENT:
            return { ...state, event: action.payload }
        case SET_MACHINE_WORK_CHAR:
            return { ...state, machineWorkChar: action.payload }
        case SET_MACHINE_TYPE:
            return { ...state, type: action.payload }
        case SET_MACHINE_CLASS:
            return { ...state, class: action.payload }
        case SET_MACHINE_MODEL:
            return { ...state, model: action.payload }
        case SET_MACHINE_WORRANTY:
            return { ...state, worranty: action.payload }
        case SET_MACHINE_WORK_TABLE:
            return { ...state, machineWorkTable: action.payload }
        default:
            return state
    }
}