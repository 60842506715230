import { Box, Grid, Link, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { BackButton, PageTitle, Preloader } from '../../components'
import { useParams } from 'react-router-dom'
import Item from './Item'
import { useDispatch, useSelector } from 'react-redux'
import * as ClerkSelector from '../../store/Clerk/selectors'
import { getDoc, getDocContent } from '../../store/Clerk/actions'
import { usePromiseTracker } from 'react-promise-tracker'
import DescriptionIcon from '@mui/icons-material/Description'

function ManualDetail() {
    const { nomer, id } = useParams()

    const dispatch = useDispatch()
    const { promiseInProgress } = usePromiseTracker()


    const doc = useSelector(ClerkSelector.doc)
    const content = useSelector(ClerkSelector.content)

    useEffect(() => {
        dispatch(getDoc(id))
    }, [])

    useEffect(() => {
        if (typeof doc.parts !== "undefined" && doc.parts.length !== 0)
            dispatch(getDocContent(doc.parts[0].id))
    }, [doc])

    const Map = ({ items }) => {
        if (items.parts.length !== 0)
            return items.parts.map(item => (
                <Item key={item.id} title={item.title} onClick={() => dispatch(getDocContent(item.id))}>
                    <Map items={item} />
                </Item>
            ))

        return null
    }

    const Content = ({ content }) => <div dangerouslySetInnerHTML={{ __html: content }} />

    const FileLink = ({ url, title }) => <Link sx={{
        display: 'flex',
        alignItems: 'center',
        color: "#3498db",
        ".MuiSvgIcon-root": {
            mr: 2
        }
    }} href={url} target="_blank"><DescriptionIcon sx={{ color: "#3498db" }} />{title}</Link>

    const DocContent = ({ content, size = 25 }) => {
        return (typeof content.parts !== "undefined" && content.parts.length !== 0) && content.parts.map(part => (
            <>
                <PageTitle title={part.title} size={size} />
                <Content content={part.content} />
                <DocContent content={part} size={size - 5} />
            </>
        ))
    }

    if (promiseInProgress) return <Preloader />

    return (
        <Box sx={{
            p: 2,
            width: '100%'
        }}>
            <BackButton nomer={nomer} url={`/machine/${nomer}/manual`} />
            <PageTitle title={doc.title} />
            <Grid container
                columnSpacing={3}
                sx={{
                    mt: '15px',
                    height: '100%'
                }}
            >
                <Grid item xs={3}>
                    <Box sx={{
                        backgroundColor: '#F5F6FA',
                        borderRadius: '4px',
                        padding: "19px 24px"
                    }}>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: 700
                        }} component="h3">Содержание</Typography>
                        <Box sx={{ mt: '22px' }}>
                            {
                                typeof doc.parts !== "undefined" && doc.parts.map(parent => (
                                    <Item key={parent.id} title={parent.title} parent={true} onClick={() => dispatch(getDocContent(parent.id))}>
                                        <Map items={parent} />
                                    </Item>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box sx={{
                        backgroundColor: '#F5F6FA',
                        borderRadius: '4px',
                        padding: "19px 24px",
                        mt: 2
                    }}>
                        {
                            (typeof doc.file !== "undefined" && doc.file !== null && typeof doc.file.title !== "undefined") && (<FileLink title={doc.file.title} url={"https://clerk.dstapi.ru/uploads/docs/" + doc.file.file} />)
                        }
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{
                        mb: 2
                    }}>
                        <PageTitle title={content.title} />
                        <Content content={content.content} />
                        <DocContent content={content} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ManualDetail


