import {
  SET_SHOP_BASKET,
  SET_SHOP_BUILDS,
  SET_SHOP_CATEGORIES,
  SET_SHOP_CATEGORY,
  SET_SHOP_MACHINE,
  SET_SHOP_MODEL,
  SET_SHOP_MODEL_GROUP,
  SET_SHOP_MODEL_GROUP_LIST,
  SET_SHOP_ORDER,
  SET_SHOP_ORDERS,
  SET_SHOP_ORDER_STATUS,
  SET_SHOP_ORDER_STATUSES,
  SET_SHOP_PART,
  SET_SHOP_SCHEME,
  SET_SHOP_SCHEME_ITEMS,
  SET_SHOP_SUBCATEGORY,
  SET_SHOP_SUBSUBCATEGORY,
} from "./types";

const initialState = {
  machine: null,
  basket: null,
  orders: [],
  order: null,
  categories: [],
  category: null,
  subcategory: null,
  subSubcategory: null,
  part: null,
  builds: [],
  scheme: [],
  schemeItems: [],
  orderStatuses: [],
  orderStatus: null,
  model_group: [],
  model: [],
  shopModelGrouoList: [],
};

export const ShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOP_MACHINE:
      return { ...state, machine: action.payload };
    case SET_SHOP_BASKET:
      return { ...state, basket: action.payload };
    case SET_SHOP_ORDERS:
      return { ...state, orders: action.payload };
    case SET_SHOP_CATEGORIES:
      return { ...state, categories: action.payload };
    case SET_SHOP_BUILDS:
      return { ...state, builds: action.payload };
    case SET_SHOP_CATEGORY:
      return { ...state, category: action.payload };
    case SET_SHOP_SUBCATEGORY:
      return { ...state, subcategory: action.payload };
    case SET_SHOP_SUBSUBCATEGORY:
      return { ...state, subSubcategory: action.payload };
    case SET_SHOP_PART:
      return { ...state, part: action.payload };
    case SET_SHOP_SCHEME:
      return { ...state, scheme: action.payload };
    case SET_SHOP_SCHEME_ITEMS:
      return { ...state, schemeItems: action.payload };
    case SET_SHOP_ORDER:
      return { ...state, order: action.payload };
    case SET_SHOP_ORDER_STATUSES:
      return { ...state, orderStatuses: action.payload };
    case SET_SHOP_ORDER_STATUS:
      return { ...state, orderStatus: action.payload };
    case SET_SHOP_MODEL_GROUP:
      return { ...state, model_group: action.payload };
    case SET_SHOP_MODEL:
      return { ...state, model: action.payload };
    case SET_SHOP_MODEL_GROUP_LIST: {
      return { ...state, shopModelGrouoList: action.payload };
    }
    default:
      return state;
  }
};
